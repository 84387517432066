import React from "react";
import methodModel from "../../../methods/methods";
import { FaSpinner } from "react-icons/fa";

const Html = ({
  inputElement,
  uploadImage,
  img,
  remove,
  loader,
  model,
  multiple,
  required,
  err,
}) => {
  return (
    <div>
      <label className={`!px-4 text-sm cursor-pointer font-medium text-white h-10 inline-flex items-center justify-center gap-2 bg-[#71B55C] rounded-lg shadow-btn hover:opacity-80 transition-all focus:ring-2 ring-[#EDEBFC] disabled:bg-[#D0CAF6] disabled:cursor-not-allowed ${img && !multiple ? "hidden" : ""}`}>
        <input
          type="file"
          className="hidden"
          ref={inputElement}
          accept="image/*"
          multiple={multiple ? true : false}
          disabled={loader}
          onChange={(e) => {
            uploadImage(e);
          }}
        />
        Upload Image
      </label>

      {loader ? (
        <div className="text-success">
          Uploading... <FaSpinner className="animate-spin inline-block" />
        </div>
      ) : (
        <></>
      )}

      {multiple ? (
        <>
          <div className="imagesRow">
            {img &&
              img.map((itm, i) => {
                return (
                  <div className="imagethumbWrapper">
                    <img
                      src={methodModel.noImg(itm, model)}
                      className="thumbnail w-[120px]"
                    />
                    <i
                      className="fa fa-times"
                      title="Remove"
                      onClick={(e) => remove(i)}></i>
                  </div>
                );
              })}
          </div>
        </>
      ) : (
        <>
          {img ? (
            <div className="imagethumbWrapper">
              <img src={methodModel.noImg(img, model)} className="thumbnail w-[120px]" />
              <i
                className="fa fa-times"
                title="Remove"
                onClick={(e) => remove()}></i>
                <i class="material-symbols-outlined"
                title="Remove"
                onClick={(e) => remove()}>close</i>
            </div>
          ) : (
            <></>
          )}
        </>
      )}

      {required && !img ? (
        <div className="text-danger">{err ? err : "Image is Required"}</div>
      ) : (
        <></>
      )}
    </div>
  );
};
export default Html;
