import React from "react";
import methodModel from "../../../methods/methods";
// import "./style.scss";
import Select from "react-select";
import { Listbox } from "@headlessui/react";
import { FiChevronDown } from "react-icons/fi";
import { colourStyles } from "../../../utils/constants";

const Html = ({
  options,
  selectedValues,
  handleChange,
  displayValue='name',
  id,
  placeholder,
  required,
  disabled,
  name,
  noDefault,
  className,
  theme = "normal",
  dynamicStyle
}) => {
  const categoryVal = () => {
    let ext = options && options.find((item) => item.id == selectedValues); 
    return ext ? { value: ext.id, label: ext[displayValue] } : "";
  };
  const handleSelectChange = (objectData) => {
    handleChange(objectData?.id);
  };

  return (
    <>
    <div className="selectDropdown">
      {theme == "search" ? (
        <>
          <Select
            styles={colourStyles}
            options={
              options?.map((itm) => {
                return { value: itm.id, label: itm[displayValue] };
              }) || []
            }
            placeholder={placeholder}
            value={categoryVal()}
            // isClearable={true}
            name={name}
            required={required}
            autoComplete="off"
            onChange={(e) => handleChange(e?.value || "")}
          />
        </>
      ) : (
        <>
          <Listbox value={selectedValues} onChange={handleSelectChange} disabled={disabled}>
            <div className="relative list_box_active_state">
              <Listbox.Button
                className={`relative focus:outline-none focus:ring-2 focus:ring-[#71B55C] disabled:!bg-gray-200  ${className ? className : "w-full"
                  } flex items-center justify-between gap-1.5 cursor-default rounded-lg bg-white !px-4 text-left  shadow-box h-10`}>
                <span className="block text-sm font-normal truncate text-[#222]">
                  {selectedValues
                    ? methodModel.find(options, selectedValues, "id")?.[
                    displayValue
                    ] || placeholder
                    : placeholder}
                </span>
                <FiChevronDown
                  className="text-lg text-[#75757A]"
                  aria-hidden="true"
                />
              </Listbox.Button>
              <Listbox.Options
                className={`${dynamicStyle ? "" : "max-h-60"}  focus:outline-none focus:ring-2 focus:ring-[#71B55C]  text-sm absolute z-40 ${className ? className : " min-w-[260px]"
                  }  right-0 shadow-lg !py-2 !mt-1.5 overflow-auto bg-white rounded-lg scrollbar`}>
                {noDefault ? <></> : <>
                  <Listbox.Option
                    value={''}
                    className={`relative cursor-pointer capitalize select-none !py-3 px-3.5 text-gray-900"`}>
                    <span
                      className={`block font-normal`}>
                      {placeholder}
                    </span>
                  </Listbox.Option>
                </>}

                {options &&
                  options.map((item, itemIdx) => (
                    <Listbox.Option
                      key={itemIdx}
                      value={item}
                      className={({ active }) =>
                        `relative cursor-pointer capitalize select-none !py-3 px-3.5 ${active ? "bg-[#71B55C] text-white" : "text-gray-900"
                        }`
                      }>
                      {({ selected }) => (
                        <>
                          <span
                            className={`block ${selected
                              ? "font-medium bg-[#71B55C]"
                              : "font-normal"
                              }`}>
                            {item[displayValue]}
                          </span>
                        </>
                      )}
                    </Listbox.Option>
                  ))}
              </Listbox.Options>
            </div>
          </Listbox>
        </>
      )}
      </div>
    </>
  );
};

export default Html;
