import CanvasJSReact from '../assets/canvasjs.react';
const list=[
    "#3ac4a3",
    "#3f89f9",
    "#7777e9",
    "#ff7eb4",
    "#ffc337",
    "#be6fdc",
    "#bd6a33"
]

const colorsModel = { list }
export default colorsModel