import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DateRangePicker from "../../components/common/DateRangePicker";
import Layout from "../../components/global/layout";
import ApiClient from "../../methods/api/apiClient";
import datepipeModel from "../../models/datepipemodel";
// import "./style.scss";
import { useSelector } from "react-redux";
import { BiLike } from "react-icons/bi";
import { HiOutlinePresentationChartBar } from "react-icons/hi";
import { AiOutlineDownload } from "react-icons/ai";
import { RxReset } from "react-icons/rx";
import Badge from "../../components/Badge";
import pipeModel from "../../models/pipeModel";
import { MdOutlineAssessment, MdTrendingDown, MdTrendingUp } from "react-icons/md";
import { getAssistantResponse } from "../../plugins/aiAssistant";
import { FaSpinner } from "react-icons/fa";
import { FiX } from "react-icons/fi";
import { enabledProductPopup } from "../../shared/shared.method";
import sharedModel from "../../models/shared.model";
import AllChart from "../../components/common/AllChart";
import environment from "../../environment";
import loader from "../../methods/loader";
import Table from "../../components/Table";
import MonthYearInput from "../../components/common/MonthYearInput";
const curentDate=new Date()

const TableComponent=({title,apiUrl='api/frontend/tour/by-date',columns})=>{
  const [emmissionByDate, setEmmissionByDate] = useState([]);
  const [emmissionByDateT, setEmmissionByDateT] = useState(0);
  const [loading, setLoader] = useState(false);
  const [filtersD, setFiltersD] = useState({
    page: 1,
    count: 50,
    month_start: curentDate.getMonth(),
    month_end: curentDate.getMonth()+1,
    year_start: curentDate.getFullYear(),
    year_end: curentDate.getFullYear(),
  });


  useEffect(()=>{
    getData()
  },[filtersD])

  const getData = () => {
    setLoader(true)
    ApiClient.get(apiUrl, filtersD).then(res => {
      setLoader(false)
      if (res.success) {
        let data = res.data



        data = data.map(itm => {
          let scopes = []

          const scopetotal = (s = 1, key = 'carbon_emission') => {
            let n = 0
            scopes.filter(eitm => eitm.scope == s).map(itm => {
              n = n + Number(itm?.[key] || 0)
            })
            return n
          }

          let carbon_emission_detail = itm.carbon_emission_detail

          if (itm.carbon_emission_detail[0].carbon_emission_detail) {
            carbon_emission_detail = itm.carbon_emission_detail.map(itm => {
              let scopes = []
              itm.carbon_emission_detail.map(itm => {
                scopes = [...scopes, ...itm.scope]
              })

              const scopetotal = (s = 1, key = 'carbon_emission') => {
                let n = 0
                scopes.filter(eitm => eitm.scope == s).map(itm => {
                  n = n + Number(itm?.[key] || 0)
                })
                return n
              }

              let payload = {
                totalPax: itm.totalPax,
                accomondation: scopetotal(3, 'accomondation_carbon_emission'),
                scope1: scopetotal(1),
                scope2: scopetotal(2),
                scope3: scopetotal(3),
                total_carbon_emission: 0,
                co2e: 0,
              };
              payload.scope3 = scopetotal(3) + (payload.accomondation * payload.totalPax)
              payload.total_carbon_emission = payload.scope1 + payload.scope2 + payload.scope3
              payload.co2e = (payload.total_carbon_emission / payload.totalPax)
              return payload
            })

            const keyTotal = (key = '') => {
              let v = 0
              carbon_emission_detail.map(itm => {
                v = v + Number(itm?.[key] || 0)
              })

              return v
            }

            itm.accomondation = keyTotal('accomondation')
            itm.scope1 = keyTotal('scope1')
            itm.scope2 = keyTotal('scope2')
            itm.scope3 = keyTotal('scope3')
            itm.total_carbon_emission = keyTotal('total_carbon_emission')
            itm.co2e = keyTotal('co2e')

          } else {
            itm.carbon_emission_detail.map(itm => {
              scopes = [...scopes, ...itm.scope]
            })


            itm.accomondation = scopetotal(3, 'accomondation_carbon_emission')
            itm.scope1 = scopetotal(1)
            itm.scope2 = scopetotal(2)
            itm.scope3v = scopetotal(3)
            itm.scope3 = scopetotal(3) + (itm.accomondation * itm.totalPax)

            itm.total_carbon_emission = itm.scope1 + itm.scope2 + itm.scope3
            itm.co2e = (itm.total_carbon_emission / itm.totalPax)
          }

          itm.carbon_emission_detail = carbon_emission_detail



          return itm
        })

        console.log("data", data)
        setEmmissionByDate(data)
        setEmmissionByDateT(res.total)
      }

    })
  }

  return <>
      <div className="shadow-box p-[20px] rounded-lg bg-white my-6">
          <div className="flex items-center justify-between mb-7">
            <div className="flex flex-col gap-0.5">
              <h6 className="text-lg font-semibold text-typo">
                {title}
              </h6>
              {/* <div className="flex items-center gap-2">
                {filters.compare ? (
                  <>
                    <p className="mb-0">
                      <Grade
                        av={resellerChartData?.totalSale}
                        bv={resellerChartDataC?.totalSale}
                      />
                      <span className="text_color">
                        Compare to{" "}
                        <span className="text-lowercase">{filters.compare}</span>
                      </span>
                    </p>
                  </>
                ) : (
                  <></>
                )}
              </div> */}
            </div>
            <div className="flex items-center gap-3">
              <div>
              <label>From</label>
              <MonthYearInput
              month={filtersD.month_start}
              year={filtersD.year_start}
              onChange={e=>{
                setFiltersD({...filtersD,month_start:e.month,year_start:e.year})
              }}
              />
              </div>
              <div>
              <label>To</label>
              <MonthYearInput
              month={filtersD.month_end}
              year={filtersD.year_end}
              onChange={e=>{
                setFiltersD({...filtersD,month_end:e.month,year_end:e.year})
              }}
              />
              </div>
             
            
            </div>
          </div>
          <div className="">
            {loading?<>
            <div className="text-center">Loading... <i className="fa fa-spin fa-spinner"></i></div>
            </>:<>
            <Table
              className='mb-3'
              data={emmissionByDate}
              columns={columns}
              page={filtersD.page}
              count={filtersD.count}
              total={emmissionByDateT}
              result={(e) => {
                if (e.event == 'page'){
                  setFiltersD({...filtersD,page:e.value})
                }
                if (e.event == 'sort') sorting(e.value)
              }}
            />
            </>}
           
          </div>
        </div>
  </>
}

export default function EnvirenmentDashboard() {

  const user = useSelector((state) => state.user);
  const history = useNavigate();

  const [filters, setFilter] = useState({
    startDate: "",
    endDate: "",
    forward: false,
    yearType: "calendar",
    compare: "",
    compareStart: "",
    compareEnd: "",
    status: "",
    partner: user?.subRole && user?.subRole?.id == environment.SubRolePartner ? user.id || user._id : ""
  });

  const [loading, setLoader] = useState(true);
  const [cloading, setCLoader] = useState(false);

  const [chartData, setChartData] = useState({ salesOverTime: [] });
  const [resellerChartData, setResellerChartData] = useState();
  const [resellerChartDataC, setResellerChartDataC] = useState();
  const [salesBreakdownChartData, setSalesBreakdownChartData] = useState();
  const [salesBreakdownChartDataC, setSalesBreakdownChartDataC] = useState();

  const [insightData, setInsightData] = useState("");
  const [recommendationData, setRecommendationData] = useState("");
  const [insightLoader, setInsightLoader] = useState(false);


  const getResellerSales = (p = {}, compare = false) => {
    loader(true);
    let filter = { ...filters, ...p };
    ApiClient.get("api/reseller/sales", filter).then((res) => {
      if (res.success) {
        res.salesByReseeler = res.salesByReseeler.map((itm) => {
          itm.sale = itm.totalSale;
          itm._id = itm.reseller;
          return itm;
        });
        res.salesByReseeler = res.salesByReseeler.sort((a, b) => {
          return b.totalSale - a.totalSale;
        });

        if (compare) {
          setResellerChartDataC(res);
        } else {
          setResellerChartData(res);
        }
      }
      loader(false);
    });
  };

  const getSalesBreakdown = (p = {}, compare = false) => {
    loader(true);

    if (compare) {
      setCLoader(true)
    } else {
      setLoader(true)
    }
    let filter = { ...filters, ...p, type: 'monthly' };
    if (filter.compare == 'Previous Month' || filter.compare == 'Previous Period') {
      filter.type = 'daily'
    }

    ApiClient.get("api/sales/breakdown", filter).then((res) => {
      if (compare) {
        setCLoader(false)
      } else {
        setLoader(false)
      }
      if (res.success) {
        let data = res;
        data.salesBreakdown = data.salesBreakdown.sort((a, b) => {
          return new Date(a.year, a.month) - new Date(b.year, b.month);
        });


        setTimeout(() => {
          if (compare) {
            setSalesBreakdownChartDataC(data);
          } else {
            setSalesBreakdownChartData(data);
          }
        }, 100)
      }

      loader(false);
    });
  };

  const getData = (p = {}, compare = false) => {
    // getResellerSales(p, compare)
    // getSalesBreakdown(p, compare)
  };

  const print = () => {
    let divId = "printArea";
    let printContents = document.getElementById(divId).innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;

    // Set the default layout to landscape
    let style = document.createElement('style');
    style.innerHTML = '@page { size: landscape; }';
    document.head.appendChild(style);

    window.print();

    // Restore the original contents and remove the landscape style
    document.body.innerHTML = originalContents;
    document.head.removeChild(style);

    // Reload the page to reset any changes made
    location.reload();
  };


  const pageName = window.location.href;

  const dataFilter = (p = {}) => {
    let f = { ...filters, ...p, partner: user?.subRole?.id == environment.SubRolePartner ? user.id || user?._id : "" }
    localStorage.setItem(pageName, JSON.stringify(f))
    let dateRange = {
      startDate: f.startDate,
      endDate: f.endDate,
      compare: f.compare,
      compareStart: f.compareStart,
      compareEnd: f.compareEnd
    }
    localStorage.setItem('dateRange', JSON.stringify(dateRange))
    setFilter({ ...f })
    getData(p)
    if (f.compare) {
      getData({ ...f, startDate: f.compareStart, endDate: f.compareEnd }, true)
    }
  }

  const onRangeChange = (e) => {
    let f = {
      startDate: datepipeModel.datetostring(e.startDate),
      endDate: datepipeModel.datetostring(e.endDate),
      compare: e.compare,
      compareStart: e.compareStart,
      compareEnd: e.compareEnd,
    }
    if (datepipeModel.datetostring(e.startDate) == datepipeModel.datetostring(e.endDate)) {
      setFilter({ ...filters, ...f })
    } else {
      dataFilter({ ...f })
    }
  }

  const yearTypeChange = (p, sfilter) => {
    let f = {
      startDate: '',
      endDate: '',
      ...sfilter,
      compare: ''
    }
    if (p == 'financial') {
      let financialYear = user.financialYear || '1'
      financialYear = Number(financialYear) < 10 ? '0' + financialYear : financialYear
      let year = new Date().getFullYear()
      let endDate = new Date(`${year}-${financialYear}-01`)
      endDate = endDate.setDate(endDate.getDate() - 1)
      f.startDate = `${year - 1}-${financialYear}-01`
      f.endDate = datepipeModel.datetostring(endDate)
    } else {
      let year = new Date().getFullYear()
      let month = new Date().getMonth() + 1
      let endDate = new Date(`${year}-${month}-1`)
      endDate.setMonth(month)
      endDate.setDate(0)
      let compareEnd = new Date(`${year}-${month}-01`).setDate(0)
      compareEnd = datepipeModel.datetostring(compareEnd)
      f.startDate = `${year}-${month}-01`
      f.endDate = datepipeModel.datetostring(endDate)
      f.compare = 'Previous Month'
      let compareStart = new Date(`${year}-${month}-01`)
      compareStart.setMonth(month - 2)

      // f.compareStart = datepipeModel.datetostring(compareStart)
      // f.compareEnd = `${compareEnd}`
      f.compare = ''
      f.compareStart = ''
      f.compareEnd = ''

    }

    dataFilter({ ...f, yearType: p })
  }

  const reset = () => {
    let f = { forward: false, yearType: 'calendar' }
    yearTypeChange('calendar', f)
  }


  useEffect(() => {
    if (user && !user.companyName && user?.subRole?.id != environment.SubRolePartner) {
      history('/company')
    } else {
      enabledProductPopup(user)
      let sfilter = localStorage.getItem(pageName)

      if (sfilter) {
        sfilter = JSON.parse(sfilter)
        sfilter = { ...sfilter }

        let dateRange = localStorage.getItem('dateRange')
        if (dateRange) {
          dateRange = JSON.parse(dateRange)
          sfilter = { ...sfilter, ...dateRange }
        }


        if (sfilter.startDate && sfilter.endDate) {

          dataFilter(sfilter)
        } else {
          yearTypeChange('calendar')
        }

      } else {
        yearTypeChange('calendar')
      }
    }
  }, [])

  const Grade = ({ av, bv, check = false }) => {
    let value = 0;
    let a = Number(av || 0);
    let b = Number(bv || 0);
    let total = a + b;
    value = (a / total) * 100;
    let html = (
      <>
        <span className="text-[#047857] bg-[#04785714] p-[5px] rounded-[30px] text-xs mr-1.5">
          <MdTrendingUp className="inline-block" />+
          {value ? value.toFixed(2) : "0"}%
        </span>
      </>
    );

    if (b > a) {
      html = (
        <>
          <span className="text-[#e92531] bg-[#e9253133] p-[5px] rounded-[30px] text-xs mr-1.5">
            <MdTrendingDown className="inline-block" />-
            {value ? value.toFixed(2) : "0"}%
          </span>
        </>
      );
    }
    return html;
  };



  const [chatRes, setChatRes] = useState()
  const chatType = 'dashboard'
  const getReply = async () => {
    if (!chartData?.salesOverTime?.length) return
    let parm = {
      start_date: filters.startDate,
      end_date: filters.endDate,
      type: chatType,
    }
    setInsightLoader(true);
    ApiClient.get('api/insight/detail', parm).then(async res => {
      if (res.success) {
        setChatRes(res.data)
        if (res.data.insight) {
          setInsightData(res.data.insight)
          setRecommendationData(res.data.recommendation)
          setInsightLoader(false);
        } else {
          let message = "";
          message = JSON.stringify(chartData?.salesOverTime || []);

          let topic = "get insites from the bellow data";
          setInsightLoader(true);
          setRecommendationData("");
          const assistantResponse1 = await getAssistantResponse(message, topic);
          const assistantResponse = await getAssistantResponse(assistantResponse1, "convert my text into html");
          setInsightLoader(false);
          setInsightData(assistantResponse);
          document.querySelector("body").classList.add("max-[1535.98px]:overflow-hidden")
        }
      } else {
        setInsightLoader(false);
      }
    })
  };

  const viewRecommendation = async () => {
    let message = "";
    message = insightData;

    let topic = "provide me recommendation for bellow sales insight data";
    // let topic = "plese do the html format of the content";
    setInsightLoader(true);
    const assistantResponse1 = await getAssistantResponse(message, topic);
    const assistantResponse = await getAssistantResponse(assistantResponse1, "convert my text into html");
    setInsightLoader(false);
    setRecommendationData(assistantResponse);

    let payload = {
      insight: insightData,
      recommendation: assistantResponse,
      type: chatType,
      start_date: filters.startDate,
      end_date: filters.endDate
    }
    ApiClient.post('api/insight', payload, '', true).then(res => {
      if (res.success) {

      }
    })
  };

  const [overtimeData, setOvertimeData] = useState([])




  let performanceLegends = [
    { label: 'Carbon Emmissions', key: 'carbonEmmissions', compare: 'carbonEmmissionsC' },
  ]


  if (filters.compare) {
    let performanceLegendsC = [
      { label: `Carbon Emmissions - ${filters.compare}`, key: 'carbonEmmissionsC', hide: true },
    ]

    performanceLegends = [
      ...performanceLegends,
      ...performanceLegendsC,
    ]
  }


  useEffect(() => {
    if (insightData && !chatRes.insight) {
      viewRecommendation()
    }
  }, [insightData])

  const columns = [
    {
      key: 'dateCreated', name: 'Date', 
      render: (row) => {
        return <>
          {datepipeModel.isotodate(row?.dateCreated)}<br/>
        </>
      }
    },
    {
      key: 'total_carbon_emission', name: 'CO2e Total', 
      render: (row) => {
        return <span className='capitalize'>{pipeModel.number(row?.total_carbon_emission)}</span>
      }
    },
    {
      key: 'scope1', name: 'Scope 1', 
      render: (row) => {
        return <>{pipeModel.number(row?.scope1)}</>
      }
    },
    {
      key: 'scope2', name: 'Scope 2', 
      render: (row) => {
        return <>{pipeModel.number(row?.scope2)}</>
      }
    },
    {
      key: 'scope3', name: 'Scope 3', 
      render: (row) => {
        return <>{pipeModel.number(row?.scope3)}</>
      }
    },
    {
      key: 'totalPax', name: 'No. of pax',
      render: (row) => {
        return <>{row?.totalPax}</>
      }
    },
    {
      key: 'co2e', name: 'CO2e / Per Person',
      render: (row) => {
        return <>{pipeModel.number(row?.co2e)}</>
      }
    },
  ]
  const columns2 = [
    {
      key: 'product_name', name: 'Name of Tour', 
      render: (row) => {
        return <span className='capitalize'>{row?.product_name}</span>
      }
    },
    {
      key: 'total_carbon_emission', name: 'CO2e Total', 
      render: (row) => {
        return <span className='capitalize'>{pipeModel.number(row?.total_carbon_emission)}</span>
      }
    },
    {
      key: 'scope1', name: 'Scope 1', 
      render: (row) => {
        return <>{pipeModel.number(row?.scope1)}</>
      }
    },
    {
      key: 'scope2', name: 'Scope 2', 
      render: (row) => {
        return <>{pipeModel.number(row?.scope2)}</>
      }
    },
    {
      key: 'scope3', name: 'Scope 3', 
      render: (row) => {
        return <>{pipeModel.number(row?.scope3)}</>
      }
    },
    {
      key: 'totalPax', name: 'No. of pax', 
      render: (row) => {
        return <>{row?.totalPax}</>
      }
    },
    {
      key: 'co2e', name: 'CO2e / Per Person',
      render: (row) => {
        return <>{pipeModel.number(row?.co2e)}</>
      }
    },
  ]

  return (

    <Layout>
      <div className="2xl:flex 2xl:gap-2">
        <div className={`grow ${insightData ? "2xl:w-[calc(100%_-_300px)]" : ""}`}
          id="printArea">
          <div className="flex items-center justify-between mb-6">
            <div className="flex flex-col gap-1">
              <h4 className="text-2xl font-semibold text-[#3C3E49]">
                Dashboard
              </h4>
              <p className="text-sm font-normal text-[#75757A]">
                Summary of all reports for your business
              </p>
            </div>
            <div className="flex gap-4">
              <button
                type="button"
                onClick={(e) => getReply()}
                disabled={insightLoader}
                className="!px-4 text-typo text-sm font-normal py-2.5 flex items-center justify-center gap-2 bg-[#fff] rounded-lg shadow-btn hover:bg-[#6db56224] border border-[#D0D5DD] transition-all focus:ring-2 ring-[#F1F2F3] disabled:bg-[#F3F2F5] disabled:cursor-not-allowed">
                <HiOutlinePresentationChartBar className="text-xl text-typo" />
                View Insights
                {insightLoader ? (
                  <FaSpinner className="inline-block animate-spin" />
                ) : (
                  <></>
                )}
              </button>
              {insightData ? (
                <>
                  <button
                    type="button"
                    onClick={(e) => viewRecommendation()}
                    className="!px-4 text-sm font-normal text-white h-11 flex items-center justify-center gap-2 bg-[#71B55C] rounded-lg shadow-btn hover:opacity-80 transition-all focus:ring-2 ring-[#EDEBFC] disabled:bg-[#D0CAF6] disabled:cursor-not-allowed">
                    <BiLike className="text-xl text-white" />
                    View Recommendation
                    {insightLoader ? (
                      <FaSpinner className="inline-block animate-spin" />
                    ) : (
                      <></>
                    )}
                  </button>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>

          <div className="flex flex-wrap items-center justify-end gap-3 grow mb-7" >
            {/* <button className="!px-4 text-typo text-sm font-normal py-2.5 flex items-center justify-center gap-2 bg-[#fff] rounded-lg shadow-btn hover:bg-[#6db56224] border border-[#D0D5DD] transition-all focus:ring-2 ring-[#F1F2F3] disabled:bg-[#F3F2F5] disabled:cursor-not-allowed" title={filters.forward ? "View By Travel Date" : "View By Order Date"}>
              {filters.forward ? "View By Travel Date" : "View By Order Date"}
              <label className="relative inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  checked={filters.forward}
                  onChange={(e) => {
                    viewByChange(e.target.checked);
                  }}

                  className="sr-only peer"
                />
                <div className="w-11 h-6 bg-[#6db562b3] peer-focus:outline-none peer-focus:ring-0 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-[#71B55C]" />
              </label>
            </button> */}
            <div className="w-full max-w-[300px] !p-1 bg-[#6db56236]  border border-gray-200 rounded-lg flex items-center gap-2">
              <label
                className={`flex-1 mb-0 flex items-center justify-center gap-2 rounded-md text-sm font-medium py-2 px-3 focus:outline-none ${filters.yearType == "financial"
                  ? "text-typo font-medium bg-white !rounded-md shadow-sm border-2 border-[#71B55C]"
                  : "text-[#1D2433A6]"
                  }`}
                onClick={(e) => yearTypeChange("financial")}>
                Financial year
              </label>
              <label
                className={`#71B55Cmedium py-2 px-3 focus:outline-none ${filters.yearType == "calendar"
                  ? "text-typo font-medium bg-white !rounded-md shadow-sm border-2 border-[#71B55C]"
                  : "text-[#1D2433A6]"
                  }`}
                onClick={(e) => yearTypeChange("calendar")}>
                Calendar year
              </label>
            </div>
            <DateRangePicker
              disabled={filters.yearType == "calendar" ? false : true}
              title={filters.yearType == 'calendar' ? '' : 'Change Data by Calendar Year to change dates'}
              value={{
                startDate: filters.startDate,
                endDate: filters.endDate,
                compare: filters.compare,
                compareStart: filters.compareStart,
                compareEnd: filters.compareEnd,
              }}
              isCompare={true}
              onChange={(e) => onRangeChange(e)}
              fullWidth={false}
            />

            <button
              onClick={print}
              type="button"
              className="relative cursor-default rounded-lg bg-[#fff] !px-4 text-left focus:outline-none h-10 flex items-center justify-center gap-2 shadow-box hover:bg-[#6db56224] transition-all disabled:bg-[#F3F2F5] disabled:cursor-not-allowed">
              <AiOutlineDownload className="text-xl text-typo" />
              Export PDF
            </button>
            <button
              onClick={reset}
              type="button"
              className="!px-4 text-sm font-normal text-white h-10 flex items-center justify-center gap-2 bg-[#71B55C] rounded-lg shadow-btn hover:opacity-80 transition-all focus:ring-2 ring-[#EDEBFC] disabled:bg-[#D0CAF6] disabled:cursor-not-allowed">
              <RxReset className="text-xl text-white" />
              Reset
            </button>
          </div>

          {/* <div className="grid grid-cols-2 xl:grid-cols-4 gap-[16px] mb-6">

            {stats.map(itm => {
              if (itm.list) {
                return <>
                  <div className="flex gap-2 overflow-hidden bg-white rounded-lg shadow-box">
                    <div className="flex grow flex-col gap-8 !px-3 !py-4 bg-[#6db56236] h-full border-l-2 border-[#71B55C] !rounded-l-lg">
                      <Badge label={itm.list[0].name} />
                      <div>
                        <h2 className="font-medium text-typo text-1xl">
                          {pipeModel.translate({ value: itm.list[0].value, pipe: itm.list[0].pipe, format: itm.list[0].format })}
                          ({directResellerPer(itm.list[0].value)}%){" "}
                          {filters.compare ? (
                            <>
                              v/s{" "}
                              {pipeModel.translate({ value: itm.list[0].valueC, pipe: itm.list[0].pipe, format: itm.list[0].format })}
                              (
                              {directResellerPer(
                                itm.list[0].valueC,
                                true
                              )}
                              %)
                            </>
                          ) : (
                            <></>
                          )}
                        </h2>

                        {filters.compare ? (
                          <>
                            <p className="mb-0">
                              <Grade
                                av={itm.list[0].value}
                                bv={itm.list[0].valueC}
                              />
                              <span className="text_color"></span>
                            </p>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                    <div className="flex grow flex-col h-full gap-8 !px-2 !py-4">
                      <Badge label={itm.list[1].name} />
                      <div>
                        <h2 className="font-medium text-typo text-1xl">
                          {pipeModel.translate({ value: itm.list[1].value, pipe: itm.list[1].pipe, format: itm.list[1].format })}
                          ({directResellerPer(itm.list[1].value)}%){" "}
                          {filters.compare ? (
                            <>
                              v/s{" "}
                              {pipeModel.translate({ value: itm.list[1].valueC, pipe: itm.list[1].pipe, format: itm.list[1].format })}
                              (
                              {directResellerPer(
                                itm.list[1].valueC,
                                true
                              )}
                              %)
                            </>
                          ) : (
                            <></>
                          )}
                        </h2>

                        {filters.compare ? (
                          <>
                            <p className="mb-0">
                              <Grade
                                av={itm.list[1].value}
                                bv={itm.list[1].valueC}
                              />
                              <span className="text_color"></span>
                            </p>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              } else {
                return <>
                  <div className="p-[16px] flex flex-col gap-8 rounded-lg bg-white shadow-box">
                    <Badge label={itm.name} />
                    <div className="">
                      <h2 className="text-2xl font-medium text-typo">
                        {pipeModel.translate({ value: itm.value, pipe: itm.pipe, format: itm.format })}
                        {filters.compare ? (
                          <>
                            v/s{" "}
                            {pipeModel.translate({ value: itm.valueC, pipe: itm.pipe, format: itm.format })}
                          </>
                        ) : (
                          <></>
                        )}
                      </h2>
                      {filters.compare ? (
                        <>
                          <p className="mb-0">
                            <Grade
                              av={itm.value}
                              bv={itm.valueC}
                            />
                            <span className="text_color">
                              Compare to{" "}
                              <span className="text-lowercase">{filters.compare}</span>
                            </span>
                          </p>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </>
              }

            })}




          </div> */}



          {/* Orders Over Time */}
          <div className="shadow-box p-[20px] rounded-lg bg-white my-6">
            <div className="flex items-center justify-between mb-7">
              <div className="flex flex-col gap-0.5">
                <h6 className="text-lg font-semibold text-typo">
                  Carbon Emmissions
                </h6>
                <div className="flex items-center gap-2">
                  {filters.compare ? (
                    <>
                      <p className="mb-0">
                        <Grade
                          av={resellerChartData?.totalSale}
                          bv={resellerChartDataC?.totalSale}
                        />
                        <span className="text_color">
                          Compare to{" "}
                          <span className="text-lowercase">{filters.compare}</span>
                        </span>
                      </p>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <div className="flex items-center gap-3">
                {/* <Select
                  selected={selectedOrderChart}
                  setSelected={setSelectedOrderChart}
                  options={CHART_OPTIONS}
                  classes="h-9"
                /> */}
              </div>
            </div>
            <div className="">
              <AllChart data={overtimeData} legends={performanceLegends} />
            </div>
          </div>

          <TableComponent title="CO2e - by date for all tours" columns={columns} apiUrl="api/frontend/tour/by-date" />
          <TableComponent title="CO2e - by tours" columns={columns2} apiUrl="api/frontend/tour/by-name" />

        </div>
        {insightData ? (
          <>
            <div className="max-[1535.98px]:absolute max-[1535.98px]:top-0 max-[1535.98px]:right-0 max-[1535.98px]:z-[51] min-w-[300px] max-w-[300px] w-full bg-white shadow-btn p-4 rounded-lg max-[1535.98px]:h-screen 2xl:h-[calc(100vh_-_88px)] overflow-auto scrollbar">
              <div className="flex items-center gap-4 mb-4">
                <div className="h-10 w-10 shrink-0 rounded-md shadow-box bg-white flex items-center justify-center text-2xl !text-[#71B55C]">
                  <MdOutlineAssessment />
                </div>
                <h4 className="text-xl font-medium text-typo">Insight</h4>
                <button
                  onClick={() => {
                    setInsightData("");
                    document.querySelector("body").classList.remove("max-[1535.98px]:overflow-hidden")
                  }}
                  className="ml-auto h-9 w-9 shrink-0 shadow-btn hover:shadow-none p-1 rounded-lg border border-gray-100 !text-[#71B55C]">
                  <FiX className="w-full h-full" />

                </button>
              </div>
              <div className="bg-white border border-[#EBEBEB] rounded-xl p-4">
                <div
                  className="w-full [&>h1]:text-sm [&>h1]:font-semibold [&>h1]:text-[#111827] [&>p]:text-xs [&>p]:text-[#333A41] [&>p]:font-medium [&>p]:my-2 [&>ol]:text-xs [&>ol]:text-[#4A545E] [&>ol]:list-decimal [&>ol]:list-inside [&>ol]:space-y-1.5"
                  dangerouslySetInnerHTML={{ __html: sharedModel.getInsightHtml(insightData) }}></div>
              </div>
              {!recommendationData ? (
                <>
                  <button
                    type="button"
                    onClick={(e) => viewRecommendation()}
                    className="2xl:hidden my-3 !px-4 text-sm font-normal text-white h-11 flex items-center justify-center gap-2 bg-[#71B55C] rounded-lg shadow-btn hover:opacity-80 transition-all focus:ring-2 ring-[#EDEBFC] disabled:bg-[#D0CAF6] disabled:cursor-not-allowed">
                    <BiLike className="text-xl text-white" />
                    View Recommendation
                    {insightLoader ? (
                      <FaSpinner className="inline-block animate-spin" />
                    ) : (
                      <></>
                    )}
                  </button>
                </>) : <></>
              }

              {recommendationData ? (
                <>
                  <div className="my-6 border-b" />
                  <div className="mt-6">
                    <div className="flex items-center gap-4 mb-4">
                      <div className="h-10 w-10 shrink-0 rounded-md shadow-box bg-white flex items-center justify-center text-2xl !text-[#71B55C]">
                        <MdOutlineAssessment />
                      </div>
                      <h4 className="text-xl font-medium text-typo">
                        Recommendation
                      </h4>
                    </div>
                    <div
                      className="w-full recommendationData"
                      dangerouslySetInnerHTML={{
                        __html: sharedModel.getInsightHtml(recommendationData),
                      }}></div>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
            <div className="fixed inset-0 z-50 bg-black/30 2xl:hidden" />
          </>
        ) : (
          <></>
        )}
      </div>
    </Layout>

  );
}
