import "./style.scss";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Html from "./Html";
import environment from "../../../environment";
import { useEffect } from "react";
const Sidebar = ({ isOpen }) => {
  const user = useSelector((state) => state.user);
  const history = useNavigate();

  const ListItemLink = ({ to, type = "link", disabled = false, ...rest }) => {
    let url = location.href;
    const host = location.host;
    url = url.split(host)[1];
    return (
      <>
        {type == "link" ? (
          <li className={`nav-item ${url.includes(to) ? "active" : ""}`}>
            {/* {...rest} */}
            <Link
              to={to}
              {...rest}
              className={`nav-link hoverclass ${disabled ? "disabled" : ""}`}
              disabled={disabled}
            />
          </li>
        ) : (
          <li
            className={`nav-item main ${url.includes(to) ? "active" : ""}`}
            {...rest}></li>
        )}
      </>
    );
  };


  const urlAllow = (url) => {
    if(user?.subRole?.id==environment.SubRolePartner){
      return true
    }
    let permissions = user?.subRole && user?.subRole?.permissions && user?.subRole?.permissions[0];
    let arr = url?.split(",");
    let value = false;
    arr.map((itm) => {
      if (permissions?.[itm]) value = true;
    });
    if(url=='hide') return false
    if (user?.subRole?.id || user?.subRole?._id) {
      return value;
    } else {
      return true;
    }
  };

  const route = (p) => {
    history(p);
  };

  const scrollToId = (id) => {

    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'auto' });
    } else {
      console.error(`Element with id '${id}' not found`);
    }
  };
  useEffect(() => {
    scrollToId(window.location.pathname == '/accounting/bills' ? "/accounting/invoices" : window.location.pathname)
  }, [window.location.pathname])
  return (
    <>
      <Html
        route={route}
        scrollToId={scrollToId}
        ListItemLink={ListItemLink}
        urlAllow={urlAllow}
        isOpen={isOpen}
      />
    </>
  );
};

export default Sidebar;
