const shared={
    check:'Employee',
    title:'Employees',
    addTitle:'Employee',
    url:'employee',
    type:'tour-details',
    addApi:'api/employee',
    editApi:'api/employee',
    detailApi:'api/employee',
    listApi:'api/employees',
    statusApi:'api/employee',
    deleteApi:'api/employee'
}

export default shared