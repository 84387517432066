import React, { useEffect, useState } from "react"
import Layout from "../../components/global/layout";
import "./style.scss";
import Apiclient from "../../methods/api/apiClient";
import { useSelector } from "react-redux";
import loader from "../../methods/loader";
import { toast } from "react-toastify";

export default function TwoFactorAuth() {
    const user = useSelector(state => state.user)
    const [form, setForm] = useState({ email: '',otp:'' })
    const [step, setStep] = useState(1)
    const [userDetail, setUserDetail] = useState()

    useEffect(()=>{
        Apiclient.get('api/user/profile',{id:user._id}).then(res=>{
            if(res.success){
                setUserDetail(res.data)
                setForm({...form,email:res.data.two_factor_email})
            }
        })
    },[])

    const submit = (e) => {
        e.preventDefault()

        let payload = {
            id: user._id,
            two_factor_email: form.email
        }
        let url='api/two-factor/email'
        if(step==2){
            url='api/enable-two-factor'  
            payload={
                id: user._id,
                otp: form.otp
            }
        }
       
        loader(true)
        Apiclient.post(url, payload).then(res => {
            if (res.success) {
                if(step==1){
                    setStep(2)
                }else if(step==2){
                    setForm({ ...form,otp:'' })
                    setStep(1)
                    toast.success(res.message)
                }
            }
            loader(false)
        })
    }

    return <>
        <Layout>
            <h5 className="text-xl font-semibold text-typo mb-6">2 Factor authentication</h5>
            <div className="shadow-box p-[20px] w-full lg:w-7/12  rounded-lg bg-white ">
                <div className="grid grid-cols-12  gap-y-8 items-center">
                <div className="col-span-12 md:col-span-4 hidden md:block border-r border-gray-300 border-dashed">
                        <div className="imsgfactor">
                            <img className="h-64" src="/assets/img/svgs/twofactor.png" />
                        </div>
                    </div>
                    <div className="col-span-12 md:col-span-8">
                    <div className=" pl-8">
                    <form onSubmit={submit} className=" max-500">
                    {step == 1 ? <>
                        <p className="mb-3">Enter your email</p>
                        <input
                            type="email"
                            className="shadow-box bg-white w-full text-sm placeholder:text-gray-500 rounded-lg h-12 flex items-center gap-2 overflow-hidden px-2 !ring-[#71B55C] !outline-[#71B55C]"
                            placeholder="Enter Email"
                            value={form.email}
                            onChange={(e) => {
                                setForm({ ...form, email: e.target.value });
                            }}
                            required
                        />
                    </> : <></>}
                    {step == 2 ? <>
                    <p className="mb-3">Otp sent on email</p>
                        <input
                            type="text"
                            className="shadow-box bg-white w-full text-sm placeholder:text-gray-500 rounded-lg h-12 flex items-center gap-2 overflow-hidden px-2 !ring-[#71B55C] !outline-[#71B55C]"
                            placeholder="Enter OTP"
                            minLength={6}
                            value={form.otp}
                            onChange={(e) => {
                                setForm({ ...form, otp: e.target.value });
                            }}
                            required
                        />
                    </> : <></>}
                    <div className="text-right mt-4">
                        <button className="!px-4 text-sm w-full font-medium text-white h-10 inline-flex items-center justify-center gap-2 bg-[#71B55C] rounded-lg shadow-btn hover:opacity-80 transition-all focus:ring-2 ring-[#EDEBFC] disabled:bg-[#D0CAF6] disabled:cursor-not-allowed" >
                        {step==2?'Submit':'Next'}
                        </button>
                    </div>
                    </form>
                    </div>
                    </div>
                   
                </div>
           
            

            </div>
        </Layout>
    </>
}