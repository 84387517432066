import {  useNavigate } from "react-router-dom";
import Layout from "../../components/global/layout";
import { useEffect, useState } from "react";
import datepipeModel from "../../models/datepipemodel";
import ApiClient from "../../methods/api/apiClient";
import { useParams } from "react-router-dom";
import shared from "./shared";
import Table from "../../components/Table";
import { Tooltip } from "antd";
import pipeModel from "../../models/pipeModel";

const View = () => {
    const [data, setData] = useState()
    const [expense, setExpense] = useState()
    const [filters, setFilter] = useState({page:1,count:50})
    const [total, setTotal] = useState({page:1,count:50})
    const history = useNavigate()
    const {id}=useParams()


    const getDetail=()=>{
        ApiClient.getAll({url:shared.detailApi,params:{id:id},response:(res)=>{
            if(res.success){
                setData(res.data)
            }
        }})
    }

    const getExpenses=()=>{
        ApiClient.getAll({url:'api/tour-expenses',params:{...filters,employee_id:id},response:(res)=>{
            if(res.success){
                setExpense(res.data.map(itm=>{
                    itm.lineDetail=itm.xerobanktransactions_detail.lineItems?.[0]
                    return itm
                }))
                setTotal(res.total)
            }
        }})
    }

    useEffect(()=>{
        getDetail()
    },[])

    useEffect(()=>{
        getExpenses()
    },[filters])

    const columns = [
        {
            key: 'origin_address', name: 'Start Location', sort: true,
            render: (row) => {
                return <span className='capitalize'>{row?.origin_address}</span>
            }
        },
        {
            key: 'destination_address', name: 'End Location', sort: true,
            render: (row) => {
                return <>{row?.destination_address}</>
            }
        },
        {
            key: 'accomodation_detail', name: 'accomodation_detail',
            render: (row) => {
                return<span className='capitalize'>{row?.accomodation_detail?.accomodation_type}</span>
            }
        },
        {
            key: 'travel_type', name: 'Travel Type', sort: true,
            render: (row) => {
                return <span className="capitalize">{row?.travel_type}</span>
            }
        },
        {
            key: 'class', name: 'Class', sort: true,
            render: (row) => {
                return <span className="capitalize">{row?.class}</span>
            }
        },
        {
            key: 'Expense Category', name: 'Expense Category',
            render: (row) => {
                return <>{row?.lineDetail?.AccountDetail?.Name||''}</>
            }
        },
        {
            key: 'spend', name: 'Spend',
            render: (row) => {
                return <>{pipeModel.number(row?.xerobanktransactions_detail?.total)}</>
            }
        },
        {
            key: 'totalTax', name: 'Tax',
            render: (row) => {
                return <>{pipeModel.number(row?.xerobanktransactions_detail?.totalTax)}</>
            }
        },
        {
            key: 'expenseDate', name: 'Expense Date',
            render: (row) => {
                return <>
                    {datepipeModel.date(row?.xerobanktransactions_detail?.createdAt)}
                </>
            }
        },
        
        // {
        //     key: 'action', name: 'Action',
        //     render: (itm) => {
        //         return <>
        //             <div className="flex items-center justify-start gap-1.5">
        //             <Tooltip placement="top" title="View">
        //                         <a className="border cursor-pointer border-[#ff7641] hover:opacity-70 rounded-lg bg-[#ff764114] w-10 h-10 !text-[#71B55C] flex items-center justify-center text-xl" onClick={e => view(itm.id)}>
        //                         <span class="material-symbols-outlined">visibility</span>
        //                         </a>
        //                     </Tooltip>
        //                     <Tooltip placement="top" title="Edit">
        //                         <a className="border cursor-pointer border-[#ff7641] hover:opacity-70 rounded-lg bg-[#ff764114] w-10 h-10 !text-[#71B55C] flex items-center justify-center text-xl" onClick={e => edit(itm.id)}>
        //                         <span class="material-symbols-outlined">edit</span>
        //                         </a>
        //                     </Tooltip>
        //             </div>
        //         </>
        //     }
        // },
    ]


    const sorting = (key) => {
        let sorder = 'asc'
        if (filters.key == key) {
            if (filters.sorder == 'asc') {
                sorder = 'desc'
            } else {
                sorder = 'asc'
            }
        }

        let sortBy = `${key} ${sorder}`;
        setFilter({ ...filters, sortBy, key, sorder })
    }

    return <>
        <Layout>
            <div className="text-right">
                <div>
                    <span className="cursor-pointer" onClick={() => history(`/${shared.url}`)}>
                    <span class="material-symbols-outlined">arrow_back</span>
                    </span>
                </div>
            </div>
            <div className='w-full p-4 mt-6 bg-white rounded-lg shadow-box'>

                <div className="grid grid-cols-12 gap-4">
                    <div className="col-span-12 sideclass md:col-span-12">
                        <h3 className="px-3 py-3 rounded mt-3 mb-6 bg-[#6db56247] text-[#666]">{shared.addTitle} Details</h3>
                        <div className="grid grid-cols-12 gap-4">
                            <div className="col-span-12 md:col-span-6">
                                <label className="profileheddingcls">Name</label>
                                <div className='profiledetailscls'>{data?.fullName || '--'}</div>
                            </div>
                            <div className="col-span-12 md:col-span-6">
                                <label className="profileheddingcls">Email</label>
                                <div className='profiledetailscls'>{data?.email || '--'}</div>
                            </div>
                            
                            <div className="col-span-12 md:col-span-6">
                                <label className="profileheddingcls">Start Date</label>
                                <div className='profiledetailscls'>{datepipeModel.date(data?.start_date) || '--'}</div>
                            </div>
                            <div className="col-span-12 md:col-span-6">
                                <label className="profileheddingcls">Date of Birth</label>
                                <div className='profiledetailscls'>{datepipeModel.date(data?.dateOfBirth) || '--'}</div>
                            </div>
                            <div className="col-span-12 md:col-span-6">
                                <label className="profileheddingcls">Number of Days / per week</label>
                                <div className='profiledetailscls'>{data?.days || '--'}</div>
                            </div>
                            <div className="col-span-12 md:col-span-6">
                                <label className="profileheddingcls">Gender</label>
                                <div className='profiledetailscls'>{data?.gender || '--'}</div>
                            </div>
                            <div className="col-span-12 md:col-span-6">
                                <label className="profileheddingcls">Address</label>
                                <div className='profiledetailscls'>{data?.address?.addressLine1 || '--'}</div>
                            </div>
                            <div className="col-span-12 md:col-span-6">
                                <label className="profileheddingcls">Kms travelled</label>
                                <div className='profiledetailscls'>{data?.kms_travelled || '--'}</div>
                            </div>
                            <div className="col-span-12 md:col-span-6">
                                <label className="profileheddingcls">Status</label>
                                <div className='profiledetailscls'>{data?.status=='active'?'Active':'Inactive'}</div>
                            </div>
                            <div className="col-span-12 md:col-span-6">
                                <label className="profileheddingcls">Created Date</label>
                                <div className='profiledetailscls'>{datepipeModel.date(data?.createdAt)}</div>
                            </div>
                        </div>

                        <h3 className="px-3 py-3 rounded mt-10 mb-6 bg-[#6db56247] text-[#666]">Expense Details</h3>
                        <Table
                        className='mb-3'
                        data={expense}
                        columns={columns}
                        page={filters.page}
                        count={filters.count}
                        total={total}
                        result={(e) => {
                            if (e.event == 'page'){
                                setFilter({...filters,page:e.value})
                            }
                            if (e.event == 'sort') sorting(e.value)
                        }}
                    />
                    </div>
                </div>
            </div>
        </Layout>
    </>
}

export default View;