import React, { useEffect, useState } from "react";

import Layout from "../../../components/global/layout";
import ApiClient from "../../../methods/api/apiClient";
import datepipeModel from "../../../models/datepipemodel";
import Breadcrumb from "../../../components/common/Breadcrumb";
import { useSelector } from "react-redux";
import pipeModel from "../../../models/pipeModel";
import loader from "../../../methods/loader";
import moment from "moment";
import { IoChevronBackOutline } from "react-icons/io5";
import { useNavigate,useParams } from "react-router-dom";
const XeroBillDetail = () => {
  const user = useSelector((state) => state.user);
  const { id } = useParams();
  const history = useNavigate();
  const [data, setdata] = useState();

  const back = () => {
    history(-1);
  };

  useEffect(() => {
    getReviewDetails();
  }, []);

  const getReviewDetails = () => {
    loader(true);
    ApiClient.get(`api/xero/invoice/detail`, { id }).then((res) => {
      if (res.success) {
        setdata(res.data);
      }
      loader(false);
    });
  };

  return (
    <Layout>
      <Breadcrumb
        links={[
          {
            name: " Bills",

            link: "/accounting/bill",
          },
        ]}
        currentPage={" Bill Detail"}
      />
      <div className="max-w-[1024px]">
        <div className="flex justify-between items-center my-6">
          <h3 className="text-2xl font-semibold text-[#111827]">Xero Bill Detail</h3>
          <button className="!px-3 text-base font-normal text-white h-11 flex items-center justify-center gap-2 !bg-[#71B55C] rounded-lg shadow-btn hover:opacity-80 transition-all focus:ring-2 ring-[#EDEBFC] disabled:bg-[#D0CAF6] disabled:cursor-not-allowed" onClick={(e) => back()}>
            <IoChevronBackOutline className="text-xl text-white" />
            Back
          </button>
        </div>
        <div className="bg-white shadow-box rounded-lg w-full p-6">
          <div className="grid grid-cols-2 !gap-5">
            <div>
              <p className="text-[#75757A] text-sm font-normal mb-1.5">Invoice Number</p>
              <h4 className="text-base shadow-box bg-gray-100 rounded-lg h-11 flex items-center gap-2 overflow-hidden !px-3">{data?.invoiceNumber || "--"}</h4>
            </div>
            <div>
              <p className="text-[#75757A] text-sm font-normal mb-1.5">Name of Company</p>
              <h4 className="text-base shadow-box bg-gray-100 rounded-lg h-11 flex items-center gap-2 overflow-hidden !px-3">{data?.contact?.Name || "--"}</h4>
            </div>

            <div>
              <p className="text-[#75757A] text-sm font-normal mb-1.5">Amount Paid</p>
              <h4 className="text-base shadow-box bg-gray-100 rounded-lg h-11 flex items-center gap-2 overflow-hidden !px-3">
                {pipeModel.currency(
                  data?.amountPaid,
                  "",
                  user.companyCurrencyFormat
                )}
              </h4>
            </div>
            <div>
              <p className="text-[#75757A] text-sm font-normal mb-1.5">Amount Credited</p>
              <h4 className="text-base shadow-box bg-gray-100 rounded-lg h-11 flex items-center gap-2 overflow-hidden !px-3">
                {pipeModel.currency(
                  data?.amountCredited,
                  "",
                  user.companyCurrencyFormat
                )}
              </h4>
            </div>
            <div>
              <p className="text-[#75757A] text-sm font-normal mb-1.5">Amount Due</p>
              <h4 className="text-base shadow-box bg-gray-100 rounded-lg h-11 flex items-center gap-2 overflow-hidden !px-3">
                {pipeModel.currency(
                  data?.amountDue,
                  "",
                  user.companyCurrencyFormat
                )}
              </h4>
            </div>
            <div>
              <p className="text-[#75757A] text-sm font-normal mb-1.5">Issue Date</p>
              <h4 className="text-base shadow-box bg-gray-100 rounded-lg h-11 flex items-center gap-2 overflow-hidden !px-3">
                {datepipeModel.date(data?.dateString, user?.companyDateFormat) ||
                  "--"}
              </h4>
            </div>
            <div>
              <p className="text-[#75757A] text-sm font-normal mb-1.5">Due Date</p>
              <h4 className="text-base shadow-box bg-gray-100 rounded-lg h-11 flex items-center gap-2 overflow-hidden !px-3">
                {datepipeModel.date(data?.dueDate, user?.companyDateFormat)}
              </h4>
            </div>
            <div>
              <p className="text-[#75757A] text-sm font-normal mb-1.5">Sub Total</p>
              <h4 className="text-base shadow-box bg-gray-100 rounded-lg h-11 flex items-center gap-2 overflow-hidden !px-3">
                {pipeModel.currency(
                  data?.subTotal,
                  "",
                  user.companyCurrencyFormat
                )}
              </h4>
            </div>
            <div>
              <p className="text-[#75757A] text-sm font-normal mb-1.5">Taxes</p>
              <h4 className="text-base shadow-box bg-gray-100 rounded-lg h-11 flex items-center gap-2 overflow-hidden !px-3">
                {pipeModel.currency(
                  data?.totalTax,
                  "",
                  user.companyCurrencyFormat
                )}
              </h4>
            </div>
            <div>
              <p className="text-[#75757A] text-sm font-normal mb-1.5">Total</p>
              <h4 className="text-base shadow-box bg-gray-100 rounded-lg h-11 flex items-center gap-2 overflow-hidden !px-3">
                {pipeModel.currency(data?.total, "", user.companyCurrencyFormat)}
              </h4>
            </div>
            <div>
              <p className="text-[#75757A] text-sm font-normal mb-1.5">Line Amount Types</p>
              <h4 className="text-base shadow-box bg-gray-100 rounded-lg h-11 flex items-center gap-2 overflow-hidden !px-3">{data?.lineAmountTypes || "--"}</h4>
            </div>
            <div>
              <p className="text-[#75757A] text-sm font-normal mb-1.5">Description</p>
              <h4 className="text-base shadow-box bg-gray-100 rounded-lg h-11 flex items-center gap-2 overflow-hidden !px-3">
                {data?.lineItems?.[0]?.Description}
              </h4>
            </div>
            <div>
              <p className="text-[#75757A] text-sm font-normal mb-1.5">Status</p>
              <h4 className="text-base shadow-box bg-gray-100 rounded-lg h-11 flex items-center gap-2 overflow-hidden !px-3">{data?.status || "--"}</h4>
            </div>
            <div>
              <p className="text-[#75757A] text-sm font-normal mb-1.5">Category</p>
              <h4 className="text-base shadow-box bg-gray-100 rounded-lg h-11 flex items-center gap-2 overflow-hidden !px-3">
                {data?.lineItems?.[0]?.AccountDetail?.Name || "--"}
              </h4>
            </div>
            <div>
              <p className="text-[#75757A] text-sm font-normal mb-1.5">Reference</p>
              <h4 className="text-base shadow-box bg-gray-100 rounded-lg h-11 flex items-center gap-2 overflow-hidden !px-3">{data?.reference || "--"}</h4>
            </div>
          </div>
        </div>
      </div>

    </Layout>
  );
};
export default XeroBillDetail;
