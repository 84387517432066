const shared={
    check:'ActivityType',
    title:'Activity Types',
    addTitle:'Activity Type',
    url:'activity',
    addApi:'api/category',
    editApi:'api/category/update',
    detailApi:'api/category/detail',
    listApi:'api/categories/listing',
    statusApi:'api/category/status/change',
    deleteApi:'api/category/delete'
}

export default shared