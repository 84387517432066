// let host = document.location.host;
let apiUrl = 'https://ecoapi.dazhboards.com/'
// if (host.includes('localhost')) {
//   apiUrl = 'https://stageapi.ecometrixs.com/'
// } else if (host.includes('stageadmin.ecometrixs.com')) {
//   apiUrl = 'https://stageapi.ecometrixs.com/'
// } else {
//   apiUrl = 'https://stageapi.ecometrixs.com/'
// }

const environment = {
  api: apiUrl,
  kpiTypeId: '6618f9bcf517b68368e00f8b',
  adminRoleId: '64b152a909d268f038611929',
  userRoleId: '64b15102b14de6c28838f7d2',
  partnerRole: '64b15102b14de6c28838f7d2',
  SubRolePartner: '65d305b193650817f7620002',
  sustainabilityTypeId: '64b149e3cd1f5ed827c13812',
  travellingCatId:'663c6330b5d876d3de39858c',
  accommodationTypeId:'661fd0257c7ddfb85d1c19ba',
  transportTypeId:'6629fb8dff82c17bdc606a05',
  activityTypeId:'661fd3337c7ddfb85d1c2115',
  goalTypeId:'6673ce5551bae5b04d456e0d',
  // fbAppId:'650078173094925',
  // fbConfig_id:null,
  fbAppId: '687148143612037',
  fbConfig_id: '1445414022852988',

  // map_api_key:'AIzaSyAbD0kk0SRu76yogIQKhY2r-oKdAZIbNIs',
  map_api_key: 'AIzaSyDXcaa81DeBgUyniq30GpZ5pYI7oDneKlE',
  resellerTypeId: '64b23b7d690d1d5f7ae76102',
  productTypeId: '64a7d198fa039f179c0320ca',
  OPENAI_API_KEY: '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'
};
export default environment;
