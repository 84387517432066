import React from "react";
import { Link } from "react-router-dom";
import methodModel from "../../../methods/methods";
import Sidebar from "../sidebar";
import { Menu, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import { FaRegUser } from "react-icons/fa";
import { IoSettingsOutline } from "react-icons/io5";
import { FiMenu, FiX, FiLogOut, FiChevronDown } from "react-icons/fi";
import ApiClient from "./../../../methods/api/apiClient";
import datepipeModel from "../../../models/datepipemodel";
const Html = ({
  isOpen,
  toggle,
  searchHandle,
  search,
  user,
  isOpen1,
  searchChange,
  clear,
  Logout,
}) => {
  const PathName = window.location.pathname;
  const [activePlan, setActivePlan] = useState();
  useEffect(() => {
    // api/my/plan
    ApiClient.get("api/my/plan").then((res) => {
      if (res.success) {
        setActivePlan(res.data);
      }
    });
  }, []);
  return (
    <nav
      className={`${
        isOpen ? "min-sidebar w-[calc(100%-78px)]" : "w-[calc(100%-280px)] "
      } w-[calc(100%-280px)]  py-[8px] px-[32px] bg-white border-b  fixed transition-[width] duration-300 ml-auto right-0 z-50 flex items-center 
      
      `}>
        <div className="flex items-center">
        <button
        onClick={toggle}
        className="h-9 w-9 shrink-0 shadow-btn hover:shadow-none p-1 rounded-lg border border-gray-100 !text-[#71B55C]">
        {!isOpen ? (
          <FiMenu className="w-full h-full" />
        ) : (
          <FiX className="w-full h-full" />
        )}
      </button>
      <h2 className="text-[#2B2B2B] font-medium text-[18px] ms-[10px]">
          Home
        </h2>
        </div>
     

      {/* <form
        className="border border-[#EBEBEB] hover:shadow-none shadow-btn rounded-lg h-10 ml-3.5 flex gap-2 items-center px-2.5 min-w-[400px]"
        onSubmit={searchHandle}>
        <IoSearchOutline className="text-xl text-gray-500" />
        <input
          className="w-full h-full text-typo focus:outline-none placeholder:font-light placeholder:text-gray-400"
          type="text"
          placeholder="Search anything..."
          value={search}
          onChange={(e) => searchChange(e.target.value)}
        />

        {search ? (
          <FiX
            className="w-4 h-4 !text-[#71B55C] cursor-pointer"
            onClick={clear}
          />
        ) : (
          <></>
        )}
      </form> */}

      <Menu as="div" className="relative inline-block ml-auto text-left">
        <div className="flex items-center">
          {user?.order_syncing ? (
            <>
              <div className="mr-1.5 min-w-[200px] text-xs w-[20px] text-white p-3 rounded-lg bg-[#71B55C]">
                Your Sales data is syncing now
              </div>
            </>
          ) : (
            <></>
          )}
          {user?.product_syncing ? (
            <>
              <div className="mr-1.5 min-w-[200px] text-xs w-[20px] text-white p-3 rounded-lg bg-[#71B55C]">
                Your Products data is syncing now
              </div>
            </>
          ) : (
            <></>
          )}
          {activePlan?.on_trial ? (
            <>
              <div className="mr-1.5 min-w-[300px]  text-xs w-[20px] text-white p-3 rounded-lg bg-[#71B55C]">
                You are on trial period. Expires on{" "}
                <span className="underline underline-offset-4 ">
                {datepipeModel.date(
                  activePlan?.validUpTo,
                  user.companyDateFormat
                )}
                </span>
              </div>
            </>
          ) : (
            <></>
          )}
          <Menu.Button className="flex items-center justify-center w-full gap-2 px-2 py-2 rounded-md hover:bg-black/5 focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75">
            <img
              alt="image"
              src={methodModel.userImg(user.image)}
              className="object-cover w-10 h-10 rounded-full"
              width="40"
              height="40"
            />
            <div>
              <p className="font-medium text-[#2D3036]">{user.fullName}</p>
              {/* <p className="text-xs font-normal text-left text-gray-500">
                {user.role?.name}
              </p> */}
            </div>
            <FiChevronDown className="w-5 h-5 text-[#2D3036]" />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95">
          <Menu.Items className="ring-1 ring-black/5 z-[999999] focus:outline-none w-60 !rounded-xl absolute right-0 mt-2 origin-top-right bg-white divide-y divide-gray-100 shadow-lg">
            <div className="px-2 py-2 ">
              <Menu.Item>
                <Link
                  to="/profile"
                  className="group hover:bg-gray-100 hover:no-underline hover:!text-[#71B55C] flex items-center w-full gap-3 px-2 py-2 text-sm font-normal text-gray-900 rounded-md">
                  <FaRegUser />
                  Profile
                </Link>
              </Menu.Item>
              <Menu.Item>
                <Link
                  to="/profile/change-password"
                  className="group hover:bg-gray-100 hover:no-underline hover:!text-[#71B55C] flex items-center w-full gap-3 px-2 py-2 text-sm font-normal text-gray-900 rounded-md">
                  <IoSettingsOutline />
                  Change Password
                </Link>
              </Menu.Item>
            </div>
            <div className="px-2 py-2">
              <Menu.Item>
                <button
                  onClick={() => Logout()}
                  className="group hover:bg-gray-100 hover:no-underline hover:!text-[#71B55C] flex items-center w-full gap-3 px-2 py-2 text-sm font-normal text-gray-900 rounded-md">
                  <FiLogOut />
                  Logout
                </button>
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
      {isOpen1 ? (
        <div className="w-full mobi-dropdown">
          <Sidebar />
        </div>
      ) : (
        <></>
      )}
    </nav>
  );
};

export default Html;
