import React, { Suspense } from "react";

import { PersistGate } from "redux-persist/es/integration/react";
import "react-datepicker/dist/react-datepicker.css";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import configureStore from "./config";
import { Navigate } from 'react-router-dom';
import { ToastContainer } from "react-toastify";
import "react-pagination-js/dist/styles.css";
// import "./scss/main.scss";
import "react-toastify/dist/ReactToastify.css";
// progressbar;
import "react-circular-progressbar/dist/styles.css";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import "react-phone-input-2/lib/style.css";
import 'react-quill/dist/quill.snow.css';

import { Auth } from "./methods/auth";
import SalesDataRetrive from "./pages/SalesDashboard/SalesRetriveData";
import TwoFactorAuth from "./pages/TwoFactorAuth";
import RefundData from "./pages/SalesDashboard/RefundData";
import Partner from "./pages/PartnerModule/Partner";
import AddEditCoupon from "./pages/CouponsModule/AddEditCoupon";
import CouponDetail from "./pages/CouponsModule/ViewCoupons";
import CouponDashboard from "./pages/CouponsModule/CouponDashboard";
import Preparation from "./pages/PDFSIGN/Prepration";
import SignDocument from "./pages/PDFSIGN/SIGNATURE";
import SubmissionTanks from "./pages/SubmissinTahnku/SubmissionTanks";
import EnvirenmentDashboard from "./pages/DashboardPages/EnvirenmentDashboard";

/************ store configration *********/

const Login = React.lazy(() => import("./pages/Login"));
const SignUp = React.lazy(() => import("./pages/Signup"));
const Forgotpassword = React.lazy(() => import("./pages/Forgotpassword"));
const Resetpassword = React.lazy(() => import("./pages/Resetpassword"));
const Profile = React.lazy(() => import("./pages/Profile"));
const EditProfile = React.lazy(() => import("./components/Profile/Edit"));
const ReteriveProductData = React.lazy(() =>
  import("./pages/ProductDashboard/RetriveProduct")
);
const ChangePassword = React.lazy(() =>
  import("./components/Profile/ChangePassword")
);
const Plans = React.lazy(() => import("./pages/Plan"));
const Cards = React.lazy(() => import("./pages/Cards"));
const AddEditCards = React.lazy(() => import("./pages/Cards/AddEdit"));
const CardsDetail = React.lazy(() => import("./pages/CardsDetail"));
const ProductDetail = React.lazy(() =>
  import("./pages/Products/Detail/ProductDetail")
);
const ProductDetail1 = React.lazy(() =>
  import("./pages/Products/Detail1/ProductDetail")
);
const POS = React.lazy(() => import("./pages/POS"));
const MarketingSoftware = React.lazy(() => import("./pages/POS/MarketingSoftware"));
import AddEditPOS from "./pages/POS/AddEdit";
import noPages from "./pages/404";
import XeroBills from "./pages/Xero/BIllsToPay";
import XeroBillDetail from "./pages/Xero/BIllsToPay/Detail";
import KPIs from "./pages/KPI";
import Activity from "./pages/Activity";
import AddEditActivity from "./pages/Activity/AddEdit";
import ViewActivity from "./pages/Activity/View";
import GoalManager from "./pages/GoalManager";
import AddEditGoalManager from "./pages/GoalManager/AddEdit";
import ViewGoalManager from "./pages/GoalManager/View";
import Tasks from "./pages/Tasks";
import AddEditTask from "./pages/Tasks/AddEdit";
import ViewTask from "./pages/Tasks/View";


const Orders = React.lazy(() => import("./pages/POS/Orders/Orders"));
const CompanyDetails = React.lazy(() => import("./pages/CompanyDetails"));

const Salesdashboard = React.lazy(() => import("./pages/SalesDashboard"));
const Resellerdashboard = React.lazy(() =>
  import("./pages/SalesDashboard/ResellerDashboard")
);
const Marketingdashboard = React.lazy(() =>
  import("./pages/MarketingDashboard")
);
const Financialdashboard = React.lazy(() =>
  import("./pages/FinancialDashboard")
);
// import Resellerdashboard from React.lazy(() => import('./pages/ResellerDashboard'));
const ActivePlan = React.lazy(() => import("./pages/Plan/ActivePlan"));
const OrderDetail = React.lazy(() => import("./pages/POS/Orders/OrderDetail"));
const Calendar = React.lazy(() => import("./pages/Calendar"));
const SalesData = React.lazy(() => import("./pages/SalesDashboard/salesData"));
const Productdashboard = React.lazy(() => import("./pages/ProductDashboard"));
const ProductData = React.lazy(() =>
  import("./pages/ProductDashboard/ProductData")
);


const CustomerData = React.lazy(() => import("./pages/Customers/CustomerData"));
const Customers = React.lazy(() => import("./pages/Customers"));

const ResellerDatabase = React.lazy(() => import("./pages/ResellerDatabase"));

const Roles = React.lazy(() => import("./pages/Roles"));
const AddEditRole = React.lazy(() => import("./pages/Roles/AddEdit"));

const Users = React.lazy(() => import("./pages/Users"));
const AddEditUser = React.lazy(() => import("./pages/Users/AddEditUser"));
const Reviews = React.lazy(() => import("./pages/Reviews"));
const Googlereview = React.lazy(() => import("./pages/Reviews/Googlereview"));
const FacebookReviews = React.lazy(() => import("./pages/Reviews/FacebookReviews"));
const Googlereviewsteps = React.lazy(() =>
  import("./pages/Reviews/Googlereviewsteps")
);
const step2 = React.lazy(() => import("./pages/Reviews/step2"));
const sitereview = React.lazy(() => import("./pages/Reviews/Reviewsite"));
const POSReviews = React.lazy(() => import("./pages/POS/Reviews"));
const improveContact = React.lazy(() =>
  import("./pages/Reviews/ImproveContacts")
);
const CRM = React.lazy(() => import("./pages/CRM"));
const AddEditCRM = React.lazy(() => import("./pages/CRM/AddEdit"));
const ViewReseller = React.lazy(() => import("./pages/ResellerDatabase/view"));

const AddEditReseller = React.lazy(() =>
  import("./pages/ResellerDatabase/AddEditReseller")
);

const Thanku = React.lazy(() => import("./pages/Thanku"));
const ReviewDashboard = React.lazy(() =>
  import("./pages/Reviews/ReviewDashboard")
);

const ReviewPage = React.lazy(() => import("./pages/Reviews/ReviewPage"));
const ReviewDetail = React.lazy(() => import("./pages/Reviews/ReviewDetail"));
const ViewCRM = React.lazy(() => import("./pages/CRM/View"));

const All_review = React.lazy(() => import("./pages/reviewsAll"));
const MarketingAnalytics = React.lazy(() =>
  import("./pages/MarketingDashboard/MarketingAnalytics")
);
const ContractTemplateProducts = React.lazy(() =>
  import("./pages/CRM/ContractTemplateProducts")
);
const XeroConnect = React.lazy(() => import("./pages/Xero/XeroConnect"));
const XeroInvoices = React.lazy(() => import("./pages/Xero/Invoices"));
const XeroTransactions = React.lazy(() => import("./pages/Xero/transactions"));
const XeroInvoiceDetail = React.lazy(() =>
  import("./pages/Xero/Invoices/Detail")
);
const XeroTransactionDetail = React.lazy(() =>
  import("./pages/Xero/transactions/Detail")
);
const OTPpage = React.lazy(() => import("./pages/Signup/OTPpage"));

const CustomerDataDetails = React.lazy(() =>
  import("./pages/CustomersDazboard/Customers/CustomerDetails")
);

const { persistor, store } = configureStore();

import TourDetails from "./pages/TourDetail";
import AddEditTourDetail from "./pages/TourDetail/AddEdit";
import ViewTourDetail from "./pages/TourDetail/View";
import Employee from "./pages/Employee";
import AddEditEmployee from "./pages/Employee/AddEdit";
import ViewEmployee from "./pages/Employee/View";

export default () => {
  localStorage.removeItem('joinedRoom')
  localStorage.removeItem('joinedPlatform')

  const routes=[
    {
        path:'/kpi',
        component:KPIs
    },
    {
      path:'/tour-details',
      component:TourDetails
    },
    {
      path:'/tour-details/add',
      component:AddEditTourDetail
    },
    {
      path:'/tour-details/edit/:id',
      component:AddEditTourDetail
    },
    {
      path:'/tour-details/detail/:id',
      component:ViewTourDetail
    },
    {
      path:'/employee',
      component:Employee
    },
    {
      path:'/employee/edit/:id',
      component:AddEditEmployee
    },
    {
      path:'/employee/add',
      component:AddEditEmployee
    },
    {
      path:'/employee/detail/:id',
      component:ViewEmployee
    },
    {
      path:'/activity',
      component:Activity
    },
    {
      path:'/activity/edit/:id',
      component:AddEditActivity
    },
    {
      path:'/activity/add',
      component:AddEditActivity
    },
    {
      path:'/activity/detail/:id',
      component:ViewActivity
    },
    {
      path:'/goal',
      component:GoalManager
    },
    {
      path:'/goal/edit/:id',
      component:AddEditGoalManager
    },
    {
      path:'/goal/add',
      component:AddEditGoalManager
    },
    {
      path:'/goal/detail/:id',
      component:ViewGoalManager
    },
    {
      path:'/task',
      component:Tasks
    },
    {
      path:'/task/edit/:id',
      component:AddEditTask
    },
    {
      path:'/task/add',
      component:AddEditTask
    },
    {
      path:'/task/detail/:id',
      component:ViewTask
    },
    {
      path:'/login',
      component:Login
    },
    {
      path:'/sales/refunds',
      component:RefundData
    },
  ]


  return (
    <>
      <Provider store={store}>
        <PersistGate loading={"loading ..."} persistor={persistor}>
        
            <Suspense
              fallback={
                <div>
                  <div id="loader" className="loaderDiv">
                    <div>
                      <img
                        src="/assets/img/loader.gif"
                        alt="logo"
                        className="pageLoader"
                        width="auto"
                        height="auto"
                      />
                    </div>
                  </div>
                </div>
              }>
            <Router>
                    <Routes>

                {routes.map(itm=>{
                                return <Route exact={true} key={itm.path}  path={itm.path} store={store} requireAuth={Auth} Component={itm.component} />
                            })}

             

      <Route path='/' element={ <Navigate to="/login" /> }/>

                 
  
                  <Route
                    exact={true}
                    store={store}
                    path="/coupons"
                    Component={CouponDashboard}
                  />
                  <Route
                    exact={true}
                    store={store}
                    path="/accounting/bills"
                    Component={XeroBills}
                  />
                  <Route
                    exact={true}
                    store={store}
                    path="/coupons/add/:id?"
                    Component={AddEditCoupon}
                  />

                  
                  <Route
                    exact={true}
                    store={store}
                    path="/accounting/bill/:id"
                    Component={XeroBillDetail}
                  />
                  
                  
                 
                  <Route
                    exact={true}
                    store={store}
                    path="/partner"
                    Component={Partner}
                  />
                 

                  <Route
                    exact={true}
                    store={store}
                    path="/two-factor-auth"
                    Component={TwoFactorAuth}
                  />
                  <Route
                    exact={true}
                    store={store}
                    path="/otppage"
                    Component={OTPpage}
                  />
                  <Route
                    exact={true}
                    requireAuth={Auth}
                    store={store}
                    path="/dashboard"
                    Component={EnvirenmentDashboard}
                  />
                  <Route
                    exact={true}
                    requireAuth={Auth}
                    store={store}
                    path="/dashboard/orders"
                    Component={Orders}
                  />
                  <Route
                    exact={true}
                    requireAuth={Auth}
                    store={store}
                    path="/orders/:id"
                    Component={OrderDetail}
                  />
                  <Route
                    exact={true}
                    requireAuth={Auth}
                    store={store}
                    path="/profile"
                    Component={Profile}
                  />
                  <Route
                    exact={true}
                    requireAuth={Auth}
                    store={store}
                    path="/profile/edit"
                    Component={EditProfile}
                  />
                  <Route
                    exact={true}
                    requireAuth={Auth}
                    store={store}
                    path="/profile/change-password"
                    Component={ChangePassword}
                  />
                  <Route
                    exact={true}
                    store={store}
                    path="/calendar"
                    Component={Calendar}
                  />
                  <Route
                    exact={true}
                    store={store}
                    path="/activeplan"
                    Component={ActivePlan}
                  />
                  <Route
                    exact={true}
                    store={store}
                    path="/product/detail1/:id"
                    Component={ProductDetail}
                  />
                  <Route
                    exact={true}
                    store={store}
                    path="/product/:id"
                    Component={ProductDetail1}
                  />

                  <Route
                    exact={true}
                    store={store}
                    path="/company"
                    Component={CompanyDetails}
                  />
              
                  <Route
                    exact={true}
                    store={store}
                    path="/signup"
                    Component={SignUp}
                  />
                  <Route
                    exact={true}
                    store={store}
                    path="/forgotpassword"
                    Component={Forgotpassword}
                  />
                  <Route
                    exact={true}
                    store={store}
                    path="/prepare"
                    Component={Preparation}
                  />

                  <Route
                    exact={true}
                    store={store}
                    path="/signdoc"
                    Component={SignDocument}
                  />
                  <Route
                    exact={true}
                    store={store}
                    path="/resetpassword"
                    Component={Resetpassword}
                  />

                  <Route
                    exact={true}
                    store={store}
                    path="/sales-data"
                    Component={SalesData}
                  />
                  {/*  Sales Retrivable Data */}
                  <Route
                    exact={true}
                    store={store}
                    path="/sales/retrievable"
                    Component={SalesDataRetrive}
                  />

                  <Route
                    exact={true}
                    path="/users"
                    store={store}
                    requireAuth={Auth}
                    Component={Users}
                  />
                  <Route
                    exact={true}
                    path="/users/add"
                    store={store}
                    requireAuth={Auth}
                    Component={AddEditUser}
                  />
                  <Route
                    exact={true}
                    path="/users/edit/:id"
                    store={store}
                    requireAuth={Auth}
                    Component={AddEditUser}
                  />

                  <Route
                    exact={true}
                    store={store}
                    path="/roles"
                    Component={Roles}
                  />
                  <Route
                    exact={true}
                    store={store}
                    path="/roles/add"
                    Component={AddEditRole}
                  />
                  <Route
                    exact={true}
                    store={store}
                    path="/roles/edit/:id"
                    Component={AddEditRole}
                  />

                  <Route
                    exact={true}
                    path="/product-dashboard"
                    store={store}
                    requireAuth={Auth}
                    Component={Productdashboard}
                  />
                  <Route
                    exact={true}
                    path="/product-data"
                    store={store}
                    requireAuth={Auth}
                    Component={ProductData}
                  />
                  <Route
                    exact={true}
                    path="/products/retrivable"
                    store={store}
                    requireAuth={Auth}
                    Component={ReteriveProductData}
                  />
                  <Route
                    exact={true}
                    path="/customer-data"
                    store={store}
                    requireAuth={Auth}
                    Component={CustomerData}
                  />
                  <Route
                    exact={true}
                    path="/customers"
                    store={store}
                    requireAuth={Auth}
                    Component={Customers}
                  />
                  <Route
                    exact={true}
                    path="/reseller-data"
                    store={store}
                    requireAuth={Auth}
                    Component={ResellerDatabase}
                  />
                  <Route
                    exact={true}
                    path="/reseller-data/add"
                    store={store}
                    requireAuth={Auth}
                    Component={AddEditReseller}
                  />
                  <Route
                    exact={true}
                    path="/reseller-data/edit/:id"
                    store={store}
                    requireAuth={Auth}
                    Component={AddEditReseller}
                  />
                  <Route
                    exact={true}
                    path="/reseller-data/view/:id"
                    store={store}
                    requireAuth={Auth}
                    Component={ViewReseller}
                  />
                  <Route
                    exact={true}
                    path="/reviews"
                    store={store}
                    requireAuth={Auth}
                    Component={Reviews}
                  />
                  <Route
                    exact={true}
                    path="/review-dashboard"
                    store={store}
                    requireAuth={Auth}
                    Component={ReviewDashboard}
                  />
                  <Route
                    exact={true}
                    path="/api/review-connected"
                    store={store}
                    requireAuth={Auth}
                    Component={POSReviews}
                  />
                  <Route
                    exact={true}
                    path="/api/review"
                    store={store}
                    requireAuth={Auth}
                    Component={Googlereview}
                  />
                  <Route
                    exact={true}
                    path="/api/review/facebook"
                    store={store}
                    requireAuth={Auth}
                    Component={FacebookReviews}
                  />
                  <Route
                    exact={true}
                    path="/api/accounting"
                    store={store}
                    requireAuth={Auth}
                    Component={XeroConnect}
                  />

                  <Route
                    exact={true}
                    path="/accounting/invoices"
                    store={store}
                    requireAuth={Auth}
                    Component={XeroInvoices}
                  />
                  <Route
                    exact={true}
                    path="/accounting/invoices/:id"
                    store={store}
                    requireAuth={Auth}
                    Component={XeroInvoiceDetail}
                  />
                  <Route
                    exact={true}
                    path="/accounting/transactions"
                    store={store}
                    requireAuth={Auth}
                    Component={XeroTransactions}
                  />
                  <Route
                    exact={true}
                    path="/accounting/transactions/:id"
                    store={store}
                    requireAuth={Auth}
                    Component={XeroTransactionDetail}
                  />

                  <Route
                    exact={true}
                    path="/reviewStep"
                    store={store}
                    requireAuth={Auth}
                    Component={Googlereviewsteps}
                  />
                  <Route
                    exact={true}
                    path="/step"
                    store={store}
                    requireAuth={Auth}
                    Component={step2}
                  />
                  <Route
                    exact={true}
                    path="/sitereview"
                    store={store}
                    requireAuth={Auth}
                    Component={sitereview}
                  />
                  <Route
                    exact={true}
                    path="/improvecontact"
                    store={store}
                    requireAuth={Auth}
                    Component={improveContact}
                  />
                  <Route
                    exact={true}
                    path="/api/reviewpage"
                    store={store}
                    requireAuth={Auth}
                    Component={ReviewPage}
                  />
                  <Route
                    exact={true}
                    path="/review/:id"
                    store={store}
                    requireAuth={Auth}
                    Component={ReviewDetail}
                  />
                  <Route
                    exact={true}
                    path="/coupon/:id"
                    store={store}
                    requireAuth={Auth}
                    Component={CouponDetail}
                  />
                  <Route
                    exact={true}
                    path="/crm"
                    store={store}
                    requireAuth={Auth}
                    Component={CRM}
                  />
                  <Route
                    exact={true}
                    path="/crm/add"
                    store={store}
                    requireAuth={Auth}
                    Component={AddEditCRM}
                  />
                  <Route
                    exact={true}
                    path="/crm/edit/:id"
                    store={store}
                    requireAuth={Auth}
                    Component={AddEditCRM}
                  />
                  <Route
                    exact={true}
                    path="/crm/view/:id"
                    store={store}
                    requireAuth={Auth}
                    Component={ViewCRM}
                  />
                  <Route
                    exact={true}
                    path="/crm/products/:id"
                    store={store}
                    requireAuth={Auth}
                    Component={ContractTemplateProducts}
                  />


                  <Route
                    exact={true}
                    path="/pos"
                    store={store}
                    requireAuth={Auth}
                    Component={POS}
                  />
                  <Route
                    exact={true}
                    path="/marketing-software"
                    store={store}
                    requireAuth={Auth}
                    Component={MarketingSoftware}
                  />
                  <Route
                    exact={true}
                    path="/pos/orders"
                    store={store}
                    requireAuth={Auth}
                    Component={Orders}
                  />
                  <Route
                    exact={true}
                    store={store}
                    path="/pos/orders/orderdetail/:id"
                    Component={OrderDetail}
                  />
                  <Route
                    exact={true}
                    path="/pos/add"
                    store={store}
                    requireAuth={Auth}
                    Component={AddEditPOS}
                  />
                  <Route
                    exact={true}
                    path="/pos/edit/:id"
                    store={store}
                    requireAuth={Auth}
                    Component={AddEditPOS}
                  />
                  <Route
                    exact={true}
                    path="/sales"
                    store={store}
                    requireAuth={Auth}
                    Component={Salesdashboard}
                  />
                  <Route
                    exact={true}
                    path="/reseller-dashboard"
                    store={store}
                    requireAuth={Auth}
                    Component={Resellerdashboard}
                  />

                  <Route
                    exact={true}
                    path="/marketing/google-analytics"
                    store={store}
                    requireAuth={Auth}
                    Component={Marketingdashboard}
                  />
                  <Route
                    exact={true}
                    path="/marketing-analytics"
                    store={store}
                    requireAuth={Auth}
                    Component={MarketingAnalytics}
                  />
                  <Route
                    exact={true}
                    path="/financial/dashboard"
                    store={store}
                    requireAuth={Auth}
                    Component={Financialdashboard}
                  />
                  
                  {/* Plans */}
                  <Route exact={true} path="/plans" Component={Plans} />
                  {/* Cards */}
                  <Route
                    exact={true}
                    store={store}
                    path="/card"
                    Component={Cards}
                  />
                  <Route
                    exact={true}
                    store={store}
                    path="/card/add"
                    Component={AddEditCards}
                  />
                  <Route
                    exact={true}
                    store={store}
                    path="/card/edit/:id"
                    Component={AddEditCards}
                  />

                  <Route
                    exact={true}
                    path="/detailcards/:id/:intervalcount/:currencyiso"
                    Component={CardsDetail}
                  />
                  <Route
                    exact={true}
                    path="/thanku"
                    store={store}
                    Component={Thanku}
                  />
                  <Route
                    exact={true}
                    path="/allreview"
                    store={store}
                    Component={All_review}
                  />
                  <Route
                    exact={true}
                    path="/customerdetails/:id"
                    store={store}
                    Component={CustomerDataDetails}
                  />

               

                  <Route
                    exact={true}
                    path="/submit/thanks"
                    store={store}
                    requireAuth={Auth}
                    Component={SubmissionTanks}
                  />
  

                  <Route
                    exact={true}
                    store={store}
                    path="*"
                    Component={noPages}
                  />

                  {/* <Route exact path="/">
                    <Redirect to="/login" />
                  </Route> */}
                </Routes>
              </Router>
            </Suspense>
        </PersistGate>
      </Provider>
      <div id="loader" className="loaderDiv !hidden">
        <div>
          <img
            src="/assets/img/loader.gif"
            width="auto"
            height="auto"
            alt="logo"
            className="pageLoader"
          />
        </div>
      </div>
      <ToastContainer position="top-right" />
    </>
  );
};
