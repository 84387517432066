import React, { useState, useEffect } from "react";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import methodModel from "../../methods/methods";
import { Link, useNavigate, useParams } from "react-router-dom";
import Layout from "../../components/global/layout";
import statusModel from "../../models/status.model";
import { Tooltip } from "antd";
import FormControl from "../../components/common/FormControl";
import shared from "./shared";
import { useSelector } from "react-redux";
import environment from "../../environment";
import datepipeModel from "../../models/datepipemodel";

const AddEdit = () => {
    const { id } = useParams()
    const [images, setImages] = useState({ image: '' });
    const [form, setform] = useState({
        id: '',
        email:'',
        fullName: '',
        start_date:'',
        gender: '',
        dateOfBirth: '',
        city: '',
        status: 'active',
        transport_means: '',
        days: '',
        address: '',
        kms_travelled: '',
        country:'',
        postCode:'',
        state:''
    })


    const history = useNavigate()
    const [submitted, setSubmitted] = useState(false)
    const [transport, setTransport] = useState([])
    const user = useSelector((state) => state.user);
    const formValidation = [
        { key: 'status', required: true, message: 'Status is Required' },
        { key: 'transport_means', required: true, message: 'Transport Means is Required' },
        { key: 'gender', required: true, message: 'Gender is Required' },
    ]

    const handleSubmit = (e) => {
        e.preventDefault()
        setSubmitted(true)
        let invalid = methodModel.getFormError(formValidation, form)
        if (invalid) return
        let method = 'post'
        let url = shared.addApi
        let value = {
            ...images,
            "id": form.id,
            // "fullName": form.fullName,
            "jobTitle": null,
            "start_date": form.start_date,
            "gender": form.gender,
            "employmentType": "Employee",
            "dateOfBirth": form.dateOfBirth,
            transport_means:form.transport_means,
            kms_travelled:form.kms_travelled,
            days:form.days,
            "address": {
                "addressLine1": form.address,
                "addressLine2": null,
                "city": form.city,
                "suburb": form.state,
                "countryName": form.country,
                "postCode": form.postCode
            },
            "email": form.email,
            // "employee_id": "ee8ea1dc-cec8-4899-a3de-5979381dd6c2",
            "firstName": form.fullName,
            "lastName": null,
            "status": "active",
        }





        if (value.id) {
            method = 'put'
            url = shared.editApi
        } else {
            delete value.id
        }

        loader(true)
        ApiClient.allApi(url, value, method).then(res => {
            if (res.success) {
                // ToastsStore.success(res.message)
                history(`/${shared.url}`)
            }
            loader(false)
        })
    }

    useEffect(() => {
        if (id) {
            loader(true)
            ApiClient.getAll({
                url: shared.detailApi, params: { id }, response: (res) => {
                    if (res.success) {
                        let value = res.data
                        let payload = form

                        Object.keys(payload).map(itm => {
                            payload[itm] = value[itm]
                        })

                        payload.id = id
                        payload.address=value.address.addressLine1;
                        payload.city=value.address.city;
                        payload.country=value.address.countryName;
                        payload.postCode=value.address.postCode;
                        payload.state=value.address.suburb;

                        if (payload.product_id?._id) payload.product_id = payload.product_id._id
                        setform({
                            ...payload
                        })

                        let img = images
                        Object.keys(img).map(itm => {
                            img[itm] = value[itm]
                        })
                        setImages({ ...img })

                    }
                    loader(false)
                }
            })
        }

    }, [id])

    const imageResult = (e, key) => {
        images[key] = e.value
        setImages(images)
    }

    const getError = (key) => {
        return submitted ? methodModel.getError(key, form, formValidation)?.message : ''
    }



    const genders = [
        { id: 'M', name: 'Male' },
        { id: 'F', name: 'Female' },
    ]

    let days = []
    for (let i = 1; i <= 7; i++) {
        let name = `${i} Days`
        if (i == 1) name = `1 Day`
        days.push({ id: i, name: name })
    }


    const getTeansport = () => {
        ApiClient.get('api/categories/listing', { catType: environment.transportTypeId, status: 'active' }).then(res => {
            if (res.success) {
                setTransport(res.data.map(itm => {
                    return {
                        id: itm.id,
                        name: `${itm.name}`
                    }
                }))
            }
        })
    }

    useEffect(() => {
        getTeansport()
    }, [])


    return <>
        <Layout>
            <form onSubmit={handleSubmit} autoComplete="off">
                <div className="pprofile1">


                    <div className='flex items-center mb-8'>
                        <Tooltip placement="top" title="Back">
                            <Link to={`/${shared.url}`} className="!px-4  py-2 flex items-center justify-center  rounded-lg shadow-btn hover:bg-[#6db56224] border transition-all  mr-3">
                                <span class="material-symbols-outlined">arrow_back</span>
                            </Link>
                        </Tooltip>
                        <div>
                            <h3 className="text-base font-medium text-[#0A0D14]">
                                {form && form.id ? 'Edit' : 'Add'}  {shared.addTitle}
                            </h3>
                            <p class="text-sm font-normal text-[#75757A]">Here you can see all about your {shared.addTitle}</p>
                        </div>
                    </div>



                    {/* <FormTabs list={tabs} value={tab} result={e=>setTab(e)}/> */}
                    <div className="grid grid-cols-2 gap-4">
                        <div className="">
                            <FormControl
                                type="text"
                                label="Name of Employee"
                                value={form.fullName}
                                onChange={e => setform({ ...form, fullName: e })}
                                required
                            />
                        </div>
                        <div className="">
                            <FormControl
                                type="email"
                                label="Email"
                                value={form.email}
                                onChange={e => setform({ ...form, email: e })}
                                required
                            />
                        </div>
                        <div className="">
                            <FormControl
                                type="date"
                                label="Start Date"
                                value={datepipeModel.datetostring(form.start_date)}
                                onChange={e => setform({ ...form, start_date: e })}
                                required
                            />
                        </div>
                        <div className="">
                            <FormControl
                                type="date"
                                label="Date Of Birth"
                                value={datepipeModel.datetostring(form.dateOfBirth)}
                                onChange={e => setform({ ...form, dateOfBirth: e })}
                                required
                            />
                        </div>
                        <div className="">
                            <FormControl
                                type="select"
                                label="Number of Days
                                / per week"
                                placeholder="Select Option"
                                value={form.days}
                                options={days}
                                theme="search"
                                onChange={e => setform({ ...form, days: e })}
                                required
                            />
                        </div>

                        <div className="">
                            <FormControl
                                type="radio"
                                label="Gender"
                                placeholder="Select Option"
                                value={form.gender}
                                options={genders}
                                onChange={e => setform({ ...form, gender: e })}
                                required
                                error={getError('gender')}
                            />
                        </div>

                        <div className="">
                            <FormControl
                                type="address"
                                label="Address"
                                value={form.address}
                                onPlaceChange={e=>{
                                    console.log("onPlaceChange",e)
                                    setform({ ...form, 
                                        address: e.address,
                                        city: e.city,
                                        country: e.country,
                                        state: e.state,
                                        postCode: e.zipcode,
                                     })
                                }}
                                onChange={e =>{
                                    // setform({ ...form, address: e })
                                    // console.log(e,"e")
                                    // console.log(form,"form")
                                }}
                                required
                            />
                        </div>
                        <div className="">
                            <FormControl
                                type="text"
                                label="City"
                                value={form.city}
                                onChange={e => setform({ ...form, city: e })}
                                required
                            />
                        </div>
                        <div className="">
                            <FormControl
                                type="text"
                                label="State"
                                value={form.state}
                                onChange={e => setform({ ...form, state: e })}
                                required
                            />
                        </div>
                        <div className="">
                            <FormControl
                                type="text"
                                label="Country"
                                value={form.country}
                                onChange={e => setform({ ...form, country: e })}
                                required
                            />
                        </div>
                        <div className="">
                            <FormControl
                                type="text"
                                label="Post Code"
                                value={form.postCode}
                                onChange={e => setform({ ...form, postCode: e })}
                                required
                            />
                        </div>
                        
                        <div className="">
                            <FormControl
                                type="select"
                                label="Transport Means"
                                displayValue="name"
                                placeholder="Select Option"
                                value={form.transport_means}
                                onChange={e => setform({ ...form, transport_means: e })}
                                options={transport}
                                theme="search"
                                required
                                error={getError('transport_means')}
                            />
                        </div>
                        <div className="">
                            <FormControl
                                type="number"
                                label="Kms travelled"
                                value={form.kms_travelled}
                                maxlength="12"
                                onChange={e => setform({ ...form, kms_travelled: e })}
                                required
                            />
                        </div>

                        <div className="">
                            <FormControl
                                type="select"
                                label="Status"
                                displayValue="name"
                                placeholder="Select Status"
                                value={form.status}
                                onChange={e => setform({ ...form, status: e })}
                                options={statusModel.list}
                                required
                                error={getError('status')}
                            />
                        </div>

                        {/* <div className="col-span-12 md:col-span-6">
                                <label className='lablefontcls'>Image</label><br></br>
                                <ImageUpload model="users" result={e => imageResult(e, 'image')} value={images.image || form.image} />
                            </div> */}
                    </div>


                    <div className="text-right mt-4">

                        <button type="submit" className="text-white bg-gradient-to-br bg-[#71B55C] bg-[#71B55C] hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2">Save</button>
                    </div>
                </div>


            </form>
        </Layout>
    </>
}

export default AddEdit