import swal from "sweetalert"
import ApiClient from "../methods/api/apiClient"
import methodModel from "../methods/methods"
import environment from "../environment"

export const enabledProductPopup=(user)=>{
  ApiClient.get('api/user/profile', { id: user?.supplier_id ? user?.supplier_id.id || user?.supplier_id._id :user._id}).then(res=>{
      if(res.success){
        if(!res.data.enabledProducts&&user?.subRole?.id!=environment.SubRolePartner){
          if(window.location.pathname!="/login"){
          swal({
            title: "Please enable your products",
            buttons:['Close','Enable Products'],
            icon: "success",
              allowOutsideClick: false, // Prevent clicking outside

          }).then(res=>{
            if(res){
              methodModel.route('/product-data')
            }
          })
        }
        }
      }
    })
  }