import { useEffect ,useState,useRef} from "react"
import ApiClient from "../../methods/api/apiClient"
import { useSelector } from "react-redux";
import Layout from "../../components/global/layout";
import PhoneInput from "react-phone-input-2";
import { rolePermission, rolePermissions, roleType } from "../../models/type.model";
import environment from "../../environment";
import loader from "../../methods/loader";
import { FiEdit3, FiPlus } from "react-icons/fi";
import { BsTrash3 } from "react-icons/bs";
import { LuMessageSquarePlus } from "react-icons/lu";
import { BiMessage } from "react-icons/bi";
import Modal from "../../components/common/Modal";
import MultiSelectDropdown from "../../components/common/MultiSelectDropdown";


export default function Partner() {
    const permissions = rolePermissions
    const permission = rolePermission
    const [confirmModal,setConfirmModal]=useState(false);

const [AllProducts,setAllProducts]=useState([]);
    const defaultvalue = () => {
        let keys = { ...roleType };
        Object.keys(roleType).map((itm) => {
            if (itm != "permissions") keys[itm] = null;
        });
        Object.keys(roleType.permissions).map((itm) => {
            keys.permissions[itm] = false;
        });
        keys.status = "active";
        return keys;
    };

    const setpermission = (key, value) => {
        setform({
            ...form,
            permissions: {
                ...form.permissions,
                [key]: value,
            },
        });
    };

    const user =useSelector(state=>state.user);
    const btnref = useRef(null);
    const [addtab, setaddtab] = useState('')
    const [partners, setParteners] = useState([])
    const [partnerRole, setPartnerRole] = useState('')
const [form,setform]=useState({...roleType})
    const [partnerForm, setPartnerForm] = useState({ fullName: '', email: '', mobileNo: '', id: '', contactPerson:'',inviteEmail:false,productIds:[] })
    const ShowModel = () => {
        const modelbtn = document.getElementById("model").click();
    };

    const getPartnerRole = () => {
        const response = (res) => {
            if (res.success) {
                let data = res.data
                if (data.length) {
                    setPartnerRole(data[0].id)
                }
            }
        }
        ApiClient.getAll({ url: 'api/user/roles/listing', params: { page: 1, count: 1, addedBy: user._id, search: 'Partner' }, response })
    }


    const getPartners = () => {
        loader(true);
        const response = (res) => {
            loader(false)
            if (res.success) {
                setParteners(res.data)
            }
        }
        
        ApiClient.getAll({ url: 'api/partners/list', response })
    }

    useEffect(() => {
        if (user.financialYear == "") {
            ShowModel();
        }
        getPartners()
        getPartnerRole()
    }, []);

    const addPartner = () => {
        setaddtab('form')
        setPartnerForm({ fullName: '', email: '', mobileNo: '', id: '' })
    }

    const partnerSubmit = (e) => {
        e.preventDefault()
        if (partnerForm.id){
        PartnerSubmitter()
        }else{
            setConfirmModal(true)
        }
        
    }

    const PartnerSubmitter=(data={})=>{
        console.log(partnerForm,"These are the partner form ---")

        let url = 'api/add/partners'
        let method = 'post'
        if (!partnerForm.id) delete partnerForm.id

        let formData = {
            ...partnerForm,
            ...data,
            role: environment.partnerRole,
            subRole: partnerRole,
            permissions: [form?.permissions]
        }

        let payload = {
            partnerData: [formData]
        }

        if (partnerForm.id) {
            method = 'put'
            url = 'api/user/profile'
            payload = formData

        }

        loader(true)
        ApiClient.allApi(url, payload, method).then(res => {
            loader(false)
            if (res.success) {
                setaddtab('')
                setConfirmModal(false)
                getPartners()
            }
        })
    }

    const editPartner = (item) => {
        let payload = { ...partnerForm }
        Object.keys(payload).map(itm => {
            payload[itm] = item[itm]
        })

        payload.id = item.id
        setPartnerForm({
            ...payload, productIds: item?.partner_products.map((item)=>{
                return item?.id||item?._id
            }) })
        setaddtab('form')

        let value = item;
        let payload1 = form;
        if(item.permissions[0]){

        setform({
            permissions:item.permissions[0]
        });
    }else{
        setform({
            permissions:{
                ...roleType
            }
        });
    }
    }

    const deletePartner = (id) => {
        if (window.confirm("Do you want to delete this")) {
            loader(true)
            ApiClient.delete('api/user/delete', { id: id }).then(res => {
                loader(false)
                if (res.success) {
                    getPartners()
                }
            })
        }

    }


    const HandleAll = (check) => {
        let value = check ? true : false;
        let permissions = form.permissions;
        Object.keys(permissions).map((itm) => {
            permissions[itm] = value;
        });
        setform({ ...form, permissions: permissions });
    };

    const isAllChecked = () => {
        let value = true;
        let permissions = form.permissions;
        Object.keys(permissions).map((itm) => {
            if (!permissions[itm]) value = false;
        });
        return value;
    };


    const HandleAllAdd = (check) => {
        let value = check ? true : false;
        let keys = {};
        permissions.map(itm => {
            keys = { ...keys, [`add${itm.key}`]: value }
        })

        setform({
            ...form,
            permissions: {
                ...form.permissions,
                ...keys
            }
        })
    };
    const HandleallEdit = (check) => {
        let value = check ? true : false;
        let keys = {};
        permissions.map(itm => {
            keys = { ...keys, [`edit${itm.key}`]: value }
        })

        setform({
            ...form,
            permissions: {
                ...form.permissions,
                ...keys
            }
        })
    };
    const HandleAllDelete = (check) => {
        let value = check ? true : false;
        let keys = {};
        permissions.map(itm => {
            keys = { ...keys, [`delete${itm.key}`]: value }
        })

        setform({
            ...form,
            permissions: {
                ...form.permissions,
                ...keys
            }
        })
    };




    const handleAllPermission = (e) => {
        let key = e.name
        let checked = e.checked

        let keys = {};
        permission.map(itm => {
            keys = { ...keys, [`${itm.key}${key}`]: checked }
        })

        setform({
            ...form,
            permissions: {
                ...form.permissions,
                ...keys
            },
        });
    }

    const HandleAllRead = (check) => {
        let value = check ? true : false;

        let keys = {};
        permissions.map(itm => {
            keys = { ...keys, [`read${itm.key}`]: value }
        })

        setform({
            ...form,
            permissions: {
                ...form.permissions,
                ...keys
            }
        })
    };

    const isAllPCheck = (key = 'read') => {
        let value = true;
        permissions.map(itm => {
            if (!form.permissions[`${key}${itm.key}`]) value = false
        })
        return value
    };


    const isCheckAll = (key) => {
        let value = true
        permission.map(itm => {
            if (!form.permissions[`${itm.key}${key}`]) value = false
        })
        return value
    }

    useEffect(()=>{
        setform(defaultvalue());
    },[])

const InvitePartner=(item)=>{
    loader(true)
    ApiClient.post(`api/login/link/partner`,{id:item.id}).then(res=>{
        if(res.success){
            
        getPartners()
        
        }
        loader(false)
    })
}

    const modalResult=()=>{
        setConfirmModal(false)
    }

const [Products,setProducts]=useState([]);
console.log(Products,"These are the Products data thats we want ")
    const getPtoducts = () => {
        ApiClient.get("api/products/listing", { status: "active", addedBy: user?.id || user?._id }).then((res) => {
            if (res.success) {
                setProducts(res.data?.map((item)=>{
                    return {
                        id:item?._id||item?.id,
                        name:item?.name||""
                    }
                }))
            }
        });
    };
    useEffect(()=>{
getPtoducts()
    },[])

    const getProductsValue=(ids)=>{
        console.log(ids,"this are the ids -=-===-=-------------------------------")
const data=Products.filter((itm)=>ids.includes(itm?.id));
return data||[]
    }
  return (
    <div>
        <Layout>
            <>
              <div className="bg-white border border-[#EBEBEB] rounded-[10px] mt-6">
                  <div className="!p-5 border-b border-[#EBEBEB] flex items-center justify-between">
                          <div className="flex flex-col gap-1">
                              <h4 className="text-2xl font-semibold text-[#111827]">
                                  Partners
                              </h4>
                              <p className="text-sm font-normal text-[#75757A]" style={{textWrap:"balance"}}>
                                  If you sell other supplier's products, this section will let you assign products to these suppliers, This will give clarity on revenues and numbers.
                              </p>
                          </div>
                      <div className="flex gap-1" >

                          {addtab == 'form' ? <>
                          </> : <>
                              <button type="button" onClick={addPartner} className="!px-4 py-2 text-sm font-normal text-white  flex items-center justify-center gap-2 bg-[#71B55C] rounded-lg shadow-btn hover:opacity-80 transition-all focus:ring-2 ring-[#EDEBFC] disabled:bg-[#D0CAF6] disabled:cursor-not-allowed">
                              <FiPlus className="text-xl text-white" /> Add Partner
                              </button>
                          </>}
                      </div>

                  </div>
                  <div className="!px-5 !py-4" sty>
                      {addtab == 'form' ? <>
                          <form 
                          onSubmit={partnerSubmit}
                          >
                              <div className="grid grid-cols-2 gap-y-4 gap-x-5 mb-6">
                                  <div>
                                      <label className="text-sm font-normal text-[#75757A] !mb-3">
                                          Name 
                                      </label>
                                      <input
                                          type="text"
                                          className="mt-2 shadow-box bg-white w-full text-sm placeholder:text-gray-500 rounded-lg h-10 flex items-center gap-2 overflow-hidden px-2 !ring-[#71B55C] !outline-[#71B55C] disabled:!bg-gray-200"
                                          value={partnerForm.fullName}
                                          onChange={(e) =>
                                              setPartnerForm({ ...partnerForm, fullName: e.target.value })
                                          }
                                          required
                                      />
                                  </div>
                                      <div>
                                          <label className="text-sm font-normal text-[#75757A] !mb-3">
                                              Products
                                          </label>
                                          <MultiSelectDropdown
                                              options={Products}
                                              intialValue={partnerForm.productIds}
                                              displayValue="name"
                                              id="productCat"
                                              result={(e) =>{ setPartnerForm({ ...partnerForm, productIds: e.value });console.log(e.value,"This is the data thats we want the data")}}
                                          />
                                      </div>
                                      
                                      <div>
                                          <label className="text-sm font-normal text-[#75757A] !mb-3">
                                            Contact  Name 
                                          </label>
                                          <input
                                              type="text"
                                              className="mt-2 shadow-box bg-white w-full text-sm placeholder:text-gray-500 rounded-lg h-10 flex items-center gap-2 overflow-hidden px-2 !ring-[#71B55C] !outline-[#71B55C] disabled:!bg-gray-200"
                                              value={partnerForm.contactPerson
}
                                              onChange={(e) =>
                                                  setPartnerForm({
                                                      ...partnerForm, contactPerson: e.target.value })
                                              }
                                              required
                                          />
                                      </div>
                                  <div>
                                      <label className="text-sm font-normal text-[#75757A] !mb-3">
                                          Contact number
                                      </label>
                                      <PhoneInput
                                          required
                                          enableSearch={true}
                                          placeholder="Enter phone number"
                                          value={partnerForm.mobileNo}
                                          countryCodeEditable={true}
                                          onChange={(e) => {
                                              setPartnerForm({ ...partnerForm, mobileNo: e })
                                          }}
                                          buttonClass="!border-0 !roundehidden !bg-tranparent !rounded-tl-lg !rounded-bl-lg"
                                          inputClass="!border-0 shadow-box !h-10 text-sm placeholder:text-gray-500 flex items-center gap-2 overflow-hidden !px-2 !pl-10 !ring-[#71B55C] !outline-[#71B55C]"
                                      />
                                  </div>
                                  <div>
                                      <label className="text-sm font-normal text-[#75757A] !mb-3">
                                              Email 
                                      </label>
                                      <input
                                          type="email"
                                              disabled={partnerForm.id?true:false}
                                          className="mt-2 shadow-box bg-white w-full text-sm placeholder:text-gray-500 rounded-lg h-10 flex items-center gap-2 overflow-hidden px-2 !ring-[#71B55C] !outline-[#71B55C] disabled:!bg-gray-200"
                                          value={partnerForm.email}
                                          onChange={(e) =>
                                              setPartnerForm({ ...partnerForm, email: e.target.value })
                                          }
                                          required
                                      />
                                  </div>
                                  <div>

                                  </div>
                              </div>
                                          <div className="col-12 bg-white shadow-box rounded-lg w-full">
                                              <div className="flex items-start gap-3 p-6">
                                                  <h5 className="text-typo text-xl font-medium">Permissions</h5>
                                              </div>
                                              <div className="scrollbar w-full overflow-auto">
                                                  <table className="w-full">
                                                      <thead className="border-y border-[#EAECF0]">
                                                          <tr className=" border-y border-[#EAECF0]">
                                                              <th
                                                                  scope="col"
                                                                  className="cursor-pointer text-[#3C3E49A3] !border-l-0 font-normal text-sm !border border-[#EAECF0] !px-5 text-left bg-[#6db56214] !py-3">
                                                                  Role Name
                                                              </th>
                                                              <th
                                                                  scope="col"
                                                                  className="cursor-pointer text-[#3C3E49A3] !border-l-0 font-normal text-sm !border border-[#EAECF0] !px-5 text-left bg-[#6db56214] !py-3">
                                                                  <div className="flex items-center gap-3">
                                                                      <input
                                                                          type="checkbox"
                                                                          className="h-4 w-4 green_check cursor-pointer shrink-0 rounded-[4px] !border !border-[#3C3E49A3] !text-white"
                                                                          onChange={(e) => HandleAll(e.target.checked)}
                                                                          checked={isAllChecked()}
                                                                      />
                                                                      All
                                                                  </div>
                                                              </th>
                                                              <th
                                                                  scope="col"
                                                                  className="cursor-pointer text-[#3C3E49A3] !border-l-0 font-normal text-sm !border border-[#EAECF0] !px-5 text-left bg-[#6db56214] !py-3">
                                                                  <div className="flex items-center gap-3">
                                                                      <input
                                                                          type="checkbox"
                                                                          className="h-4 w-4 green_check cursor-pointer shrink-0 rounded-[4px] !border !border-[#3C3E49A3] !text-white"
                                                                          onChange={(e) => HandleAllRead(e.target.checked)}
                                                                          checked={isAllPCheck('read')}
                                                                      />
                                                                      Read
                                                                  </div>
                                                              </th>
                                                              <th
                                                                  scope="col"
                                                                  className="cursor-pointer text-[#3C3E49A3] !border-l-0 font-normal text-sm !border border-[#EAECF0] !px-5 text-left bg-[#6db56214] !py-3">
                                                                  <div className="flex items-center gap-3">
                                                                      <input
                                                                          type="checkbox"
                                                                          className="h-4 w-4 green_check cursor-pointer shrink-0 rounded-[4px] !border !border-[#3C3E49A3] !text-white"
                                                                          onChange={(e) => HandleAllAdd(e.target.checked)}
                                                                          checked={isAllPCheck('add')}
                                                                      />
                                                                      Add
                                                                  </div>
                                                              </th>
                                                              <th
                                                                  scope="col"
                                                                  className="cursor-pointer text-[#3C3E49A3] !border-l-0 font-normal text-sm !border border-[#EAECF0] !px-5 text-left bg-[#6db56214] !py-3">
                                                                  <div className="flex items-center gap-3">
                                                                      <input
                                                                          type="checkbox"
                                                                          className="h-4 w-4 green_check cursor-pointer shrink-0 rounded-[4px] !border !border-[#3C3E49A3] !text-white"
                                                                          onChange={(e) => HandleallEdit(e.target.checked)}
                                                                          checked={isAllPCheck('edit')}
                                                                      />
                                                                      Edit
                                                                  </div>
                                                              </th>
                                                              <th
                                                                  scope="col"
                                                                  className="cursor-pointer text-[#3C3E49A3] !border-l-0 font-normal text-sm !border border-[#EAECF0] !px-5 text-left bg-[#6db56214] !py-3">
                                                                  <div className="flex items-center gap-3">
                                                                      <input
                                                                          type="checkbox"
                                                                          className="h-4 w-4 green_check cursor-pointer shrink-0 rounded-[4px] !border !border-[#3C3E49A3] !text-white"
                                                                          onChange={(e) => HandleAllDelete(e.target.checked)}
                                                                          checked={isAllPCheck('delete')}
                                                                      />
                                                                      Delete
                                                                  </div>
                                                              </th>
                                                          </tr>
                                                      </thead>
                                                      <tbody className="roleTable">
                                                          {permissions.map(itm => {
                                                              return <>
                                                                  <tr>
                                                                      <td className="!text-typo !border-l-0 cursor-pointer !px-5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]">
                                                                          {itm.name}
                                                                      </td>
                                                                      <td className="!text-typo !border-l-0 cursor-pointer !px-5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]">
                                                                          <input
                                                                              type="checkbox"
                                                                              className="h-4 w-4 green_check cursor-pointer shrink-0 rounded-[4px] !border !border-[#3C3E49A3] !text-white"
                                                                              name={itm.key}
                                                                              onChange={(e) =>
                                                                                  handleAllPermission(e.target)
                                                                              }
                                                                              checked={isCheckAll(itm.key)}
                                                                          />
                                                                      </td>
                                                                      {permission.map(pitm => {
                                                                          return <td className="!text-typo !border-l-0 cursor-pointer !px-5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]">
                                                                              <div Name="checkList">
                                                                                  <label className="mb-0">
                                                                                      <input
                                                                                          type="checkbox"
                                                                                          className="h-4 w-4 green_check cursor-pointer shrink-0 rounded-[4px] !border !border-[#3C3E49A3] !text-white"
                                                                                          checked={form.permissions[`${pitm.key}${itm.key}`]}
                                                                                          onChange={(e) =>
                                                                                              setpermission(`${pitm.key}${itm.key}`, e.target.checked)
                                                                                          }
                                                                                      />
                                                                                  </label>
                                                                              </div>
                                                                          </td>
                                                                      })}
                                                                  </tr>
                                                              </>
                                                          })}

                                                      </tbody>
                                                  </table>
                                              </div> 
                                          </div>
                              <div className="flex gap-3 mt-4 mr-4">
                                  <button type="button" onClick={() => setaddtab('')} className="ml-auto !px-4 text-sm font-normal text-white h-9 flex items-center justify-center gap-2 bg-[#71B55C] rounded-lg shadow-btn hover:opacity-80 transition-all focus:ring-2 ring-[#EDEBFC] disabled:bg-[#D0CAF6] disabled:cursor-not-allowed">
                                      Cancel
                                  </button>
                                      <button type="submit"  className="!px-4 text-sm font-normal text-white h-9 flex items-center justify-center gap-2 bg-[#71B55C] rounded-lg shadow-btn hover:opacity-80 transition-all focus:ring-2 ring-[#EDEBFC] disabled:bg-[#D0CAF6] disabled:cursor-not-allowed">
                                      Save
                                  </button>
                              </div>
                          </form>
                      </> : <>
                          <table className="min-w-full text-sm font-medium text-left">
                              <thead className="border-y border-[#EAECF0] font-normal">
                                  <tr className="text-[#7F828B] !bg-[#6db56236]">
                                      <th
                                          scope="col"
                                          className="border-r px-[20px] py-[16px] border-[#EAECF0] min-w-[225px] flex items-center gap-1 font-normal">
                                          Name
                                          {/* <AiOutlineArrowDown className="text-sm text-[#7F828B]" /> */}
                                      </th>
                                              
                                              <th
                                                  className="border-r px-[20px] py-[16px] border-[#EAECF0]  font-normal"
                                                  >
                                                  Contact Name
                                                  {/* <AiOutlineArrowDown className="text-sm text-[#7F828B]" /> */}
                                              </th>
                                      <th
                                          scope="col"
                                          className="border-r px-[20px] py-[16px] border-[#EAECF0]  font-normal">
                                          Email
                                      </th>
                                              <th
                                                  scope="col"
                                                  className="border-r px-[20px] py-[16px] border-[#EAECF0]  font-normal">
                                                  Total Products
                                              </th>
                                              <th
                                                  scope="col"
                                                  className="border-r px-[20px] py-[16px] border-[#EAECF0]  font-normal">
                                                  Sales
                                              </th>
                                      <th
                                          scope="col"
                                          className="border-r px-[20px] py-[16px] border-[#EAECF0]  font-normal">
                                          Action
                                      </th>
                                  </tr>
                              </thead>
                              <tbody className="text-typo font-normal text-left">
                                  {partners.map(itm => {
                                      return <tr className="border-b border-[#EAECF0]">
                                          <td className="border-r px-[20px] py-[16px] border-[#EAECF0]">
                                              {itm.fullName}
                                          </td>
                                          <td className="border-r px-[20px] py-[16px] border-[#EAECF0]">
                                              {itm.contactPerson||"---"}
                                          </td>
                                          <td className="border-r px-[20px] py-[16px] border-[#EAECF0]">
                                              {itm.email}
                                          </td>
                                          <td className="border-r px-[20px] py-[16px] border-[#EAECF0]">
                                              {itm?.total_partner_products}
                                          </td>
                                          <td className="border-r px-[20px] py-[16px] border-[#EAECF0]">
                                              {itm?.total_bookings }
                                          </td>
                                          <td className="border-r px-[20px] py-[16px] border-[#EAECF0]">
                                              <div className="flex gap-3">
                                                  <button
                                                      onClick={(e) => editPartner(itm)}
                                                      title="Edit"
                                                      className="border cursor-pointer ! border-[#E9253129] hover:opacity-70 rounded-lg bg-[#fee9ea] w-10 h-10 !text-[#71B55C] flex items-center justify-center text-xl">
                                                       <FiEdit3 /> 
                                                  </button>
                                                  <button
                                                      onClick={(e) => deletePartner(itm.id)}
                                                      title="Delete"
                                                      className="border cursor-pointer !border-[#E9253129] hover:opacity-70 rounded-lg bg-[#FDE9EA] w-10 h-10 text-[#E92531] flex items-center justify-center text-xl">
                                                       <BsTrash3 /> 
                                                  </button>
                                                  <button
                                                      title={itm?.invite_email?"Invited":"Invite"}
                                                      disabled={itm?.invite_email} onClick={e => InvitePartner(itm)} className="border !border-[#94D5AE] rounded-lg bg-[#ECF4EF] hover:opacity-70 w-10 h-10 text-[#11a74da4] flex items-center justify-center text-xl">
                                                      
                                                      {itm?.invite_email ? <BiMessage /> : <LuMessageSquarePlus />
}
                                                  </button>
                                              </div>

                                          </td>
                                      </tr>
                                  })}
                              </tbody>
                          </table>
                      </>}

                      {addtab!='form'&&partners.length==0?<span>No data</span>:null}


                  </div>
              </div>
                  <Modal
                      isHeader={false}
                      isFooter={false}
                      isOpen={confirmModal}
                      result={modalResult}
                      content={<>
                          <div className="py-5 border-b text-center">
                              <img src="/assets/img/logo.png" className="inline-block w-[200px]" />
                          </div>
                          <div className="text-center">
                              <img src="/assets/img/popupbanner.png" className="confirmpopupImg" />
                              <h4 className="font-medium text-lg">Do you want to Send Login Details to Partner?</h4>
                              <button type="button" className="text-sm px-6 py-3 bg-[#71B55C] text-white font-medium mt-3 rounded-lg" onClick={() => {
                                  setPartnerForm({ ...partnerForm, inviteEmail :true});
                                  PartnerSubmitter({inviteEmail:true})
                              }}>Yes</button>
                              <div className="mt-3">
                                  <a className="text-primary font-weight-500 text-sm cursor-pointer" onClick={() => {
                                      setPartnerForm({ ...partnerForm, inviteEmail: true });
                                      PartnerSubmitter({ inviteEmail: false })
}}>No</a>
                              </div>
                          </div>
                      </>}
                  />
          </> 
          </Layout>
    </div>
  )
}
