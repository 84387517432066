import { useEffect, useState } from "react"
import SelectDropdown from "../SelectDropdown"
import LineChart from "../LineChart";
import BarChart from "../BarChart";
import PieChart from "../PieChart";

export default ({data=[],legends=[]})=>{
    const [type,setType]=useState('Line')

    const options=[
        {id:'Line',name:'Line Chart'},
        {id:'Bar',name:'Bar Chart'},
        // {id:'Pie',name:'Pie Chart'},
    ]

    useEffect(()=>{

    },[])
    
    return <>
    <div className="">
        <div className="flex mb-3">
        <div className="ml-auto">
        <SelectDropdown
            displayValue="name"
            placeholder="Select Chart"
            options={options}
            intialValue={type}
            result={e=>setType(e.value)}
            noDefault={true}
            />
        </div>
        </div>
        {type=='Line'?<>
        <LineChart data={data} legends={legends} />
        </>:<></>}

        {type=='Bar'?<>
        <BarChart data={data} legends={legends} />
        </>:<></>}
        {type=='Pie'?<>
        <PieChart data={data} legends={legends} />
        </>:<></>}
        
    </div>
    </>
}