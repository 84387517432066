import React from "react";
import './style.scss';
import Select from "react-select";
import { colourStyles } from "../../../utils/constants";

const Html = ({ options,selectedValues,handleChange,displayValue,id,className}) => {
    return <>
        <div className={className||" selectDropdown"}>
             <Select
               styles={colourStyles}
    defaultValue={displayValue}
    isMulti
    value={selectedValues||[]}
    options={options?.map(itm => { return { value: itm.id, label: itm[displayValue] } }) || []}
    className={"basic-multi-select"}
    classNamePrefix="select"
    onChange={e => handleChange(e)}
  />
        </div>
    </>
}

export default Html