import { useRef, useEffect, useState } from "react";
import { Box, Column, Heading, Row, Stack, Button, PageHeader } from "gestalt";
import WebViewer from "@pdftron/webviewer";
import "gestalt/dist/gestalt.css";
import loader from "../../methods/loader";
import ApiClient from "../../methods/api/apiClient";
import { useNavigate } from "react-router-dom";
import { IoCheckmarkSharp } from "react-icons/io5";
import { MdStart } from "react-icons/md";


import { toast } from "react-toastify";
// import './PrepareDocument.css';

const SignatureDocument = () => {
  const Navigate = useNavigate();
  const [FieldLength,setFieldLength]=useState(0);
  const [IsAgreed, setIsAgreed] = useState(false);
  const [annotPosition, setAnnotPosition] = useState(0);
  const [PagesCont,setPagesCont]=useState(0);
  const [SignDocument, setSignDocument] = useState(false);
  const SearchParams = new URLSearchParams(window.location.search);
  const url = SearchParams.get(`url`);
  const id = SearchParams.get("id");
  const copy = SearchParams.get("copy") || false;
  const custom = SearchParams.get("custom") || false;
  const templateId = SearchParams.get("templateId");
  const resellerId = SearchParams.get("resellerId");
  const [data, setdata] = useState({ fileUrl: "", type: "file" });
  const [instance, setInstance] = useState(null);
  const [dropPoint, setDropPoint] = useState(null);
  const [Error, setError] = useState(false);

  const viewer = useRef(null);
  const filePicker = useRef(null);

  // if using a class, equivalent of componentDidMount
  useEffect(() => {
    if (url) {
      WebViewer(
        {
          path: "webviewer",
          initialDoc: url,
          disabledElements: [
            "ribbons",
            "toggleNotesButton",
            "searchButton",
            "menuButton",
            "rubberStampToolGroupButton",
            "stampToolGroupButton",
            "fileAttachmentToolGroupButton",
            "calloutToolGroupButton",
            "undo",
            "redo",
            'textPopup',
            'viewControlsButton',
            'leftPanelButton',
            'viewControlsOverlay',
            'tools',
            'annotationStyleEditButton',
'resize',
            'pannels',
            'pannel',
            'contextMenuPopup',
            "eraserToolButton",
          ],
        },
        viewer.current
      ).then((instance) => {
        const { annotationManager, Annotations } = instance.Core;
        
        setPagesCont(instance.UI.getPageCount());
        setInstance(instance);
        annotationManager.addEventListener('annotationChanged',(annotation,action)=>{
          if(action=='add'){
        const annotationsList = annotationManager.getAnnotationsList();
        const NewList = annotationsList.map((annot) => annot instanceof Annotations.TextWidgetAnnotation || annot instanceof Annotations.SignatureWidgetAnnotation)
        // alert(NewList.length)
          }
        })
      
 
     



        const normalStyles = (widget) => {
          if (widget instanceof Annotations.TextWidgetAnnotation) {
            return {
              'background-color': '#a5c7ff',
              color: 'white',
            };
          } else if (widget instanceof Annotations.SignatureWidgetAnnotation) {
widget.NoResize=true;
annotationManager.updateAnnotation(widget);
            return {
              
            };
          }
        };

        annotationManager.addEventListener('annotationChanged', (annotations, action, { imported }) => {
          if (imported && action === 'add') {
            annotations.forEach(function (annot) {
              annot.NoResize=true
              if (annot instanceof Annotations.WidgetAnnotation) {
                Annotations.WidgetAnnotation.getCustomStyles = normalStyles;

              }
            });
          }
        });

        // Customize the appearance of text fields
     
      });

    }
  }, [url]);

  const applyFields = async () => {
    // const { Annotations, documentViewer } = instance.Core;
    // const annotationManager = documentViewer.getAnnotationManager();
    // const fieldManager = annotationManager.getFieldManager();
    // const annotationsList = annotationManager.getAnnotationsList();
    // const annotsToDelete = [];
    // const annotsToDraw = [];

    // await Promise.all(
    //   annotationsList.map(async (annot, index) => {
    //     let inputAnnot;
    //     let field;

    //     if (typeof annot.custom !== "undefined") {
    //       // create a form field based on the type of annotation
    //       if (annot.custom.type === "TEXT") {
    //         field = new Annotations.Forms.Field(
    //           annot.getContents() + Date.now() + index,
    //           {
    //             type: "Tx",
    //             placeholder: "Type here.",
    //           }
    //         );
    //         inputAnnot = new Annotations.TextWidgetAnnotation(field, {
    //           appearance: '_DEFAULT',
    //           transparentBackground: false,
    //           appearances: {
    //             _DEFAULT: {
    //               Normal: {
    //                 data:
    //                   'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAYdEVYdFNvZnR3YXJlAHBhaW50Lm5ldCA0LjEuMWMqnEsAAAANSURBVBhXY/j//z8DAAj8Av6IXwbgAAAAAElFTkSuQmCC',
    //                 offset: {
    //                   x: 100,
    //                   y: 100,
    //                 },
    //               },
    //             },
    //           },
    //         });
    //       } else if (annot.custom.type === "SIGNATURE") {
    //         field = new Annotations.Forms.Field(
    //           annot.getContents() + Date.now() + index,
    //           {
    //             type: "Sig",
    //           }
    //         );
    //         inputAnnot = new Annotations.SignatureWidgetAnnotation(field, {
    //           appearance: "_DEFAULT",
    //           appearances: {
    //             _DEFAULT: {
    //               Normal: {
    //                 data: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAYdEVYdFNvZnR3YXJlAHBhaW50Lm5ldCA0LjEuMWMqnEsAAAANSURBVBhXY/j//z8DAAj8Av6IXwbgAAAAAElFTkSuQmCC",
    //                 offset: {
    //                   x: 100,
    //                   y: 100,
    //                 },
    //               },
    //             },
    //           },
    //         });
    //       } else if (annot.custom.type === "DATE") {
    //         field = new Annotations.Forms.Field(
    //           annot.getContents() + Date.now() + index,
    //           {
    //             type: "Tx",
    //             value: "m-d-yyyy",
    //             actions: {
    //               F: [
    //                 {
    //                   name: "JavaScript",
    //                   javascript: 'AFDate_FormatEx("mmm d, yyyy");',
    //                 },
    //               ],
    //               K: [
    //                 {
    //                   name: "JavaScript",
    //                   javascript: 'AFDate_FormatEx("mmm d, yyyy");',
    //                 },
    //               ],
    //             },
    //           }
    //         );

    //         inputAnnot = new Annotations.DatePickerWidgetAnnotation(field);
    //       } else {
    //         // exit early for other annotations
    //         annotationManager.deleteAnnotation(
    //           annot,
    //           false,
    //           true
    //         ); // prevent duplicates when importing xfdf
    //         return;
    //       }
    //     } else {
    //       // exit early for other annotations
    //       return;
    //     }

    //     // set position
    //     inputAnnot.PageNumber = annot.getPageNumber();
    //     inputAnnot.X = annot.getX();
    //     inputAnnot.Y = annot.getY();
    //     inputAnnot.rotation = annot.Rotation;
    //     if (annot.Rotation === 0 || annot.Rotation === 180) {
    //       inputAnnot.Width = annot.getWidth();
    //       inputAnnot.Height = annot.getHeight();
    //     } else {
    //       inputAnnot.Width = annot.getHeight();
    //       inputAnnot.Height = annot.getWidth();
    //     }

    //     // delete original annotation
    //     annotsToDelete.push(annot);

    //     // customize styles of the form field
    //     Annotations.WidgetAnnotation.getCustomStyles = function (widget) {
    //       if (widget instanceof Annotations.SignatureWidgetAnnotation) {
    //         return {
    //           border: "1px solid #6956e5 !important",
    //         };
    //       }
    //     };
    //     Annotations.WidgetAnnotation.getCustomStyles(inputAnnot);

    //     // draw the annotation in the viewer
    //     annotationManager.addAnnotation(inputAnnot);
    //     fieldManager.addField(field);
    //     annotsToDraw.push(inputAnnot);
    //   })
    // );

    // // delete old annotations
    // annotationManager.deleteAnnotations(annotsToDelete, null, true);

    // // refresh viewer
    // await annotationManager.drawAnnotationsFromList(annotsToDraw);
    await uploadForSigning();
  };


  const uploadForSigning = async () => {
    const { documentViewer, annotationManager } = instance.Core;
    const doc = documentViewer.getDocument();
    const xfdfString = await annotationManager.exportAnnotations({ widgets: true, links: true });
    const data = await doc.getFileData({ xfdfString,flatten:true});
    const arr = new Uint8Array(data);
    const blob = new Blob([arr], { type: "application/pdf" });
    loader(true);
    ApiClient.postFormData("api/upload/document", { file: blob }).then(
      (res) => {
        if (res.fileName) {
          setdata({ ...data, fileUrl: res?.fileName });
          if (res?.fileName) {
            send(res?.fileName);
          }
        }
        loader(false);
      }
    );
    const url = URL.createObjectURL(blob);

    // Trigger download
    // const link = document.createElement('a');
    // link.href = url;
    // link.download = 'modified.pdf';
    // document.body.appendChild(link);
    // link.click();
    // document.body.removeChild(link);
  };

  const navigateToField = (step,type="") => {
    const { annotationManager }=instance.Core
    const annots = annotationManager.getAnnotationsList();
    const newPosition = annotPosition + step;

    if (annots[newPosition]) {
      annotationManager.jumpToAnnotation(annots[newPosition]);
      if(type!=""){
      setAnnotPosition(newPosition);
      }
    }
    

  };

  const submitForm = async () => {
    const { annotationManager, Annotations } = instance.Core;
    if (!IsAgreed) {
      setError("Please agree with the Terms and Conditions");
      return;
    }

    const annotationsList = annotationManager.getAnnotationsList();

    const signatureAnnotations = annotationsList.filter(
      (annot) => annot.custom && annot.custom.type === "SIGNATURE"
    );

    const isSigned = signatureAnnotations.some(
      (annot) => annot.custom && annot.custom.value
    );

    const signatureWidgetAnnots = annotationsList.filter(
      (annot) => annot instanceof Annotations.SignatureWidgetAnnotation
    );

    const textWidgetAnnots = annotationsList.filter(
      (annot) => annot instanceof Annotations.TextWidgetAnnotation
    );

    setError("");

    // Check for unsigned signature fields
    const unsignedSignatureFields = signatureWidgetAnnots.filter(
      (item) => item["Jg"].innerText === "Sign here" 
    );

    // Check for empty text fields
    const emptyTextFields = textWidgetAnnots.filter(
      (item) =>{
        return item["Jg"].querySelector('input').value == ""
      }
    );
    console.log(emptyTextFields,"These are the empty text fields data ---------")
    if (unsignedSignatureFields.length > 0 || emptyTextFields.length > 0) {
      // There are unsigned signature fields or empty text fields
      const firstFieldToFocus = unsignedSignatureFields.length > 0
        ? unsignedSignatureFields[0]
        : emptyTextFields[0];

      // Focus on the first field that needs attention
      annotationManager.jumpToAnnotation(firstFieldToFocus);
      setError("Please sign/fill all required fields");
      return;
    }
    // If everything is signed/filled, proceed with form submission
    await applyFields();

  };


  const send = (newurl) => {
    let payload = {
      contractId: SearchParams.get("contract"),
      sign: url,
      font: "",
      fileUrl: newurl,
    };
    loader(true);
    ApiClient.put("api/contract?id=" + payload.contractId, payload).then(
      (res) => {
        loader(false);
        if (res.success) {
          Navigate.push(
            `/thanku?contractId=${SearchParams.get(
              "contract"
            )}&downloadurl=${newurl}`
          );
        }
      }
    );
  };

  const StartHereButton = () => {
    const { annotationManager, Annotations } = instance.Core;

    const annotationsList = annotationManager.getAnnotationsList();

    const signatureAnnotations = annotationsList.filter(
      (annot) => annot.custom && annot.custom.type === "SIGNATURE"
    );

    const isSigned = signatureAnnotations.some(
      (annot) => annot.custom && annot.custom.value
    );

    const signatureWidgetAnnots = annotationsList.filter(
      (annot) => annot instanceof Annotations.SignatureWidgetAnnotation
    );

    const textWidgetAnnots = annotationsList.filter(
      (annot) => annot instanceof Annotations.TextWidgetAnnotation
    );

    setError("");

    // Check for unsigned signature fields
    const unsignedSignatureFields = signatureWidgetAnnots.filter(
      (item) => item["Jg"].innerText === "Sign here"
    );

    // Check for empty text fields
    const emptyTextFields = textWidgetAnnots.filter(
      (item) => item["Jg"].querySelector('input').value == "Type here." || item["Jg"].querySelector('input').value==""
    );

    console.log(emptyTextFields, "These are the empty text fields data ---------");

    if (unsignedSignatureFields.length > 0 || emptyTextFields.length > 0) {
      // There are unsigned signature fields or empty text fields
      const firstFieldToFocus =
        unsignedSignatureFields.length > 0
          ? unsignedSignatureFields[0]
          : emptyTextFields[0];

      // Change the background color of the text field to yellow
      emptyTextFields.map((item,index)=>{
        const textFieldInput = emptyTextFields[0]["Jg"].querySelector('input');
        textFieldInput.value=""
        textFieldInput.style.backgroundColor = "yellow";
      })
    
    unsignedSignatureFields.map((item,index)=>{
      const input=unsignedSignatureFields[0]['Jg'];
       input.style.border='1px solid #a5c7ff';
      
    })


      // Focus on the first field that needs attention
      annotationManager.jumpToAnnotation(firstFieldToFocus);
    }
  };

  return (
    <div className={"prepareDocument"}>
        <div className="container mx-auto h-screen">
            <div className="grid grid-cols-12 gap-4">
                <div className="col-span-12 md:col-span-12 mt-3">
                
            <button className="!px-4 text-sm font-normal text-white h-10 flex items-center justify-center gap-2 bg-[#71B55C] rounded-lg shadow-btn hover:opacity-80 transition-all focus:ring-2 ring-[#EDEBFC] disabled:bg-[#D0CAF6] disabled:cursor-not-allowed" onClick={e => StartHereButton()}> <MdStart /> Start Here</button>
                 {/* {FieldLength>0?
                 <span>There are {FieldLength} fields. All are required </span>
                 :null} */}
                
                </div>  
             
                <div className="col-span-12 md:col-span-12">
                    <div className="webviewer" ref={viewer}></div>
                </div>


                <div className="col-span-12 md:col-span-12 bg-gray-200 p-4 rounded-sm">
                    <div className="flex items-center justify-between">
                    <div className="flex items-center gap-x-2 ">
                        <input
                          type="checkbox"
                          className="h-4 w-4"
                          role="switch"
                          checked={IsAgreed}
                          onChange={(e) => { setIsAgreed(e.target.checked); Error =='Please agree with the Terms and Conditions'?setError(""):null}}
                        />{" "}
                          I here by agree above terms and condition and rates
                    </div>
                      <div className="flex items-center gap-x-8">
                          {/* {PagesCont==0?null: */}
                      {/* <div className="flex items-center gap-2">
                        <button onClick={e => navigateToField(1)} className="!px-4 text-sm font-normal text-white h-10 flex items-center justify-center gap-2 bg-[#71B55C] rounded-lg shadow-btn hover:opacity-80 transition-all focus:ring-2 ring-[#EDEBFC] disabled:bg-[#D0CAF6] disabled:cursor-not-allowed">Previous Field</button>
                        <button onClick={e => navigateToField(-1)} className="!px-4 ml-3 text-sm font-normal text-white h-10 flex items-center justify-center gap-2 bg-[#71B55C] rounded-lg shadow-btn hover:opacity-80 transition-all focus:ring-2 ring-[#EDEBFC] disabled:bg-[#D0CAF6] disabled:cursor-not-allowed">Next Field</button>
                      </div> */}
                      </div>
                   




                  <div className="right_end">
                    {Error ? <span className="text-red-600 flex justify-end items-end mb-2">{Error} </span> : null}
                    <div className="flex justify-end items-end">
                        
                        <button
                        className="!px-4 text-sm font-normal text-white h-10 flex items-center justify-center gap-2 bg-[#71B55C] rounded-lg shadow-btn hover:opacity-80 transition-all focus:ring-2 ring-[#EDEBFC] disabled:bg-[#D0CAF6] disabled:cursor-not-allowed"
                        onClick={submitForm}
                        accessibilityLabel="Submit Form"
                        >
                          <IoCheckmarkSharp /> Submit Contract
                        </button>
                    </div>
                </div>
                </div>

                </div>

               
            </div>
        </div>
    </div>
  );
};

export default SignatureDocument;
