import React, { useState, useEffect } from "react";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import methodModel from "../../methods/methods";
import { Link, useNavigate, useParams } from "react-router-dom";
import Layout from "../../components/global/layout";
import statusModel from "../../models/status.model";
import { Tooltip } from "antd";
import FormControl from "../../components/common/FormControl";
import shared from "./shared";
import { useSelector } from "react-redux";
import environment from "../../environment";

const AddEdit = () => {
  const { id } = useParams();
  const [images, setImages] = useState({ image: "" });
  const [form, setform] = useState({
    id: "",
    product_id: "",
    status: "active",
    vehicle_id: "",
    days: "",
    capacity: "",
    isAccommodation: "no",
    isActivity: "no",
    source: "",
    total_km:'',
  });
  const [accommodations, setAccommodations] = useState([]);
  const [activities, setActivities] = useState([]);
  const [accomodationType, setAccomodationType] = useState([]);
  const [activityType, setActivityType] = useState([]);
  const [tours, setTours] = useState([]);
  const [vehicles, setVehicles] = useState([]);

  const history = useNavigate();
  const [submitted, setSubmitted] = useState(false);
  const user = useSelector((state) => state.user);
  const formValidation = [{ key: "status", required: true }];

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    let invalid = methodModel.getFormError(formValidation, form);
    if (invalid) return;
    let method = "post";
    let url = shared.addApi;
    let total_km=form.total_km||0
    if(form.isAccommodation=='yes'){
      total_km=0
      accommodations.map(itm=>{
        total_km=total_km+Number(itm.kms||0)
      })
    }
    let value = {
      ...form,
      ...images,
      accommodations: accommodations,
      activities: activities,
      total_km:total_km
    };
    if (value.id) {
      method = "put";
      url = shared.editApi;
    } else {
      delete value.id;
    }

    loader(true);
    ApiClient.allApi(url, value, method).then((res) => {
      if (res.success) {
        // ToastsStore.success(res.message)
        history(`/${shared.url}`);
      }
      loader(false);
    });
  };

  useEffect(() => {
    if (id) {
      loader(true);
      ApiClient.getAll({
        url: shared.detailApi,
        params: { id },
        response: (res) => {
          if (res.success) {
            let value = res.data;
            let payload = form;

            Object.keys(payload).map((itm) => {
              payload[itm] = value[itm];
            });

            payload.id = id;
            if (payload.product_id._id)
              payload.product_id = payload.product_id._id;
            if (payload.vehicle_id._id)
              payload.vehicle_id = payload.vehicle_id._id;
            if (payload.vehicle_id._id)
              payload.vehicle_id = payload.vehicle_id._id;
            setform({
              ...payload,
            });

            let img = images;
            Object.keys(img).map((itm) => {
              img[itm] = value[itm];
            });
            setImages({ ...img });
            setAccommodations(value.accommodations || []);
            setActivities(value.activities || []);
          }
          loader(false);
        },
      });
    }
  }, [id]);

  const imageResult = (e, key) => {
    images[key] = e.value;
    setImages(images);
  };

  const getError = (key) => {
    return submitted
      ? methodModel.getError(key, form, formValidation)?.message
      : "";
  };

  const units = ["kgCO2e/GJ", "kgCO2e/litre"].map((itm) => ({
    name: itm,
    id: itm,
  }));

  const scopes = [1, 2, 3].map((itm) => ({ name: itm, id: itm }));

  const [tab, setTab] = useState("Vehicles");
  const tabs = [
    { id: "Vehicles", name: "Vehicles" },
    { id: "Accomodation", name: "Accomodation" },
    { id: "Activities", name: "Activities" },
  ];

  let days = [];
  for (let i = 1; i <= 30; i++) {
    let name = `${i} Days`;
    if (i == 1) name = `1 Day`;
    days.push({ id: i, name: name });
  }

  const addAccomodation = (isAccommodation = "", day = "") => {
    let arr = [];
    let p = isAccommodation || form.isAccommodation;
    let days = day || form.days;
    if (p == "yes") {
      for (let i = 1; i <= Number(days); i++) {
        arr.push({
          day: i,
          accomandation_id: null,
        });
      }
    }

    setAccommodations([...arr]);
  };

  const updateAccomodation = (i, key = "", value) => {
    let arr = accommodations;
    arr[i][key] = value||null;
    setAccommodations([...arr]);
  };

  const updateActivity = (i, key = "", value) => {
    let arr = activities;
    arr[i][key] = value;
    setActivities([...arr]);
  };

  const addActivity = () => {
    let arr = activities || [];
    arr.push({ category_id: "", cost: "" });
    setActivities([...arr]);
  };

  const removeActivity = (i) => {
    let arr = activities || [];
    arr = arr.filter((itm, index) => i != index);
    setActivities([...arr]);
  };

  const getAccommodationType = () => {
    ApiClient.get("api/emission-factors", {
      emission_type: "accomodation",
      status: "active",
    }).then((res) => {
      if (res.success) {
        setAccomodationType(
          res.data.map((itm) => {
            return {
              id: itm.id,
              name: `${itm.emission_source} (${itm?.country_detail?.name||'Globaly'})`,
            };
          })
        );
      }
    });
  };

  const getActivityType = () => {
    ApiClient.get("api/categories/listing", {
      addedBy: user._id,
      catType: environment.activityTypeId,
      status: "active",
    }).then((res) => {
      if (res.success) {
        setActivityType(res.data);
      }
    });
  };

  const getVehicles = () => {
    ApiClient.get("api/emission-factors", {
      emission_type: "vehicle",
      status: "active",
    }).then((res) => {
      if (res.success) {
        setVehicles(
          res.data.map((itm) => {
            return {
              id: itm.id,
              name: `${itm.emission_source} (${itm.fuel_type}) - ${itm.condition}`,
            };
          })
        );
      }
    });
  };

  const getTours = () => {
    ApiClient.get("api/products/listing", {
      addedBy: user._id,
      status: "active",
      count: 50,
      page: 1,
    }).then((res) => {
      if (res.success) {
        setTours(
          res.data.map((itm) => {
            return {
              id: itm.id,
              name: `${itm.name}`,
            };
          })
        );
      }
    });
  };

  useEffect(() => {
    getAccommodationType();
    getActivityType();
    getVehicles();
    getTours();
  }, []);

  return (
    <>
      <Layout>
        <form onSubmit={handleSubmit}>
          <div className="bg-white shadow-box rounded-lg max-w-[1020px] ">
            <div className="flex items-center p-4 mb-8 border-b border-solid ">
              <Tooltip placement="top" title="Back">
                <Link
                  to={`/${shared.url}`}
                  className="!px-4  py-2 flex items-center justify-center  rounded-lg shadow-btn hover:bg-[#6db56224] border transition-all  mr-3"
                >
                  <span class="material-symbols-outlined">arrow_back</span>
                </Link>
              </Tooltip>
              <div>
                <h3 className="text-base font-medium text-[#0A0D14]">
                  {form && form.id ? "Edit" : "Add"} {shared.addTitle}
                </h3>
                <p class="text-sm font-normal text-[#75757A]">
                  Here you can see all about your {shared.addTitle}
                </p>
              </div>
            </div>

            {/* <FormTabs list={tabs} value={tab} result={e=>setTab(e)}/> */}
            <div className="grid grid-cols-2 gap-4 p-4">
              <div className="">
                <FormControl
                  type="select"
                  label="Name of Tour"
                  placeholder="Name of Tour"
                  value={form.product_id}
                  options={tours}
                  theme="search"
                  onChange={(e) => setform({ ...form, product_id: e })}
                  required
                />
              </div>
              <div className="">
                <FormControl
                  type="select"
                  label="Vehicle Type"
                  placeholder="Vehicle Type"
                  value={form.vehicle_id}
                  options={vehicles}
                  theme="search"
                  onChange={(e) => setform({ ...form, vehicle_id: e })}
                  required
                />
              </div>

            
              
              <div className="">
                <FormControl
                  type="select"
                  placeholder="Select Days"
                  label="Number of Days"
                  options={days}
                  value={form.days}
                  theme="search"
                  onChange={(e) => {
                    addAccomodation("", e);
                    setform({ ...form, days: e });
                  }}
                  required
                />
              </div>

              <div className="">
                <FormControl
                  type="number"
                  label="Tour Capacity"
                  value={form.capacity}
                  maxlength="3"
                  onChange={(e) => setform({ ...form, capacity: e })}
                  required
                />
              </div>

              <div className="">
                <FormControl
                  type="radio"
                  label="Provides Accommodation"
                  value={form.isAccommodation}
                  options={[
                    { id: "yes", name: "Yes" },
                    { id: "no", name: "No" },
                  ]}
                  theme="search"
                  onChange={(e) => {
                    addAccomodation(e, "");
                    setform({ ...form, isAccommodation: e });
                  }}
                  required
                />
              </div>

              {form.isAccommodation=='no'?<>
                <div className="">
                <FormControl
                  type="number"
                  label="Kms Travelled"
                  value={form.total_km}
                  maxlength="10"
                  onChange={(e) => setform({ ...form, total_km: e })}
                  required
                />
              </div>
              </>:<></>}

              <div className="">
                <FormControl
                  type="radio"
                  label="Provides Activities"
                  value={form.isActivity}
                  options={[
                    { id: "yes", name: "Yes" },
                    { id: "no", name: "No" },
                  ]}
                  theme="search"
                  onChange={(e) => {
                    setform({ ...form, isActivity: e });
                    if (e == "yes") {
                      setActivities([{ category_id: "", cost: "" }]);
                    } else {
                      setActivities([]);
                    }
                  }}
                  required
                />
              </div>

              <div className="">
                <FormControl
                  type="select"
                  name="status"
                  label="Status"
                  displayValue="name"
                  placeholder="Select Status"
                  value={form.status}
                  onChange={(e) => setform({ ...form, status: e })}
                  options={statusModel.list}
                  required
                  error={getError("status")}
                />
              </div>

              {/* <div className="col-span-12 md:col-span-6">
                                <label className='lablefontcls'>Image</label><br></br>
                                <ImageUpload model="users" result={e => imageResult(e, 'image')} value={images.image || form.image} />
                            </div> */}
            </div>

            {form.isAccommodation == "yes" ? (
              <>
                <div className="p-3 mt-4 shadow-md">
                  <h3 className="text-lg font-medium">Accomodation</h3>
                  {accommodations.map((itm, i) => {
                    return (
                      <div className="grid grid-cols-2 gap-4">
                        <div className="col-span-full">
                          <h4 className="text-sm font-bold">Day {itm.day}</h4>
                        </div>
                        <div className="">
                          <FormControl
                            type="select"
                            label="Accommodation Type"
                            placeholder="Not Applicable"
                            options={[
                              {id:null,name:'Not Applicable'},
                              ...accomodationType
                            ]}
                            theme="search"
                            value={itm.accomandation_id}
                            onChange={(e) =>
                              updateAccomodation(i, "accomandation_id", e)
                            }
                          />
                        </div>

                        <div className="">
                          <FormControl
                            type="number"
                            label="Kms Travelled"
                            maxlength="12"
                            value={itm.kms}
                            onChange={(e) => updateAccomodation(i, "kms", e)}
                          />
                        </div>
                        <div className="">
                          <FormControl
                            type="checkbox"
                            label="Meal"
                            options={[
                              { id: "breakfast", name: "Breakfast" },
                              { id: "lunch", name: "Lunch" },
                              { id: "dinnner", name: "Dinnner" },
                            ]}
                            value={itm.meals}
                            onChange={(e) => updateAccomodation(i, "meals", e)}
                          />
                        </div>
                        <div className="">
                          <FormControl
                            type="number"
                            label="Cost per pax"
                            maxlength="12"
                            value={itm.cost}
                            required={itm.meals?.length?true:false}
                            onChange={(e) => updateAccomodation(i, "cost", e)}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </>
            ) : (
              <></>
            )}

            {form.isActivity == "yes" ? (
              <>
                <div className="p-3 mt-4 shadow-md ">
                  <div className="flex items-center justify-between mb-4 bg-[#71B55C] p-2 rounded-lg">
                    <h3 className="text-lg font-medium text-white">Activities</h3>
                    <div className="text-right ">
                      <button
                        type="button"
                        onClick={() => addActivity()}
                        className="text-[#71B55C] bg-gradient-to-br bg-white  focus:ring-4 focus:outline-none  font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                      >
                        Add Activity
                      </button>
                    </div>
                  </div>
                  {activities.map((itm, i) => {
                    return (
                      <div className="grid grid-cols-2 gap-4 mb-4">
                        <div className="">
                          <FormControl
                            type="select"
                            label="Activity"
                            placeholder="Activity"
                            options={[
                              { id: "", name: "New Activity" },
                              ...activityType,
                            ]}
                            theme="search"
                            value={itm.category_id}
                            onChange={(e) =>
                              updateActivity(i, "category_id", e)
                            }
                          />
                        </div>
                        {itm.category_id ? (
                          <></>
                        ) : (
                          <>
                            <div className="">
                              <FormControl
                                type="text"
                                label="Activity Name"
                                value={itm.activity_name}
                                onChange={(e) =>
                                  updateActivity(i, "activity_name", e)
                                }
                                required
                              />
                            </div>
                          </>
                        )}
                        <div className="">
                          <FormControl
                            type="number"
                            label="Cost per pax"
                            maxlength="12"
                            value={itm.cost}
                            onChange={(e) => updateActivity(i, "cost", e)}
                            required
                          />
                        </div>
                        <div className="text-right col-span-full">
                          {activities.length > 1 ? (
                            <>
                              <button
                                type="button"
                                onClick={() => removeActivity(i)}
                                className="text-white bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                              >
                                <span class="material-symbols-outlined">
                                  delete
                                </span>
                              </button>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </>
            ) : (
              <></>
            )}

            <div className="mt-4 text-right">
              <button
                type="submit"
                className="text-white bg-gradient-to-br bg-[#71B55C]  hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
              >
                Save
              </button>
            </div>
          </div>
        </form>
      </Layout>
    </>
  );
};

export default AddEdit;
