import React, { useState, useEffect } from "react";
import Layout from "../../components/global/layout";
// import "./style.scss";

const noPages = () => {
 

  return (
    <Layout>
      <div className="img_404page">
        <div className="">
           
                    <img src="/assets/img/no-data.png" className="erroe" />
        </div>
      </div>
    </Layout>
  );
};

export default noPages;
