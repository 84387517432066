import axios from "axios";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Layout from "../../components/global/layout";
import environment from "../../environment";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import Select from "react-select";
import DateRangePicker from "../../components/common/DateRangePicker";
import datepipeModel from "../../models/datepipemodel";
import SelectDropdown from "../../components/common/SelectDropdown";
import MultiSelectDropdown from "../../components/common/MultiSelectDropdown";
import orderStatusModel from "../../models/orderStatus.model";
import Pagination from "react-pagination-js";
import pipeModel from "../../models/pipeModel";
import { HiOutlineArrowDown, HiOutlineSearch } from "react-icons/hi";
import { FiFilter } from "react-icons/fi";
import { GoEye } from "react-icons/go";
import { colourStyles } from "../../utils/constants";
import { RxReset } from "react-icons/rx"
import methodModel from "../../methods/methods";

const SalesDataRetrive = () => {
  const user = useSelector(state => state.user)
  const searchState = useSelector((state) => state.search);
  const [orderslist, setOrderlist] = useState([])
  const [products, setProducts] = useState([])
  const [categories, setCategories] = useState([])
  const [resellers, setResellers] = useState([])
  const [booking, setbooking] = useState([])
  const [total, setTotal] = useState(0)

  const [filters, setFilter] = useState({ page: 1, count: 50, sortBy: 'dateCreated desc', posId: '', search: '', status: '', product: '', category: [], travelStartDate: '', travelEndDate: '', reseller: '' })
  const history = useNavigate()

  const getOrders = (p = {}) => {
    loader(true)
    let filter = { ...filters, ...p ,addedBy:user.supplier_id?user.supplier_id.id:user._id}
    if(user?.subRole&&user?.subRole?.id==environment.SubRolePartner){
      delete filter.addedBy
      filter['partner']=user?.id||user?._id
    }
    ApiClient.get('api/orders/retrievable', filter).then(res => {
      if (res.success) {
        let data = res.data.map(itm => {
          let totalQuantity = 0
          itm?.items?.map(oitm => {
            totalQuantity = totalQuantity + oitm.totalQuantity
          })
          return {
            ...itm,
            totalQuantity: totalQuantity
          }
        })
        setOrderlist(data)
        setTotal(res.total)
      }
      loader(false)
    })
  }

  // useEffect(() => {
  //     if (user && user.loggedIn) {
  //         setFilter({ ...filters, search: searchState.data })
  //         getOrders({ search: searchState.data, page: 1 })
  //     }
  // }, [searchState])

  useEffect(() => {
    let salesDataFilter = localStorage.getItem('salesDataFilter')
    if (salesDataFilter) {
      let f = JSON.parse(salesDataFilter)
      filter(f)
    } else {
      getOrders()
    }
    getPtoducts()
    getResellers()
    getCategories()
    getBookings()
  }, [])

  const getPtoducts = () => {
    ApiClient.get('api/filter/orders/product-list', { addedBy: user?._id || user?.id }).then(res => {
      if (res.success) {
        setProducts(res.data.map(itm => {
          return { value: itm.productCode, label: itm.productName }
        }))
      }
    })
  }

  const getCategories = () => {
    ApiClient.get('api/filter/orders/cateogry-list', { status: 'active' }).then(res => {
      if (res.success) {
        setCategories(res.data.map(itm => {
          return { id: itm.id, label: itm.name }
        }))
      }
    })
  }

  const getResellers = () => {
    ApiClient.get('api/filter/orders/reseller-list', { status: 'active' }).then(res => {
      if (res.success) {
        setResellers(res.data.map(itm => {
          return { value: itm.id, label: itm.name }
        }))
      }
    })
  }

  const vieworder = (id) => {
    history("/orders/" + id)
  }

  const filter = (p = {}) => {
    let f = { ...filters, ...p }
    localStorage.setItem('salesDataFilter', JSON.stringify(f))
    setFilter({ ...f })
    if (f?.category) {
      f.category = f.category.toString()
      getOrders(f)
    } else {
      getOrders(p)
    }
  }

  const resetDates = () => {
    let f = {
      startDate: '',
      endDate: '',
      travelEndDate: '',
      travelStartDate: ''
    }
    filter(f)
  }

  const clearKey = {
    search: '',
    status: '',
    // startDate: '',
    // endDate: '',
    product: '',
    category: [],
    reseller: '',
    // travelEndDate: '',
    // travelStartDate: ''
  }

  const isClear = () => {
    let value = false
    Object.keys(clearKey).map(itm => {
      if (filters[itm]) value = true
    })
    return value
  }

  const clear = () => {
    let p = clearKey
    Object.keys(clearKey).map(itm => {
      p[itm] = ''
    })
    let f = { ...filters, ...p, category: [], page: 1 }
    localStorage.setItem('salesDataFilter', JSON.stringify(f))
    setFilter({ ...f })
    getOrders({ ...p, page: 1 })
  }

  const exportfun = async () => {
    const token = await localStorage.getItem("token");
    const req = await axios({
      method: "get",
      url: `${environment.api}api/orders/export`,
      responseType: "blob",
      body: { token: token }
    });
    var blob = new Blob([req.data], {
      type: req.headers["content-type"],
    });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `Sales Data.xlsx`;
    link.click();
  }

  const productVal = () => {
    let ext = products && products.find(item => item.value == filters.product)
    return ext ? ext : ''
  }

  const resellerVal = () => {
    let ext = resellers && resellers.find(item => item.value == filters.reseller)
    return ext ? ext : ''
  }

  const categoryVal = () => {
    let ext = categories && categories.find(item => item.id == filters.category)
    return ext ? ext : ''
  }

  const onRangeChange = (e, type = 'order') => {
    let f = {
      startDate: datepipeModel.datetostring(e.startDate),
      endDate: datepipeModel.datetostring(e.endDate),
    }
    if (type == 'travel') {
      f = {
        travelStartDate: datepipeModel.datetostring(e.startDate),
        travelEndDate: datepipeModel.datetostring(e.endDate),
      }
    }
    if (datepipeModel.datetostring(e.startDate) != datepipeModel.datetostring(e.endDate)) {
      filter({ ...f })
    } else {
      setFilter({ ...filters, ...f })
    }
  }

  const pageChange = (e) => {
    setFilter({ ...filters, page: e })
    getOrders({ page: e })
  }

  const sortClass = (key) => {
    let cls = 'fa-sort'
    if (filters.key == key && filters.sorder == 'asc') cls = 'fa-sort-up'
    else if (filters.key == key && filters.sorder == 'desc') cls = 'fa-sort-down'
    return 'fa ' + cls
  }

  const sorting = (key) => {
    let sorder = 'asc'
    if (filters.key == key) {
      if (filters.sorder == 'asc') {
        sorder = 'desc'
      } else {
        sorder = 'asc'
      }
    }
    let sortBy = `${key} ${sorder}`;
    setFilter({ ...filters, sortBy, key, sorder })
    getOrders({ sortBy })
  }

  const handleCategory = (e) => {
    setFilter({ ...filters, category: e })
    localStorage.setItem('salesDataFilter', JSON.stringify({ category: e }))
    let data = {
      category: e.toString()
    }
    getOrders(data)
  }

  const getBookings = () => {
    ApiClient.get(`api/pos/listing?addedBy=${user?._id || user?.id}`).then(res => {
      if (res.success) {
        setbooking(res.data.map(item => {
          return ({ value: item?._id, label: item?.name })
        }))
      }
    })
  }

  const bookingVal = () => {
    let ext = booking && booking.find(item => item.value == filters.posId)
    return ext ? ext : ''
  }


  const [id, setid] = useState([])

  const checkProducts = (item, checked) => {
    let array = id
    if (checked) {
      array.push(item._id)
    }
    else {
      array = array.filter(itm => itm != item._id)
    }
    setid([...array])
  }
  const checkAllProducts = (checked) => {
    if (checked) {
      setid(orderslist.map(itm => itm.id))
    }
    else {
      setid([])
    }
  }

  const ChecksAll = () => {
    let value = true
    orderslist && orderslist.find(item => {
      if (id.includes(item.id)) {
      }
      else (
        value = false
      )
    })
    return value
  }

  const retriveProduct = () => {

    loader(true)
    ApiClient.post('api/orders/retrieve', { ids: id }).then(res => {
      if (res.success) {
        getOrders()
        setid([])
      } else {
        loader(false)
      }
    })
  }
  return (
    <Layout>
      <div>
        <div className="flex items-center justify-between mb-6">
          <div className="flex flex-col gap-1">
            <h4 className="text-2xl font-semibold text-[#111827]">
              Retrievable Sales Data
            </h4>
            <p className="text-sm font-normal text-[#75757A]">
              Here you can see all about your product statistics
            </p>
          </div>
          {/* <div className="flex gap-3">
            <button
              onClick={(e) => exportfun()}
              type="button"
              className="!px-2.5 text-[#3C3E49] text-sm font-normal py-2.5 flex items-center justify-center gap-2 bg-[#fff] rounded-lg shadow-btn hover:bg-[#6db56224] border border-[#D0D5DD] transition-all focus:ring-2 ring-[#F1F2F3] disabled:bg-[#F3F2F5] disabled:cursor-not-allowed">
              <PiFileCsv className="text-xl text-typo" />
              Export CSV
            </button>
            <button
              type="button"
              className="!px-3 text-sm font-normal text-white h-11 flex items-center justify-center gap-2 bg-[#71B55C] rounded-lg shadow-btn hover:opacity-80 transition-all focus:ring-2 ring-[#EDEBFC] disabled:bg-[#D0CAF6] disabled:cursor-not-allowed">
              <AiOutlineDownload className="text-xl text-white" />
              Export PDF
            </button>
          </div> */}
        </div>

        <div className="w-full bg-white rounded-lg shadow-box">
          <div className="flex items-start justify-between gap-3 p-6 sm:flex-wrap 2xl:flex-nowrap">
            <div className="flex items-center gap-2">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  filter();
                }}
                className="relative shadow-box bg-white min-w-[320px] rounded-lg h-10 flex items-center gap-2 overflow-hidden px-2">
                <HiOutlineSearch className="text-[#717275] text-xl shrink-0" />
                <input
                  className="w-full h-full outline-none ring-0 p-0 placeholder:text-[#98A2B3] text-typo text-sm placeholder:font-light"
                  value={filters.search}
                  onChange={(e) =>
                    setFilter({ ...filters, search: e.target.value })
                  }
                  placeholder="Search for Order or Customer"
                />
              </form>
              <button
                className="bg-[#71B55C] h-10 shadow-btn px-6 hover:opacity-80 text-sm text-white rounded-lg"
                onClick={(e) => filter()}>
                Search
              </button>
            </div>
            <div className="flex flex-wrap items-center gap-2.5 justify-end ml-auto">
              <div>
                <Select
                  styles={colourStyles}
                  options={
                    [
                      { label: 'All Product', value: '' },
                      ...products]
                  }
                  placeholder="All Product"
                  value={productVal()}
                  isClearable={true}
                  name="Product"
                  onChange={(e) => filter({ product: e ? e.value : "" })}
                />
              </div>
              <div className="flex !gap-1 items-center">
                <DateRangePicker
                  placeholder="Order Date"
                  value={{
                    startDate: filters.startDate,
                    endDate: filters.endDate,
                  }}
                  onChange={(e) => onRangeChange(e)}
                />
                {filters.startDate ? (
                  <button
                    onClick={(e) => filter({ startDate: "", endDate: "" })}
                    type="button"
                    className="!px-4 text-sm font-normal text-white h-10 flex items-center justify-center gap-2 bg-[#71B55C] rounded-lg shadow-btn hover:opacity-80 transition-all focus:ring-2 ring-[#EDEBFC] disabled:bg-[#D0CAF6] disabled:cursor-not-allowed">
                    <RxReset className="text-xl text-white" />
                  </button>
                ) : (
                  <></>
                )}
              </div>
              <div className="flex !gap-1 items-center">
                <DateRangePicker
                  className="border"
                  placeholder="Travel Date"
                  value={{
                    startDate: filters.travelStartDate,
                    endDate: filters.travelEndDate,
                  }}
                  onChange={(e) => onRangeChange(e, "travel")}
                />
                {filters.travelStartDate ? (
                  <button
                    onClick={(e) => filter({ travelStartDate: "", travelEndDate: "" })}
                    type="button"
                    className="!px-4 text-sm font-normal text-white h-10 flex items-center justify-center gap-2 bg-[#71B55C] rounded-lg shadow-btn hover:opacity-80 transition-all focus:ring-2 ring-[#EDEBFC] disabled:bg-[#D0CAF6] disabled:cursor-not-allowed">
                    <RxReset className="text-xl text-white" />
                  </button>
                ) : (
                  <></>
                )}
              </div>

              {/* <button className='btn btn-primary' onClick={e=>resetDates()}>Reset Dates</button> */}
              <div>
                <Select
                  styles={colourStyles}
                  options={[
                    { label: 'All Resellers', value: '' },
                    ...resellers]}
                  placeholder="All Resellers"
                  value={resellerVal()}
                  isClearable={true}
                  name="Resellers"
                  onChange={(e) => filter({ reseller: e ? e.value : "" })}
                />
              </div>
              <div>
                <Select
                  styles={colourStyles}
                  options={[
                    ...booking]}
                  placeholder="All Bookings"
                  value={bookingVal()}
                  isClearable={true}
                  name="Bookings"
                  onChange={(e) => filter({ posId: e ? e.value : "" })}
                />
              </div>
              <div>
                {/* <Select
                  styles={colourStyles}
                  options={categories}
                  placeholder="All Categories"
                  value={categoryVal()}
                  isClearable={true}
                  name="Categories"
                  onChange={(e) => filter({ category: e ? e.value : "" })}
                /> */}
                <MultiSelectDropdown
                  options={categories}
                  intialValue={filters?.category}
                  placeholder="All Categories"
                  displayValue="label"
                  id="categories"
                  result={(e) =>
                    handleCategory(e.value)
                  }
                />

              </div>

              <div>
                <SelectDropdown
                  isSingle={true}
                  id="statusDropdown"
                  displayValue="name"
                  placeholder="All Order Status"
                  intialValue={filters.status}
                  result={(e) => filter({ status: e ? e.value : "" })}
                  options={[...orderStatusModel.list]}
                />
              </div>

              {/* <div>
                <SelectDropdown
                  isSingle={true}
                  id="statusDropdown"
                  displayValue="name"
                  placeholder="All Coupon Code"
                  intialValue={filters.coupon}
                  result={(e) => { }}
                  options={[]}
                />
              </div> */}

              <div>
                <SelectDropdown
                  isSingle={true}
                  id="statusDropdown"
                  displayValue="name"
                  placeholder="All Waiver Status"
                  intialValue={filters.waiverStatus}
                  result={(e) => { }}
                  options={[]}
                />
              </div>

              {isClear() ? (
                <button
                  onClick={(e) => clear()}
                  type="button"
                  className="!px-4 text-sm font-normal text-white h-10 flex items-center justify-center gap-2 bg-[#71B55C] rounded-lg shadow-btn hover:opacity-80 transition-all focus:ring-2 ring-[#EDEBFC] disabled:bg-[#D0CAF6] disabled:cursor-not-allowed">
                  <FiFilter className="text-xl text-white" />
                  Clear
                </button>
              ) : (
                <></>
              )}
            </div>
          </div>
          {id.length ? <>
            <div className='p-3'>
              <button className="!px-4 text-sm font-normal text-white h-10 flex items-center justify-center gap-2 bg-[#71B55C] rounded-lg shadow-btn hover:opacity-80 transition-all focus:ring-2 ring-[#EDEBFC] disabled:bg-[#D0CAF6] disabled:cursor-not-allowed" onClick={e => retriveProduct()}>Retrive Sales Data</button>
            </div>

          </> : <></>}
          <div className="w-full overflow-auto scrollbar">
            <table className="w-full">
              <thead className="border-y border-[#EAECF0]">
                <tr className=" border-y border-[#EAECF0]">
                  <th scope="col" className="cursor-pointer text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-2.5 text-center bg-[#6db56214] !py-3"><input className="h-4 w-4 cursor-pointer shrink-0 rounded-[4px] border border-[#3C3E49A3] !text-[#71B55C]" type='checkbox' checked={ChecksAll()} onClick={e => checkAllProducts(e.target.checked)} /></th>
                  <th
                    scope="col"
                    className="cursor-pointer text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-2.5 text-center bg-[#6db56214] !py-3"
                    onClick={(e) => sorting("orderNumber")}>
                    Order Id
                    <HiOutlineArrowDown className="inline text-sm shrink-0" />
                  </th>
                  <th
                    scope="col"
                    className="cursor-pointer text-[#82838B] border-l-0 font-normal text-sm !border border-[#EAECF0] px-2.5 text-center bg-[#6db56214] !py-3"
                    onClick={(e) => sorting("dateCreated")}>
                    Order <br /> Date & Time{" "}
                    <HiOutlineArrowDown className="inline text-sm shrink-0" />
                  </th>
                  <th
                    scope="col"
                    className="cursor-pointer text-[#82838B] font-normal text-sm !border border-[#EAECF0] px-2.5 text-left bg-[#6db56214] !py-3"
                    onClick={(e) => sorting("customerName")}>
                    <span className="flex items-center gap-0.5">
                      Customer Name{" "}
                      <HiOutlineArrowDown className="inline text-sm shrink-0" />
                    </span>
                  </th>
                  <th
                    scope="col"
                    className="cursor-pointer text-[#82838B] font-normal text-sm !border border-[#EAECF0] h-10 px-3.5 text-left bg-[#6db56214] !py-3"
                    onClick={(e) => sorting("customerEmail")}>
                    Customer Email/Phone
                    <HiOutlineArrowDown className="inline text-sm shrink-0" />
                  </th>
                  <th
                    scope="col"
                    className="cursor-pointer text-[#82838B] border-l-0 font-normal text-sm !border border-[#EAECF0] px-2.5 text-left bg-[#6db56214] !py-3">
                    <span className=" whitespace-nowrap">Product Name</span>
                  </th>
                  <th
                    scope="col"
                    className="cursor-pointer text-[#82838B] font-normal text-sm !border border-[#EAECF0] px-2.5 text-center bg-[#6db56214] !py-3">
                    Pax
                  </th>
                  <th
                    scope="col"
                    className="cursor-pointer text-[#82838B] font-normal text-sm !border border-[#EAECF0] px-2.5 text-center bg-[#6db56214] !py-3"
                    onClick={(e) => sorting("orderAmount")}>
                    <span className="flex items-center justify-end gap-0.5 whitespace-nowrap">
                      Order <br /> Amount
                      <HiOutlineArrowDown className="inline text-sm shrink-0" />
                    </span>
                  </th>
                  <th
                    scope="col"
                    className="cursor-pointer text-[#82838B] font-normal text-sm !border border-[#EAECF0] px-2.5 text-center bg-[#6db56214] !py-3"
                    onClick={(e) => sorting("Discount")}>
                    <span className="flex items-center justify-end gap-0.5">
                      Discounts{" "}
                      <HiOutlineArrowDown className="inline text-sm shrink-0" />
                    </span>
                  </th>
                  <th
                    scope="col"
                    className="cursor-pointer text-[#82838B] font-normal text-sm !border border-[#EAECF0] px-2.5 text-center bg-[#6db56214] !py-3">
                    Booking <br /> Date & Time
                  </th>
                  <th
                    scope="col"
                    className="cursor-pointer text-[#82838B] font-normal text-sm !border border-[#EAECF0] px-2.5 text-center bg-[#6db56214] !py-3"
                    onClick={(e) => sorting("sourceChannel")}>
                    <span className="flex items-center justify-start gap-0.5">
                      Reseller
                      <HiOutlineArrowDown className="inline text-sm shrink-0" />
                    </span>
                  </th>
                  <th
                    scope="col"
                    className="cursor-pointer text-[#82838B] font-normal text-sm !border border-[#EAECF0] px-2.5 text-center bg-[#6db56214] !py-3"
                    onClick={(e) => sorting("sourceChannel")}>
                    Reseller <br /> Commissions
                    <HiOutlineArrowDown className="inline text-sm shrink-0" />
                  </th>
                  <th
                    scope="col"
                    className="cursor-pointer text-[#82838B] font-normal text-sm !border border-[#EAECF0] px-2.5 text-center bg-[#6db56214] !py-3">
                    Order<br /> Status
                  </th>
                  <th
                    scope="col"
                    className="cursor-pointer text-[#82838B] font-normal text-sm !border border-[#EAECF0] px-2.5 text-center bg-[#6db56214] !py-3">
                    Waiver Status
                  </th>
                  <th
                    scope="col"
                    className="cursor-pointer text-[#82838B] border-l-0 font-normal text-sm !border border-[#EAECF0] px-2.5 text-center bg-[#6db56214] !py-3">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {orderslist &&
                  orderslist.map((itm, i) => {
                    return (
                      <tr key={i} >
                        <td className="hover:underline hover:opacity-80 !text-[#71B55C] !border-l-0 cursor-pointer !px-2 text-sm font-normal !py-4 !border text-center border-[#EAECF0]"><input type='checkbox' checked={id.includes(itm._id)} className="h-4 w-4 cursor-pointer shrink-0 rounded-[4px] border border-[#3C3E49A3] !text-[#71B55C]" onClick={e => checkProducts(itm, e.target.checked)} /></td>
                        <td
                          className="hover:underline hover:opacity-80 !text-[#71B55C] !border-l-0 cursor-pointer !px-2 text-sm font-normal !py-4 !border text-center border-[#EAECF0]"
                          onClick={(e) => vieworder(itm.order_id)}>
                          {itm.orderNumber}
                        </td>
                        <td className=" text-typo !px-1.5 text-sm font-normal !py-4 !border text-center border-[#EAECF0]">
                          <span className="whitespace-nowrap">
                            {datepipeModel.date(
                              itm.dateCreated,
                              user?.companyDateFormat,
                              false,
                              itm?.productDetail?.timezone
                            )}
                          </span>

                          <p className="text-[#6B7280] text-sm">
                            {datepipeModel.time(
                              itm.dateCreated,
                              itm?.productDetail?.timezone
                            )}
                          </p>
                        </td>
                        <td className=" text-typo !px-2.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]">
                          <span className="line-clamp-2">
                            {" "}
                            {itm?.customerName}
                          </span>
                        </td>
                        <td className=" text-typo !pl-3 !pr-2 text-sm font-normal !py-4 border text-left border-[#EAECF0]">
                          <div className="flex items-center gap-1">
                            <div className="bg-[#6db5623d] w-[44px] text-center py-[3px] !rounded text-xs text-[#3C3E49]">
                              Email
                            </div>
                            {itm?.customer.email}
                          </div>
                          <div className="flex items-center gap-1 !mt-2">
                            <div className="bg-[#ece8ec] w-[44px] text-center py-[3px] !rounded text-xs text-[#3C3E49]">
                              Phone
                            </div>
                            {itm?.customer.phone && (
                              <p className="text-[#6B7280] text-sm">
                                +{itm?.customer.phone}
                              </p>
                            )}
                          </div>
                        </td>
                        <td className=" text-typo !px-2.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]">
                          <span className="line-clamp-2">
                            {itm?.items[0]?.productName}
                          </span>
                        </td>
                        <td className="text-typo !px-0 text-sm font-normal !py-4 !border text-center border-[#EAECF0]">
                          {itm.totalQuantity}
                        </td>
                        <td className="text-typo !px-2 text-sm font-normal !py-4 border text-right border-[#EAECF0]">
                          {pipeModel.currency(
                            Number(itm.totalPaid) - Number(itm.commission),
                            "",
                            user.companyCurrencyFormat
                          )}
                        </td>
                        <td className=" text-typo !px-2 text-sm font-normal !py-4 !border text-center border-[#EAECF0]">
                          --
                        </td>
                        <td className=" text-typo !px-2 text-sm font-normal !py-4 !border text-center border-[#EAECF0]">
                          <span className="whitespace-nowrap">
                            {datepipeModel.date(
                              itm?.items?.[0]?.startTime,
                              user.companyDateFormat,
                              false,
                              itm?.productDetail?.timezone
                            )}
                          </span>
                          <p className="text-[#6B7280] text-sm">
                            {datepipeModel.time(
                              itm?.items?.[0]?.startTime,
                              itm?.productDetail?.timezone
                            )}
                          </p>
                        </td>
                        <td className=" text-typo !px-2 text-sm font-normal !py-4 !border text-center border-[#EAECF0]">
                          <div className="shrink-0 flex !gap-2 flex-1 justify-start min-w-[120px]">
                            <img
                              width="32"
                              height="32"
                              alt="Item"
                              src={methodModel.userImg(itm?.resellerName ? itm?.reseller_detail?.logo : user.logo)}
                              className="object-cover w-8 h-8 rounded-full shrink-0"
                            />{" "}
                            <div className="text-left whitespace-nowrap">
                              {/* Trip Advisor */}
                              <p className="text-[#6B7280] text-sm">
                                {itm?.resellerName || "Direct"}
                              </p>
                            </div>
                          </div>
                        </td>
                        <td className="text-typo !px-2.5 text-sm font-normal !py-4 !border text-right border-[#EAECF0]">
                          {pipeModel.currency(
                            Number(itm.commission),
                            "",
                            user.companyCurrencyFormat
                          )}
                        </td>
                        <td className=" text-typo !px-2 text-sm font-normal !py-4 !border text-center border-[#EAECF0]">
                          {itm.status === "CANCELLED" && (
                            <div className="bg-[#FDEBED] text-sm !px-3 h-[30px] flex items-center justify-center border border-[#ECA59E] text-[#D23D28] !rounded">
                              {orderStatusModel.name(itm.status)}
                            </div>
                          )}
                          {itm.status === "PENDING_SUPPLIER" && (
                            <div className="bg-[#FEF2DB] text-sm !px-3 h-[30px] flex items-center justify-center border border-[#EAC785] text-[#CB8704] !rounded">
                              {orderStatusModel.name(itm.status)}
                            </div>
                          )}
                          {itm.status === "CONFIRMED" && (
                            <div className="bg-[#ECF4EF] text-sm !px-3 h-[30px] flex items-center justify-center border border-[#94D5AE] text-[#11A74D] !rounded">
                              {orderStatusModel.name(itm.status)}
                            </div>
                          )}
                        </td>
                        <td className=" text-typo !px-0 text-sm font-normal !py-4 !border text-center border-[#EAECF0]">
                          --
                        </td>
                        <td className=" text-typo !px-2.5 py-2.5 text-sm font-normal !border-r-0 !border text-center border-[#EAECF0]">
                          <button
                            onClick={(e) => vieworder(itm.order_id)}
                            className="bg-[#ffe9ea] h-[30px] text-sm cursor-pointer hover:bg-[#71B55C] hover:!border-[#71B55C] transition-all duration-300 hover:text-white flex items-center !px-2 !gap-1 border border-[#EBEBEB] !rounded-md text-[#000]">
                            <GoEye /> View
                          </button>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>

          {!total ? <div className="py-3 text-center">No Data</div> : <></>}
          {total > filters?.count && (
            <div className="p-6 paginationWrapper">
              {/* <span>
                Show {filters.count} from {total} Cards
              </span> */}
              <Pagination
                currentPage={filters.page}
                totalSize={total}
                sizePerPage={filters.count}
                changeCurrentPage={pageChange}
              />
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default SalesDataRetrive;
