import { useDispatch, useSelector } from "react-redux";
import { useLocation, NavLink, useNavigate } from "react-router-dom";
import { RiHome6Line } from "react-icons/ri";
import { Disclosure, Transition } from "@headlessui/react";
import { TiArrowSortedDown } from "react-icons/ti";
import { TbChartBar } from "react-icons/tb";
import { PiHandCoins, PiHandbagBold } from "react-icons/pi";
import { FiPackage, FiSettings, FiThumbsUp, FiUsers } from "react-icons/fi";
import { HiOutlineSpeakerphone } from "react-icons/hi";
import { HiOutlineInbox } from "react-icons/hi2";
import { RiCoupon2Fill } from "react-icons/ri";
import { RiCrosshair2Line } from "react-icons/ri";
import { TbBuildingCommunity } from "react-icons/tb";
import { GoGoal } from "react-icons/go";
import { GoDatabase } from "react-icons/go";
import { GoGraph } from "react-icons/go";

import styles from "./index.module.css";
import {
  IoFileTrayFullOutline,
  IoChatboxEllipsesOutline,
} from "react-icons/io5";
import environment from "../../../environment";
import { useEffect, useState } from "react";
import ApiClient from "../../../methods/api/apiClient";
import { login_success } from "../../../actions/user";
import ChatbotIcon from "../../../assets/chatbot-icon";

const Html = ({ ListItemLink, urlAllow, route, isOpen, scrollToId }) => {
  const location = useLocation();
  const [data, setData] = useState({});
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();
  const dataConnectionLink = [
    "/pos",
    "/api/review-connected",
    "/api/review",
    "/marketing-analytics",
    "/api/accounting",
    "/marketing-software",
    "/omni-channel-setting/widget",
    "/omni-channel-setting/integration",
  ];
  const dataConnection = dataConnectionLink.includes(location.pathname);
  useEffect(() => {
    ApiClient.get(`api/user/profile`, { id: user?.id || user?._id }, "", "", {
      apiCall: true,
    }).then((res) => {
      if (res.success) {
        setData(res.data);
        const data = res?.data;
        if (data?.subRole && data?.subRole?._id) {
          data["subRole"] = { ...data?.subRole, id: data?.subRole?._id };
        }
        dispatch(login_success(data));
      }
    });
  }, []);

  const menus = [
    {name:'MAIN MENU'},
    {
      path: '/dashboard',
      name: 'Dashboard',
      icon: <RiHome6Line className="text-lg shrink-0" />,
      check: 'readDashboard'
    },
    {
      name: 'Environment',
      icon: <RiCrosshair2Line className="text-lg shrink-0" />,
      check: 'readDashboard',
      tab: 'sales',
      menus: [
        {
          path: '/ghg-emmissions',
          name: 'GHG Emmissions',
          check: 'readDashboard',
        },
        {
          path: '/energy-emmissions',
          name: 'Energy',
          check: 'readDashboard',
        },
        {
          path: '/waste-emmissions',
          name: 'Waste',
          check: 'readDashboard',
        },
        {
          path: '/water-emmissions',
          name: 'Water',
          check: 'readDashboard',
        },
        {
          path: '/biodiversity-emmissions',
          name: 'Biodiversity',
          check: 'readDashboard',
        },
        {
          path: '/procurement-emmissions',
          name: 'Procurement',
          check: 'readDashboard',
        },
      ]
    },
    {
      name: 'Community',
      icon:<TbBuildingCommunity className="text-lg shrink-0" />,
      check: 'readDashboard',
      tab: 'sales',
      menus: [
        {
          path: '/employee-travel',
          name: 'Employee Travel',
          check: 'readDashboard',
        },
        {
          path: '/local-engagement',
          name: 'Local Engagement & Cultural Heritage',
          check: 'readDashboard',
        },
        {
          path: '/visitors',
          name: 'Visitors',
          check: 'readDashboard',
        }
      ]
    },
    {
      name: 'Goal Manager',
      icon:<GoGoal className="text-lg shrink-0"  />,
      check: 'readDashboard',
      menus: [
        {
          path: '/goal',
          name: 'Goals',
          check: 'readDashboard',
        },
        {
          path: '/task',
          name: 'Tasks',
          check: 'readDashboard',
        },
      ]
    },
    {
      name: 'Data Collection',
      icon: <GoDatabase className="text-lg shrink-0" />,
      check: '',
      tab: 'sales-data,product-data,customer-data,reviews,employee',
      menus: [
        {
          path: '/sales-data',
          name: 'Sales',
          check: '',
        },
        {
          path: '/product-data',
          name: 'Products',
          check: '',
        },
        {
          path: '/customer-data',
          name: 'Customers',
          check: '',
        },
        {
          path: '/reviews',
          name: 'Reviews',
          check: '',
        },
        {
          path: '/accounting/transactions',
          name: 'Financial Data',
          check: '',
        },
        {
          path: '/accounting/invoices',
          name: 'Invoices',
          check: '',
        },
        
      ]
    },
    {
      path: '/kpi',
      name: 'KPIs',
      icon: <GoGraph className="text-lg shrink-0" /> ,
      check: 'readDashboard'
    },
    {name:'OTHERS'},
    {
      name: 'Settings',
      icon: <FiSettings className="text-lg shrink-0" />,
      check: '',
      tab: 'sales,plans,api/review-connected,api/accounting',
      menus: [
        
        {
          path: '/company',
          name: 'Company',
          check: '',
        },
        {
          path: '/activeplan',
          name: 'Plans',
          check: '',
        },
        {
          path: '/pos',
          name: 'Data connection',
          check: '',
        },
        {
          path: '/tour-details',
          name: 'Tour Details',
          check: '',
        },
        {
          path: '/employee',
          name: 'Employees',
          check: '',
        },
        {
          path: '/activity',
          name: 'Activity Types',
          check: '',
        },
        
        {
          path: '/card',
          name: 'Billings',
          check: '',
        },
        
      ]
    },
  ]

  const tabclass = (tab) => {
    let url = window.location.href;
    let value = false;
    let menu=tab.split(',')
    menu.map((itm) => {
      if (url.includes(itm)) value = true;
    });
    return value;
  };


  const MenuItem=({itm})=>{
    if(!itm.path&&!itm.menus){
      return <>
      <li>
      <h6
              className={`${isOpen ? "py-[12px] text-center" : "p-[12px]"
                } text-xs font-medium text-[#868C98] mt-[12px] tracking-[0.48px]`}>
              <span className="sidebar_text"> {itm.name} </span>
            </h6>
      </li>
      </>
    }
    else if (itm.menus) {
      return <>

        {urlAllow(itm.check) && (
          <li>
            <Disclosure as="div" defaultOpen={tabclass(itm.menus.map(itm=>itm.path).toString()+`,${itm.tab||''}`)}>
              {({ open }) => (
                <>
                  <Disclosure.Button className={`resp-btn w-full py-[12px] px-[12px] rounded-[8px] flex items-center justify-between  hover:bg-[#71B55C]/10 transition-all duration-300 ${open?'!text-[#fff] !bg-[#71B55C] !font-[600]':'text-[#525866]'}`}>
                    <span className="text-sm font-normal text-inherit flex items-center gap-[12px] crm">
                      {itm.icon}
                      <span className="sidebar_text text-[14px] font-medium -tracking-[0.084px]">{itm.name}</span>
                    </span>
                    <TiArrowSortedDown
                      className={`${open ? "" : "-rotate-90 transform"
                        } h-4 w-4 ms-1 transition-all duration-500`}
                    />
                  </Disclosure.Button>
                  <Transition
                    enter="transition duration-300 ease-in-out"
                    enterFrom="transform scale-95 opacity-0"
                    enterTo="transform scale-300 opacity-300"
                    leave="transition duration-300 ease-in-out"
                    leaveFrom="transform scale-300 opacity-300"
                    leaveTo="transform scale-95 opacity-0">
                    <Disclosure.Panel
                      className={`${!isOpen && ""} mt-[4px] `}>
                      <ul className="">
                        {itm.menus.map(mitm=>{
                          return <>
                          {urlAllow(mitm.check)?<>
                          <li className="relative ml-[20px] mt-0">
                          <NavLink
                            to={mitm.path}
                            className={(isActive) =>
                              "py-[12px] px-[16px]  before:content-[''] before:block before:w-[2px]  before:h-[100%] before:bg-[#EFEFEF] before:absolute  before:left-0 before:top-0 rounded-[8px] block text-sm font-normal text-[#6F767E] cursor-pointer hover:!text-[#71B55C] hover:bg-transparent !no-underline transition-all " +
                              (location?.pathname == mitm.path &&
                                " !text-[#71B55C] !bg-transparent !font-medium")
                            }>
                            {isOpen && (
                              <TbChartBar className="text-lg text-inherit shrink-0" />
                            )}
                            <span className="leading-none text-inherit sidebar_text">
                              {mitm.name}
                            </span>
                          </NavLink>
                        </li>
                          </>:<></>}
                        
                          </>
                        })}
                      </ul>
                    </Disclosure.Panel>
                  </Transition>
                </>
              )}
            </Disclosure>
          </li>
        )}
      </>
    } else {
      return <>
        {urlAllow(itm.check) && (
          <li>
            <NavLink
              to={itm.path}
              className={(isActive) =>
                "py-[12px] px-[12px] rounded-[8px] flex items-center gap-[12px] text-[14px] font-medium text-[#525866] hover:!text-[#fff] hover:bg-[#71B55C] !no-underline transition-all leading-[20px] w-full" +
                (location?.pathname == itm.path &&
                  " !text-[#fff] !bg-[#71B55C] !font-[600] ")
              }>
              {itm.icon}
              <span className="leading-none text-inherit sidebar_text">
                {itm.name}
              </span>
            </NavLink>
          </li>
        )}
      </>
    }
  }

  const otherMenu = [
    {
      name: 'Settings',
      icon: <FiSettings className="text-lg shrink-0" />,
      check: '',
      tab: 'api/review-connected,api/accounting',
      menus: [
        
        {
          path: '/company',
          name: 'Company Details',
          check: '',
        },
        {
          path: '/plans',
          name: 'Plans',
          check: user?.companyName?'':'hide',
        },
        // {
        //   path: '/pos',
        //   name: 'Data connection',
        //   check: '',
        // },
        // {
        //   path: '/tour-details',
        //   name: 'Tour Details',
        //   check: '',
        // },
        // {
        //   path: '/employee',
        //   name: 'Employees',
        //   check: '',
        // },
       
        // {
        //   path: '/card',
        //   name: 'Billings',
        //   check:user?.companyName?'':'hide',
        // },
      ]
    },
  ]

  return (
    <>
      <div className={`px-[8px] ${isOpen && styles.sm_sidebar}`}>
        {user?.subscriptionId ||
          user?.on_trial ||
          user?.supplier_id ||
          user?.subRole?.id == environment.SubRolePartner ||
          user?.subRole?._id == environment.SubRolePartner ? (
          <>
            <ul className="space-y-2">
              {menus.map(itm => {
               return <MenuItem itm={itm} />
              })}
            </ul>
          </>
        ) : (
          <>
            <>
              <ul className="space-y-2">
              {otherMenu.map(itm => {
               return <MenuItem itm={itm} />
              })}
              </ul>
            </>
          </>
        )}
      </div>
    </>
  );
};

export default Html;
