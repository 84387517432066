import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DateRangePicker from "../../components/common/DateRangePicker";
import Layout from "../../components/global/layout";
import ApiClient from "../../methods/api/apiClient";
import { FaSpinner } from "react-icons/fa";
import { FiX } from "react-icons/fi";
import {
    HiOutlinePresentationChartBar
} from "react-icons/hi";
import {
    MdOutlineAssessment
} from "react-icons/md";
import { BiLike } from "react-icons/bi";
import { AiOutlineDownload } from "react-icons/ai";
import { RxReset } from "react-icons/rx";
import { getAssistantResponse } from "../../plugins/aiAssistant";
import { enabledProductPopup } from "../../shared/shared.method";
import sharedModel from "../../models/shared.model";
import environment from "../../environment";
import { useSelector } from "react-redux";
import Badge from "../../components/Badge";
import AllChart from "../../components/common/AllChart";
import loader from "../../methods/loader";
import DoughnutChart from "../../components/common/DoughnutChart";
import PieChart from "../../components/common/PieChart";
import datepipeModel from "../../models/datepipemodel";

export default function CouponDashboard() {
    const user = useSelector((state) => state.user);

    const [filters, setFilter] = useState({
        startDate: "",
        endDate: "",
        forward: false,
        yearType: "calendar",
        dateRange: "",
        partner: user?.subRole && user?.subRole?.id == environment.SubRolePartner ? user.id || user._id : ""
    });
    const [filtersReseller, setFilterReseller] = useState({});
    const [filtersProduct, setFilterProduct] = useState({});
    const [overtimeData, setOvertimeData] = useState([]);
    const [filtersCateory, setFilterCategory] = useState({});
    const [productSummary, setProductSummary] = useState([]);
    const [summeryLoader, setSummeryLoader] = useState(false);
    const [topReseller, setTopReseller] = useState([]);
    const [topProducts, setTopProducts] = useState([]);
    const [topCategories, setTopCategories] = useState([]);
    const history = useNavigate();
    const [PartnerOptions, setPartnerOptions] = useState([]);
    const [CouponsCountData,setCouponsCountData]=useState({});
    const [paymentOptionsSales, setpaymentOptionsSales] = useState([]);
    const [salesByCountry, setsalesByCountry] = useState([]);
    const [salesByCurrency, setsalesByCurrency] = useState([]);

    const getPercentage = (hval, val) => {
        let value = ((Number(val) - Number(hval)) / Number(hval)) * 100;
        value = 100 + value;
        if (!value) value = 0;
        return value == 100 ? value : value.toFixed(2);
    };

    
    const getTopProducts = (p = {}) => {
        let f = {
            ...filters,
            filtersProduct,
            ...p,
            topProducts: true,
        };
        ApiClient.get("api/product/sales", { ...f }).then((res) => {
            if (res.success) {
                let data = res.data;
                // data=data.filter(itm=>itm.sum)
                setTopProducts(data);
            }
        });
    };


    const getPartners = () => {
        const response = (res) => {
            if (res.success) {
                setPartnerOptions(res.data.map(item => {
                    return { value: item._id, label: item.fullName }
                }))
            }
        }

        ApiClient.getAll({ url: 'api/partners/list', response: response })
    }

    useEffect(() => {
        getPartners()

    }, [])

    const getTopReseller = (p = {}) => {
        let f = {
            ...filters,
            ...filtersReseller,
            ...p,
        };
        ApiClient.get("api/top/resellers", { ...f }).then((res) => {
            if (res.success) {
                let data = res.topResellers;
                // data=data.filter(itm=>itm.sum)
                setTopReseller(data);
            }
        });
    };

//  From this we will Get the Coupons Data Counts which we need to show
    const GetCouponsCountData=(p={})=>{
const F={...filters,...p};
        ApiClient.get(`api/vouchers/data`,F).then(res=>{
            if(res.success){
                setCouponsCountData(res||{})
            }
        })
    }
    const getTopCategories = (p = {}) => {
        let f = {
            ...filters,
            ...filtersCateory,
            ...p,
        };
        ApiClient.get("api/top/categories", { ...f }).then((res) => {
            if (res.success) {
                let data = res.topCategories;
                // data=data.filter(itm=>itm.sum)
                setTopCategories(data);
            }
        });
    };

    const getproductSummery = (p = {}) => {
        let f = {
            ...filters,
            ...p,
        };
        setSummeryLoader(true)
        ApiClient.get("api/vouchers/sales/over/time", { ...f }).then((res) => {
            if (res.success) {
      setOvertimeData(res.data.map((item)=>({date:`${item?.day}/${item?.month}/${item?.year}`,totalSales:item?.totalSale})))

           

                // data=data.filter(itm=>itm.sum)
                setProductSummary(data);
            }
            setSummeryLoader(false)
        });
    };

    const getData = (p = {}) => {
        getproductSummery(p);
        // getTopProducts(p);
        GetCouponsCountData(p)
        GetTopCoupons(p)
        // getTopReseller(p);
        // getTopCategories(p);
        BreakDownSalesData(p)
    };



    const BreakDownSalesData=(p={})=>{
        loader(true);
        let Newf={...filters,...p}
        ApiClient.get(`api/voucher/breakdown/report`,Newf).then(res=>{
            console.log(res,"THis is the response of data thats we want ---")
            if(res.success){
                setpaymentOptionsSales(res?.paymentOptionsSales.map((item) => ({
                    ...item,
                    value: item.sum,
                    name: item.paymentOption,
                })));
                setsalesByCountry(res?.salesByCountry.map((item) => ({
                    ...item,
                    value: item.sum,
                    name: item.countryName,
                })));
                setsalesByCurrency(res?.salesByCurrency.map((item) => ({
                    ...item,
                    value: item.sum,
                    name: item.totalCurrency,
                })) );
            }
            loader(false);
        })
    }

    const print = () => {
        let divId = "useSelectorCoupons";
        //   let mywindow = window.open('', 'PRINT', 'height=650,width=900,top=100,left=150');
        // myw/indow.document.write(document.getElementById(divId).innerHTML);
        // mywindow.document.close(); // necessary for IE >= 10
        // mywindow.focus(); // necessary for IE >= 10*

        // mywindow.print();
        // mywindow.close();
        // return true;

        let printContents = document.getElementById(divId).innerHTML;
        let originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        window.print();
        document.body.innerHTML = originalContents;
        location.reload();
    };

    const pageName = window.location.href;
    const dataFilter = (p = {}) => {
        let f = { ...filters, ...p }
        localStorage.setItem(pageName, JSON.stringify(f))
        let dateRange = {
            startDate: f.startDate,
            endDate: f.endDate,
            compare: f.compare,
            compareStart: f.compareStart,
            compareEnd: f.compareEnd
        }
        localStorage.setItem('dateRange', JSON.stringify(dateRange))
        setFilter({ ...f })
        getData(p)
        if (f.compare) {
            // getData({ ...f, startDate: f.compareStart, endDate: f.compareEnd }, true)
        }
    }

    const onRangeChange = (e) => {
        let f = {
            startDate: datepipeModel.datetostring(e.startDate),
            endDate: datepipeModel.datetostring(e.endDate),
            compare: e.compare,
            compareStart: e.compareStart,
            compareEnd: e.compareEnd,
        }
        if (datepipeModel.datetostring(e.startDate) == datepipeModel.datetostring(e.endDate)) {
            setFilter({ ...filters, ...f })
        } else {
            dataFilter({ ...f })
        }
    }


    const yearTypeChange = (p, sfilter) => {
        let f = {
            startDate: '',
            endDate: '',
            ...sfilter,
            compare: ''
        }
        if (p == 'financial') {
            let financialYear = user.financialYear || '1'
            financialYear = Number(financialYear) < 10 ? '0' + financialYear : financialYear
            let year = new Date().getFullYear()
            let endDate = new Date(`${year}-${financialYear}-01`)
            endDate = endDate.setDate(endDate.getDate() - 1)
            f.startDate = `${year - 1}-${financialYear}-01`
            f.endDate = datepipeModel.datetostring(endDate)
        } else {
            let year = new Date().getFullYear()
            let month = new Date().getMonth() + 1
            let endDate = new Date(`${year}-${month}-1`)
            endDate.setMonth(month)
            endDate.setDate(0)
            let compareEnd = new Date(`${year}-${month}-01`).setDate(0)
            compareEnd = datepipeModel.datetostring(compareEnd)
            f.startDate = `${year}-${month}-01`
            f.endDate = datepipeModel.datetostring(endDate)
            f.compare = 'Previous Month'
            let compareStart = new Date(`${year}-${month}-01`)
            compareStart.setMonth(month - 2)
            // f.compareStart = datepipeModel.datetostring(compareStart)
            // f.compareEnd = `${compareEnd}`

            f.compare = ''
            f.compareStart = ''
            f.compareEnd = ''

        }

        dataFilter({ ...f, yearType: p })
    }


    const reset = () => {
        let f = {
            forward: false,
            yearType: "calendar",
        };
        yearTypeChange("calendar", f);
    };

    useEffect(() => {
        let sfilter = localStorage.getItem(pageName)
        if (sfilter) {
            sfilter = JSON.parse(sfilter)
            sfilter = { ...sfilter }

            let dateRange = localStorage.getItem('dateRange')
            if (dateRange) {
                dateRange = JSON.parse(dateRange)
                sfilter = { ...sfilter, ...dateRange }
            }

            if (sfilter.startDate && sfilter.endDate) {

                dataFilter(sfilter)
            } else {
                yearTypeChange('calendar')
            }

        } else {
            yearTypeChange('calendar')
        }
        enabledProductPopup(user)
    }, [])

    const getSum = (arr = []) => {
        let value = 0;
        arr.map((itm) => {
            value = value + itm.sum;
        });
        return value;
    };

    const sortClassReseller = (key) => {
        let cls = "fa-sort";
        if (filtersReseller.key == key && filtersReseller.sorder == "asc")
            cls = "fa-sort-up";
        else if (filtersReseller.key == key && filtersReseller.sorder == "desc")
            cls = "fa-sort-down";
        return "fa " + cls;
    };

    const sortClassProduct = (key) => {
        let cls = "fa-sort";
        if (filtersProduct.key == key && filtersProduct.sorder == "asc")
            cls = "fa-sort-up";
        else if (filtersProduct.key == key && filtersProduct.sorder == "desc")
            cls = "fa-sort-down";
        return "fa " + cls;
    };

    const sortClassCategory = (key) => {
        let cls = "fa-sort";
        if (filtersCateory.key == key && filtersCateory.sorder == "asc")
            cls = "fa-sort-up";
        else if (filtersCateory.key == key && filtersCateory.sorder == "desc")
            cls = "fa-sort-down";
        return "fa " + cls;
    };

    const sortingReseller = (key) => {
        let sorder = "asc";
        if (filtersReseller.key == key) {
            if (filtersReseller.sorder == "asc") {
                sorder = "desc";
            } else {
                sorder = "asc";
            }
        }

        let sortBy = `${key} ${sorder}`;
        let f = {
            sortBy,
            key,
            sorder,
        };
        setFilterReseller({ ...f });
        getTopReseller(f);
    };

    const sortingProduct = (key) => {
        let sorder = "asc";
        if (filtersProduct.key == key) {
            if (filtersProduct.sorder == "asc") {
                sorder = "desc";
            } else {
                sorder = "asc";
            }
        }

        let sortBy = `${key} ${sorder}`;
        let f = {
            sortBy,
            key,
            sorder,
        };
        setFilterProduct({ ...f });
        getTopProducts(f);
    };

    const sortingCategory = (key) => {
        let sorder = "asc";
        if (filtersCateory.key == key) {
            if (filtersCateory.sorder == "asc") {
                sorder = "desc";
            } else {
                sorder = "asc";
            }
        }

        let sortBy = `${key} ${sorder}`;
        let f = {
            sortBy,
            key,
            sorder,
        };
        setFilterCategory({ ...f });
        getTopCategories(f);
    };
    const [insightData, setInsightData] = useState("");
    const [recommendationData, setRecommendationData] = useState("");
    const [insightLoader, setInsightLoader] = useState(false);

    const [chatRes, setChatRes] = useState()
    const chatType = 'coupons'
    const getReply = async () => {
        let parm = {
            start_date: filters.startDate,
            end_date: filters.endDate,
            type: chatType,
        }
        setInsightLoader(true);
        ApiClient.get('api/insight/detail', parm).then(async res => {
            if (res.success) {
                setChatRes(res.data)
                if (res.data.insight) {
                    setInsightData(res.data.insight)
                    setRecommendationData(res.data.recommendation)
                    setInsightLoader(false);
                } else {
                    let message = "";
                let array=overtimeData;
                array.concat(salesByCountry,salesByCurrency)
                    message = JSON.stringify( array|| []);
                    let topic = "get insites from the bellow data";
                    setInsightLoader(true);
                    setRecommendationData("");
                    const assistantResponse1 = await getAssistantResponse(message, topic);
                    const assistantResponse = await getAssistantResponse(assistantResponse1, "convert my text into html");
                    setInsightLoader(false);
                    setInsightData(assistantResponse);
                    document.querySelector("body").classList.add("max-[1535.98px]:overflow-hidden")

                }
            } else {
                setInsightLoader(false);
            }
        })
    };


    const viewRecommendation = async () => {
        let message = "";
        message = insightData;

        let topic = "provide me recommendation for bellow sales insight data";
        setInsightLoader(true);
        const assistantResponse1 = await getAssistantResponse(message, topic);
        const assistantResponse = await getAssistantResponse(assistantResponse1, "convert my text into html");
        setInsightLoader(false);
        setRecommendationData(assistantResponse);

        let payload = {
            insight: insightData,
            recommendation: assistantResponse,
            type: chatType,
            start_date: filters.startDate,
            end_date: filters.endDate
        }
        ApiClient.post('api/insight', payload, '', true).then(res => {
            if (res.success) {

            }
        })
    };

    useEffect(() => {
        if (insightData && !chatRes.insight) {
            viewRecommendation()
        }
    }, [insightData])

  
    const partnerVal = () => {
        let ext = PartnerOptions && PartnerOptions.find(item => item.value == filters.partner)
        return ext ? ext : ''
    }


    let performanceLegends = [
        { label: "Total Sales", key: "totalSales", compare: "totalSales" },
 
    ];

    const [TopCoupons,setTopCoupons]=useState([]);
    const GetTopCoupons=(p={})=>{
        const filter={...filters,...p}
        ApiClient.get(`api/top/vouchers`,filter).then(res=>{
            if(res.success){
                setTopCoupons(res.topVouchers)
            }
        })
    }

    return (
        <Layout>
            <div className="2xl:flex 2xl:gap-2">
                <div id="useSelectorCoupons" className={`grow ${insightData ? "2xl:w-[calc(100%_-_300px)]" : ""}`}>
                    <div className="flex items-center justify-between mb-6" >
                        <div className="flex flex-col gap-1">
                            <h4 className="text-2xl font-semibold text-[#3C3E49]">Coupons</h4>
                            <p className="text-sm font-normal text-[#75757A]">
                                Here you can see all about your product statistics
                            </p>
                        </div>
                        <div className="flex gap-4">
                            <button
                                type="button"
                                onClick={(e) => getReply()}
                                disabled={insightLoader}
                                className="!px-4 text-typo text-sm font-normal py-2.5 flex items-center justify-center gap-2 bg-[#fff] rounded-lg shadow-btn hover:bg-[#6db56224] border border-[#D0D5DD] transition-all focus:ring-2 ring-[#F1F2F3] disabled:bg-[#F3F2F5] disabled:cursor-not-allowed">
                                <HiOutlinePresentationChartBar className="text-typo text-xl" />
                                View Insights
                                {insightLoader ? (
                                    <FaSpinner className="animate-spin inline-block" />
                                ) : (
                                    <></>
                                )}
                            </button>
                            {insightData ? (
                                <>
                                    <button
                                        type="button"
                                        onClick={(e) => viewRecommendation()}
                                        className="!px-4 text-sm font-normal text-white h-11 flex items-center justify-center gap-2 bg-[#71B55C] rounded-lg shadow-btn hover:opacity-80 transition-all focus:ring-2 ring-[#EDEBFC] disabled:bg-[#D0CAF6] disabled:cursor-not-allowed">
                                        <BiLike className="text-xl text-white" />
                                        View Recommendation
                                        {insightLoader ? (
                                            <FaSpinner className="animate-spin inline-block" />
                                        ) : (
                                            <></>
                                        )}
                                    </button>
                                </>
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>

                    <div className="grow mb-7 flex flex-wrap items-center justify-end gap-3">
              
                        <button className="!px-4 text-typo text-sm font-normal py-2.5 flex items-center justify-center gap-2 bg-[#fff] rounded-lg shadow-btn hover:bg-[#6db56224] border border-[#D0D5DD] transition-all focus:ring-2 ring-[#F1F2F3] disabled:bg-[#F3F2F5] disabled:cursor-not-allowed">
                            {filters.forward ? "View By Travel Date" : "View By Order Date"}
                            <label className="relative inline-flex items-center cursor-pointer">
                                <input
                                    type="checkbox"
                                    checked={filters.forward}
                                    onChange={(e) => {
                                        dataFilter({ forward: e.target.checked });
                                    }}
                                    className="peer sr-only"
                                />
                                <div className="w-11 h-6 bg-[#6db562b3] peer-focus:outline-none peer-focus:ring-0 peer-focus:ring-blue-300 peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-[#71B55C] rounded-full" />
                            </label>
                        </button>
                        <div className="w-full max-w-[300px] !p-1 bg-[#6db56236]  border border-gray-200 rounded-lg flex items-center gap-2">
                            <label
                                className={`flex-1 mb-0 cursor-pointer flex items-center justify-center gap-2 rounded-md text-sm font-medium py-2 px-3 focus:outline-none ${filters.yearType == "financial"
                                    ? "text-typo font-medium bg-white !rounded-md shadow-sm border-2 border-primary"
                                    : "text-[#1D2433A6]"
                                    }`}
                                onClick={(e) => yearTypeChange("financial")}>
                                Financial year
                            </label>
                            <label
                                className={`flex-1 mb-0 cursor-pointer flex items-center justify-center gap-2 rounded-md text-sm font-medium py-2 px-3 focus:outline-none ${filters.yearType == "calendar"
                                    ? "text-typo font-medium bg-white !rounded-md shadow-sm border-2 border-primary"
                                    : "text-[#1D2433A6]"
                                    }`}
                                onClick={(e) => yearTypeChange("calendar")}>
                                Calendar
                            </label>
                        </div>
                        <DateRangePicker
                            disabled={filters.yearType == "calendar" ? false : true}
                            title={filters.yearType == 'calendar' ? '' : 'Change Data by Calendar Year to change dates'}
                            value={{
                                startDate: filters.startDate,
                                endDate: filters.endDate,
                            }}
                            onChange={(e) => onRangeChange(e)}
                            fullWidth={false}
                        />
                        <button
                            onClick={print}
                            type="button"
                            className="relative cursor-default rounded-lg bg-[#fff] !px-4 text-left focus:outline-none h-10 flex items-center justify-center gap-2 shadow-box hover:bg-[#6db56224] transition-all disabled:bg-[#F3F2F5] disabled:cursor-not-allowed">
                            <AiOutlineDownload className="text-typo text-xl" />
                            Export PDF
                        </button>
                        <button
                            onClick={reset}
                            type="button"
                            className="!px-4 text-sm font-normal text-white h-10 flex items-center justify-center gap-2 bg-[#71B55C] rounded-lg shadow-btn hover:opacity-80 transition-all focus:ring-2 ring-[#EDEBFC] disabled:bg-[#D0CAF6] disabled:cursor-not-allowed">
                            <RxReset className="text-xl text-white" />
                            Reset
                        </button>
                    </div>

                    <div className="grid grid-cols-1 xl:grid-cols-2 xl:grid-cols-4 gap-[16px] mb-6">
                        <div className="p-[16px] flex flex-col gap-8 rounded-lg bg-white shadow-box">
                            <Badge label="Total Sales" />
                            <div className="">
                                <h2 className="text-typo text-2xl font-medium">
                                    ${CouponsCountData?.totalSaleUsingVouchers||0}
                                </h2>
                           
                            </div>
                        </div>
                   
                        <div className="p-[16px] flex flex-col gap-8 rounded-lg bg-white shadow-box">
                            <Badge label="Total Coupons Order" />
                            <div className="">
                                <h2 className="text-typo text-2xl font-medium">
                                    {CouponsCountData?.totalVoucherOrders || 0}
                                </h2>

                            </div>
                        </div>
                        
                        
                        <div className="p-[16px] flex flex-col gap-8 rounded-lg bg-white shadow-box">
                            <Badge label="Average  Order" />
                            <div className="">
                                <h2 className="text-typo text-2xl font-medium">
                                    ${CouponsCountData?.avgOrderValue || 0}
                                </h2>

                            </div>
                        </div>
                     
                         
                    </div>
                    <AllChart data={overtimeData} legends={performanceLegends} />


                    <div className="shadow-box bg-white rounded-lg mb-6 mt-3">
                        <h1 className="text-typo text-lg font-medium p-[20px]">
                            Top Coupons
                        </h1>
                        <div className="scrollbar w-full overflow-auto">
                            <table className="w-full">
                                <thead className="border-y border-[#EAECF0]">
                                    <tr className=" border-y border-[#EAECF0]">
                                        <th 
                                            className="text-left cursor-pointer text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 bg-[#6db56214] !py-4">
                                            Code{" "}
                                        </th>
                                        <th
                                            className="text-left cursor-pointer text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 bg-[#6db56214] !py-4">
                                            Issue Date{" "}
                                        </th>
                                        <th
                                            className="text-left cursor-pointer text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 bg-[#6db56214] !py-4">
                                            Expiry Date{" "}
                                        </th>
                                        <th
                                            className="text-center cursor-pointer text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 bg-[#6db56214] !py-4">
                                            Orders{" "}                                        </th>
                               
                                    
                                        <th
                                            className="text-right cursor-pointer text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 bg-[#6db56214] !py-4">
                                            Avg. Order Value{" "}
                                        </th>
                                        <th

                                            className="text-center cursor-pointer text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 bg-[#6db56214] !py-4">
                                            Status{" "}

                                        </th>
                                 
                                    </tr>
                                </thead>
                                <tbody >
                                    {TopCoupons?.map((itm, i) => {
                                        return (
                                            <tr key={i}>
                                                <td className="text-center cursor-pointer text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 bg-[#6db56214] !py-4">
                                                    <div className="flex gap-2 item-center">
                                                     
                                                        <p className="">{itm?.voucherDetail?.code}</p>
                                                    </div>
                                                </td>
                                                <td className="text-center cursor-pointer text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 bg-[#6db56214] !py-4">
                                                    {datepipeModel.date(itm?.voucherDetail?.issueDate)}
                                                </td>
                                                <td className="text-center cursor-pointer text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 bg-[#6db56214] !py-4">
                                                    {datepipeModel.date(itm?.voucherDetail?.expiryDate) }
                                                </td>
                                                <td className="text-center cursor-pointer text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 bg-[#6db56214] !py-4">
                                                    {itm?.totalOrders}
                                                </td>
                                                <td className="text-center cursor-pointer text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 bg-[#6db56214] !py-4">
                                                    ${itm?.avgOrderValue}
                                                </td> 
                                                <td className="text-center cursor-pointer text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 bg-[#6db56214] !py-4">
                                                    {itm?.voucherDetail?.status}
                                                </td> 
                                                
                                                   
                                               
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
 
                    <div className="shadow-box flex flex-col bg-white rounded-lg">
                  
                        <div className="grid grid-cols-1 xl:grid-cols-2 gap-[24px] mb-[24px]">
                            <div className="shadow-box flex flex-col bg-white rounded-lg">
                                <div className="flex justify-between py-[20px] px-[20px] items-center">
                                    <h6 className="text-typo text-lg font-semibold">
                                        Sales by Payment Method
                                    </h6>
                                </div>
                                <div className="grid w-full grid-cols-full gap-2 pb-[20px] px-[20px]">
                                    <div>
                                    
                                        <PieChart data={paymentOptionsSales} />
                                    </div>

                   
                                </div>

                                <div className="h-14 flex items-center justify-center mt-auto border-t">
                               
                                </div>
                            </div>

                            <div className="shadow-box flex flex-col bg-white rounded-lg">
                                <div className="flex justify-between py-[20px] px-[20px] items-center">
                                    <h6 className="text-typo text-lg font-semibold">
                                        Sales by Currency
                                    </h6>
                                </div>
                                <div className="grid w-full grid-cols-full gap-2 pb-[20px] px-[20px]">
                                    <div>
                                       
                                        <DoughnutChart data={salesByCurrency} />
                                    </div>

                                
                                </div>

                                <div className="h-14 flex items-center justify-center mt-auto border-t">
                 
                                </div>
                            </div>
                        </div>

                        <div className=" xl:grid-cols-2 gap-[24px] mb-[24px]">
                            <div className="shadow-box flex flex-col bg-white rounded-lg">
                                <div className="flex justify-between py-[20px] px-[20px] items-center">
                                    <h6 className="text-typo text-lg font-semibold">
                                        Sales by Country 
                                    </h6>
                                </div>
                                <div className="grid w-full grid-cols-full gap-2 pb-[20px] px-[20px]">
                                    <div>

                                        <PieChart data={salesByCountry} />
                                    </div>


                                </div>

                           
                            </div>

                          
                        </div>
                    </div>
                </div>

{/*  HERE WE WILL SHOW THE INSIGHT LOADER DATA ################################################## */}
                {insightData ? (
                    <>
                        <div className="max-[1535.98px]:absolute max-[1535.98px]:top-0 max-[1535.98px]:right-0 max-[1535.98px]:z-[51] min-w-[300px] max-w-[300px] w-full bg-white shadow-btn p-4 rounded-lg max-[1535.98px]:h-screen 2xl:h-[calc(100vh_-_88px)] overflow-auto scrollbar">
                            <div className="flex items-center gap-4 mb-4">
                                <div className="h-10 w-10 shrink-0 rounded-md shadow-box bg-white flex items-center justify-center text-2xl !text-[#71B55C]">
                                    <MdOutlineAssessment />
                                </div>
                                <h4 className="text-typo text-xl font-medium">Insight</h4>
                                <button
                                    onClick={() => {
                                        setInsightData("");
                                        document.querySelector("body").classList.remove("max-[1535.98px]:overflow-hidden")
                                    }}
                                    className="ml-auto h-9 w-9 shrink-0 shadow-btn hover:shadow-none p-1 rounded-lg border border-gray-100 !text-[#71B55C]">
                                    <FiX className="w-full h-full" />

                                </button>
                            </div>
                            <div className="bg-white border border-[#EBEBEB] rounded-xl p-4">
                                <div
                                    className="w-full [&>h1]:text-sm [&>h1]:font-semibold [&>h1]:text-[#111827] [&>p]:text-xs [&>p]:text-[#333A41] [&>p]:font-medium [&>p]:my-2 [&>ol]:text-xs [&>ol]:text-[#4A545E] [&>ol]:list-decimal [&>ol]:list-inside [&>ol]:space-y-1.5"
                                    dangerouslySetInnerHTML={{ __html: sharedModel.getInsightHtml(insightData) }}></div>
                            </div>
                            {!recommendationData ? (
                                <>
                                    <button
                                        type="button"
                                        onClick={(e) => viewRecommendation()}
                                        className="2xl:hidden my-3 !px-4 text-sm font-normal text-white h-11 flex items-center justify-center gap-2 bg-[#71B55C] rounded-lg shadow-btn hover:opacity-80 transition-all focus:ring-2 ring-[#EDEBFC] disabled:bg-[#D0CAF6] disabled:cursor-not-allowed">
                                        <BiLike className="text-xl text-white" />
                                        View Recommendation
                                        {insightLoader ? (
                                            <FaSpinner className="animate-spin inline-block" />
                                        ) : (
                                            <></>
                                        )}
                                    </button>
                                </>) : <></>
                            }
                            {recommendationData ? (
                                <>
                                    <div className="my-6 border-b" />
                                    <div className="mt-6">
                                        <div className="flex items-center gap-4 mb-4">
                                            <div className="h-10 w-10 shrink-0 rounded-md shadow-box bg-white flex items-center justify-center text-2xl !text-[#71B55C]">
                                                <MdOutlineAssessment />
                                            </div>
                                            <h4 className="text-typo text-xl font-medium">
                                                Recommendation
                                            </h4>
                                        </div>
                                        <div
                                            className="w-full recommendationData"
                                            dangerouslySetInnerHTML={{
                                                __html: sharedModel.getInsightHtml(recommendationData),
                                            }}></div>
                                    </div>
                                </>
                            ) : (
                                <></>
                            )}
                        </div>
                        <div className="fixed inset-0 bg-black/30 2xl:hidden z-50" />
                    </>
                ) : (
                    <></>
                )}



            </div>
        </Layout>
    );
}
