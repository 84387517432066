import { useEffect, useState } from "react";
import "./style.scss";
import { useNavigate } from "react-router-dom";
import Sidebar from "../sidebar";
import Header from "../header";
import { useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import ReactGA from "react-ga";
import methodModel from "../../../methods/methods";
import { FaArrowUp } from "react-icons/fa";
import environment from "../../../environment";
import swal from "sweetalert";

const Layout = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  let user = useSelector((state) => state.user);
  const searchState = useSelector((state) => state.search);
  const history = useNavigate();
  const URLPath = window.location.pathname;
  const [showIcon, setshowIcon] = useState(false);

  const useAnalyticsEventTracker = (category = "Blog category") => {
    const eventTracker = (action = "test action", label = "test label") => {
      ReactGA.event({ category, action, label });
    };
    return eventTracker;
  };

  ReactGA.initialize("G-PDR2K98N27");
  useEffect(() => {
    useAnalyticsEventTracker("Dazboard");
  }, []);

  useEffect(() => {
    if (user && !user.loggedIn&&URLPath!="/company") {
      history("/login");
    }else{
      if(window.location.pathname=='/'){
      if(!user.companyName){
history()
      }else{
        history('/dashboard')
      }
    }
    }
  }, [searchState,user]);

  const logo = () => {
    let value = "/assets/img/logo.png";
    return value;
  };
  const logos = () => {
    let value = "/assets/img/logo-small.png";
    return value;
  };
  const router = () => {
    let route = localStorage.getItem("route");
    history(route);
  };

  const scrollToTop = () => {
    window.scroll({ top: 0, behavior: "smooth" });
  };

  window.onscroll = function () {
    if (document.documentElement.scrollTop <= 50) {
      setshowIcon(false);
    } else {
      setshowIcon(true);
    }
  };




  const ParnterNavigateHandler = () => {
    if(user?.subRole?.id==environment.SubRolePartner){
    if (user?.subscriptionId || user?.on_trial || user?.supplier_id) {

    } else {
      if (URLPath == '/review-dashboard' || URLPath == '/reviews' || URLPath == '/reseller-dashboard' || URLPath == '/reseller-data' || URLPath == '/crm' || URLPath == '/waiver' || URLPath == '/financial/dashboard' || URLPath == '/accounting/transactions' || URLPath == '/accounting/invoices' || URLPath == '/omni-channels/live-chat' || URLPath == '/plans' || URLPath == '/activeplan' 
      // || URLPath == '/sales/retrievable' || URLPath == '/sales/refunds' || URLPath == '/products/retrivable' 
        || URLPath == '/marketing/google-analytics' || URLPath == '/users' || URLPath == '/card' || URLPath == '/pos' || URLPath == '/coupons' || URLPath == '/coupons-data'){
        history(-1)
        swal({ text: "If you want to use this email as Supplier account. Ask your admin to make it disable or you can signup with new email as supplier",icon:"error" });  
        // Swal.fire({
        //   title: "Good job!",
        //   text: "You clicked the button!",
        //   icon: "success"
        // });
      }
    }
  }
  }
  useEffect(()=>{
    ParnterNavigateHandler()
  },[URLPath])
  return (
    <>
      <div component="layout">
        <div onClick={(e) => router()} id="routerDiv"></div>
        <Header />
        <div
          className={`main-wrapper flex ${user?.sidebar ? "active-sidebar" : ""
            }`}>
          <div className="main-sidebar scrollbar transition-[width] duration-300">
            <div
              className="sidebar-brand p-3 pt-4 pl-5 text-left border-b-[#E2E4E9] "
      >
              <label className="editLogo">
                <img src={logo()} className="logocls show-logo" width="170" height="35" />
                <img src={logos()} className="hide-logo" width="24" height="24" />
              </label>
            </div>
            {user?.logo ? (
              <div
                className="flex justify-center"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}>
                <img
                  src={methodModel.userImg(user?.logo || "")}
                  alt="photo"
                  width="40"
                  height="40"
                  style={{
                    width: "40px",
                    marginBottom: "2px",
                    height: "40px",
                    borderRadius: "50%",
                  }}
                />
              </div>
            ) : null}
            <Sidebar />
          </div>
          <main className="main">
            <div className="bg-white !p-5 mt-[69px]">{children}</div>
          </main>
        </div>
        {URLPath == "/sales" ||
          URLPath == "/dashboard" ||
          URLPath == "/review-dashboard" ||
          URLPath == "/product-dashboard" ||
          URLPath == "/customers" ||
          URLPath == "/marketing/google-analytics" ||
          URLPath == "/financial/dashboard" ? (
          <div className={`h-10 w-10 fixed bottom-16 right-16 cursor-pointer shadow-box bg-white flex items-center justify-center d-${showIcon ? "block" : "none"}`}>
            <FaArrowUp className={`d-${showIcon ? "block" : "none"}`}
              onClick={(e) => scrollToTop()}
              id="arrow-up"
              title="Back Top"
            />
          </div>
        ) : null}
      </div>
    </>
  );
};
export default Layout;
