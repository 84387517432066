import { HiOutlineArrowDown } from "react-icons/hi";
import Pagination from "react-pagination-js";

const Table = ({
  className = "",
  data = [],
  columns = [],
  topHead = [],
  count = 50,
  total = 0,
  page = 1,
  result = (e) => {},
  nodata = (
    <div className="p-5 text-center no-data-found">
      <img
        src="/assets/img/no-data-f.png"
        className="w-[100px] mx-auto	opacity-50	"
      />
      <p className="text-sm tracking-widest uppercase ">No Data Found</p>
    </div>
  ),
}) => {
  const handlePaginate = (e) => {
    console.log("e", e);
    result({ event: "page", value: e });
  };
  const view = (row) => {
    result({ event: "row", row: row });
  };

  const headclick = (itm) => {
    if (itm.sort) {
      result({ event: "sort", value: itm.key });
    }
  };

  return (
    <>
      <div className={`${className}`}>
        <div className="relative overflow-x-auto  pt-[15px] ">
          <table className="w-full mb-5 text-left rtl:text-right">
            <thead className="text-[#525866] text-[14px] capitalize  font-normal">
              {topHead?.length ? (
                <>
                  <tr className="bg-gray-200 border-b border-black">
                    {topHead.map((itm, i) => {
                      return (
                        <>
                          <th
                            scope="col"
                            className={`text-[#82838B]  font-normal text-sm !border border-[#EAECF0] px-3.5 text-left bg-[#6db56214] !py-3 cursor-pointer  ${
                              topHead.length - 1 == i
                                ? ""
                                : "border-r border-black"
                            }`}
                            colSpan={itm?.colSpan || 0}
                            key={i}
                          >
                            {itm.name}
                          </th>
                        </>
                      );
                    })}
                  </tr>
                </>
              ) : (
                <></>
              )}
              <tr>
                {columns.map((itm) => {
                  return (
                    <>
                      <th
                        scope="col"
                        className={`text-[#82838B]  font-normal text-sm !border border-[#EAECF0] px-3.5 text-left bg-[#6db56214] !py-3 cursor-pointer ${
                          itm.sort ? "cursor-pointer" : ""
                        }`}
                        onClick={() => headclick(itm)}
                        key={itm.key}
                      >
                        {itm.name}{" "}
                        {itm.sort ? (
                          <>
                            <span className="ml-2">
                              <HiOutlineArrowDown className="inline text-sm shrink-0" />
                            </span>
                          </>
                        ) : (
                          <></>
                        )}
                      </th>
                    </>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {data.map((itm) => {
                return (
                  <>
                    <tr
                      onClick={() => view(itm)}
                      className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                      key={itm.id}
                    >
                      {columns.map((citm) => {
                        return (
                          <>
                            <td className="!text-typo  cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]">
                              {citm.render(itm) || "--"}
                            </td>
                          </>
                        );
                      })}
                    </tr>
                  </>
                );
              })}
            </tbody>
          </table>
        </div>
        {total ? (
          <></>
        ) : (
          <>
            <div className="p-4 text-center">{nodata}</div>
          </>
        )}

        {count < total ? (
          <>
            <div className="flex items-center justify-between paginationWrapper mt-15">
              <span>
                Show {count} from {total} data
              </span>
              <Pagination
                currentPage={page}
                totalSize={total}
                sizePerPage={count}
                changeCurrentPage={handlePaginate}
              />
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default Table;
