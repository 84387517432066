import { useEffect, useState } from 'react';
import Layout from '../../components/global/layout'
import ApiClient from '../../methods/api/apiClient'
import environment from '../../environment';
import FormControl from '../../components/common/FormControl';
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import loader from '../../methods/loader';

export default function KPIs() {
    const [categories, setCategories] = useState([]);
    const [active, setActive] = useState({ index: -1, id: '' });
    const [kpis, setKpis] = useState([]);
    const getCategories = () => {
        let f = {
            page: 1,
            status: "active",
            catType: environment.kpiTypeId
        }
        ApiClient.get('api/categories/listing', f).then(res => {
            if (res.success) {
                let data = res.data
                setCategories(data)
            }
        })
    }

    const getKpis = () => {
        let f = {
            status: 'active',
        }
        loader(true)
        ApiClient.get('api/all/dynamic-fields', f).then(res => {
            loader(false)
            if (res.success) {
                let data = res.data.map((itm, i) => {
                    return { ...itm, index: i }
                })
                setKpis(data)
            }
        })
    }


    useEffect(() => {
        getCategories()
        getKpis()
    }, [])



    const updateKey = (id, k, v) => {

        let arr = kpis
        let i = arr.find(itm => itm.id == id).index
        arr[i][k] = v
        console.log("i", i)
        console.log("k", k)
        console.log("v", v)
        setKpis([...arr])
    }

    const next = () => {
        let index = active?.index
        let categories = filterCat()
        index = index + 1


        if (index < categories.length) {
            let itm = categories[index]
            console.log("index", index)
            console.log("itm", itm)
            setActive({ index: index, id: itm.id })
        } else {
            submit()
        }
    }

    const back = () => {
        let index = active?.index
        let categories = filterCat()
        index = index - 1


        if (index >= 0) {
            let itm = categories[index]
            console.log("index", index)
            console.log("itm", itm)
            setActive({ index: index, id: itm.id })
        } else {

        }
    }

    const submit = () => {
        console.log("kpis", kpis)
    }

    const filterKpis = (ci = '') => {
        let catId = ci || active.id
        let arr = kpis.filter(itm => itm.category_id == catId)
        return arr
    }

    const filterCat = () => {
        let arr = categories.filter(itm => filterKpis(itm.id).length ? true : false)
        return arr
    }

    useEffect(() => {
        if (categories.length && kpis.length) {
            let cats = filterCat()
            setActive({ index: 0, id: cats[0].id })
        }
    }, [categories.length, kpis.length])

    return <>
        <Layout>

            <h2 className='mb-5 text-xl font-medium'>KPIs</h2>
            <div className='grid grid-cols-12 gap-6'>
                <div className='col-span-12 xl:col-span-3 lg:col-span-4 md:col-span-5'>
                    <ol class="bg-[#6db5620d] p-3 rounded-[10px] shadow-md border border-[#efefef]">
                        {filterCat().map((itm, i) => {
                            return <>
                                <li onClick={() => setActive({ index: i, id: itm.id })} key={i} className='mb-3 transition ease-in-out delay-150 cursor-pointer last:mb-0'>
                                    <div class={`w-full p-4 ${itm.id == active?.id ? ' bg-[#71B55C]  text-white py-[12px] rounded-[10px] text-[15px] ' : 'text-[#7b7b7b]   py-[12px] text-[15px] hover:text-white'}  dark:bg-gray-800 rounded-[10px] hover:bg-[#71B55C]  group" role="alert`}>
                                        <div class="flex items-center justify-between group-hover:text-white">
                                            <span class="sr-only ">{itm.name}</span>
                                            <h3 class="font-medium group-hover:text-white">{i + 1}. {itm.name}</h3>
                                            {itm.id == active?.id ? <>
                                                <svg class="rtl:rotate-180 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
                                                </svg>
                                            </> : <></>}
                                        </div>
                                    </div>
                                </li>
                            </>
                        })}
                    </ol>
                </div>
                <div className='col-span-12 xl:col-span-9 lg:col-span-8 md:col-span-7'>
                    <div className='grid grid-cols-12 gap-3 bg-white border border-[#6db56254] p-5 rounded-[10px]'>
                        {filterKpis().map((itm, i) => {
                            if (itm.type == 'yes/no' || itm.type == 'multi options') {
                                return <>
                                    <div key={i} className='col-span-12' >
                                   
                                        <FormControl
                                            label={<>
                                           <div className='flex items-center'>
                                           <BsFillArrowRightCircleFill className='me-2 text-[#71B55C]'/>{itm.name}
                                           </div>
                                            </>}
                                            type='radio'
                                            options={[
                                                { id: 'yes', name: 'Yes' },
                                                { id: 'no', name: 'No' },
                                            ]}
                                            value={itm.value}
                                            onChange={e => {
                                                updateKey(itm.id, 'value', e)
                                            }}
                                        />
                                    </div>
                                </>
                            } else if (itm.type == '%') {
                                return <>
                                    <div key={i} className='col-span-12'>
                                   
                                        <FormControl
                                            label={<>
                                           <div className='flex items-center'>
                                           <BsFillArrowRightCircleFill className='me-2 text-[#71B55C]'/>{itm.name}
                                           </div>
                                            </>}
                                            className=''
                                            type='number'
                                            max="100"
                                            placeholder='Enter Number'
                                            maxlength={3}
                                            value={itm.value}
                                            onChange={e => {
                                                updateKey(itm.id, 'value', e)
                                            }}
                                        />
                                    </div>
                                </>
                            } else {
                                return <>
                                    <div key={i} className='col-span-12'>
                                       
                                        <FormControl
                                           label={<>
                                           <div className='flex items-center'>
                                           <BsFillArrowRightCircleFill className='me-2 text-[#71B55C]'/>{itm.name}
                                           </div>
                                            </>}
                                            type='test'
                                            placeholder="Answer"
                                            value={itm.value}
                                            onChange={e => {
                                                updateKey(itm.id, 'value', e)
                                            }}
                                        />
                                    </div>
                                </>
                            }

                        })}

                    </div>
                    <div className='flex justify-end gap-3 mt-3'>
                        {active.index > 0 ? <>
                            <button
                                onClick={back}
                                className='!px-4 text-typo hover:!text-typo hover:no-underline text-sm font-normal py-2.5 flex items-center justify-center gap-2 bg-[#fff] rounded-lg shadow-btn hover:bg-[#6db56224] border border-[#D0D5DD] transition-all focus:ring-2 ring-[#F1F2F3] disabled:bg-[#F3F2F5] disabled:cursor-not-allowed whitespace-nowrap '>Back</button>
                        </> : <></>}

                        <button
                            onClick={next}
                            className='!px-4 text-typo hover:!text-typo hover:no-underline text-sm font-normal py-2.5 flex items-center justify-center gap-2 bg-[#fff] rounded-lg shadow-btn hover:bg-[#6db56224] border border-[#D0D5DD] transition-all focus:ring-2 ring-[#F1F2F3] disabled:bg-[#F3F2F5] disabled:cursor-not-allowed whitespace-nowrap'>Next</button>
                    </div>
                </div>
            </div>
        </Layout>



    </>
}