import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import { useSelector } from 'react-redux';
import { posType } from "../../models/type.model";
import { Link, useNavigate, useParams } from "react-router-dom";
import Layout from "../../components/global/layout";
import methodModel from "../../methods/methods";
import Breadcrumb from "../../components/common/Breadcrumb";
import SelectDropdown from '../../components/common/SelectDropdown';

const AddEditPOS = () => {
  const defaultvalue = () => {
    let keys = { ...posType }
    Object.keys(posType).map(itm => {
      if (itm != 'permissions') keys[itm] = ''
    })
    keys.status = 'active'
    keys.displayName=''
    return keys
  }
 
  const [Instructions,setInstructions]=useState("");
  const [FullPOSData,setFullPOSData]=useState([]);

  const { id } = useParams()
  const SelectedId=methodModel.getPrams('id')
  const SelectedPlatfrom = methodModel.getPrams('platform')
  const [tab, setTab] = useState('form')
  const [form, setform] = useState({id:"",name:"",apiKey:"",url:""});
  const [DisplayName,setDisplayName]=useState("")
  const [activePlan, setActivePlan] = useState()
  const [products, setProducts] = useState([])
  const [selectedProduct, setSelectedProduct] = useState([])
  const history = useNavigate()
  const [submitted, setSubmitted] = useState(false)
  const user = useSelector((state) => state.user);
  const formValidation = [
    { key: 'name', required: true }
  ]

  const rezdy = [
    { id: 'rezdy', name: 'rezdy' },
    { id: 'fareharbor', name: 'Fareharbor' }
  ]

  const getActivePlan = () => {
    ApiClient.get('api/my/plan').then(res => {
      if (res.success) {
        setActivePlan(res.data)
      }
    })
  }



  const getProducts = () => {
    ApiClient.get('api/products/listing', { page: 1, count: 50 }).then(res => {
      if (res.success) {
        setProducts(res.data)
      }
    })
  }

  const selectProduct = (id) => {
    let sp = selectedProduct
    if (sp.find(itm => itm == id)) {
      sp = sp.filter(itm => itm != id)
    } else {
      sp.push(id)
    }
    setSelectedProduct([...sp])
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setSubmitted(true)
    let invalid = methodModel.getFormError(formValidation, form)
    if (invalid) return
    let method = 'post'
    let url = 'api/pos'
    let value = {
      ...form,
      displayName:DisplayName
    }

    if(value.name=='procharter'){
      value.booking_platform='procharter'
      url='api/connect/procharter'
    } 
    if (value.name =='hakatours'){
      value.booking_platform ='hakatours'
      url='api/haka-tour'
    }
    if(value.name=='rezdy'){
      value.booking_platform = 'rezdy'
    }
    if (value.name == "flybook") {
      value.booking_platform = "flybook"
      url ='api/integrate/flybook'
    }
    if (value.name =='respax'){
      value.booking_platform ='respax'
      url ='api/integrate-respax'
    }
    
    if (value.id) {
      method = 'put'
      url = 'api/pos/update'
      if(value.name=='procharter') url='api/connect/procharter'
    } else {
      delete value.id
    }
    loader(true)
    ApiClient.allApi(url, value, method).then(res => {
      loader(false)
      if (res.success) {
        toast.success(res.message)
        history("/pos?redirect=add")
      }
    })
  }

  useEffect(() => {
    if (id) {
      loader(true)
      ApiClient.get('api/pos/detail', { id },'','',{apiCall:true}).then(res => {
        if (res.success) {
          let value = res.data
          let payload = posType
          Object.keys(payload).map(itm => {
            payload[itm] = value[itm]
          })
          setform({
            ...payload,
            name:value?.name||value?.booking_platform,
            displayName:value?.displayName

          })
         const Selecteddata=  item?.find((itm) => {
            return itm?.platform == value.name||value?.booking_platform
          })
        
          setInstructions(Selecteddata?.instructions)
          setDisplayName(value?.displayName)
        }
        loader(false)
      })
    } else { 
    }
    getProducts()
    getActivePlan()
  }, [id])

  const handlePOS = (e) => {
    if (e) {
      if (e == 'rezdy') {
        setform({ ...form, name: e, url: 'https://api.rezdy.com/v1' })
      } else if(e=='hakatours') {
        setform({ ...form, name: e, url: 'https://admin.hakatours.com' })
      } else {
        setform({ ...form, name: e, url: '' })
      }
    } else {
      setform({ ...form, name: '', url: '' })
    }
  }
console.log(form,"This is the form data ----");
  const [item, setitem] = useState([])
  const getData2 = () => {
      let url = 'api/bookingsystem/listing';
      const UserDomain=user?.email.split('@');
      let filter={status:'active',domains:UserDomain[1]||''}
      ApiClient.get(url, filter).then((res) => {
          if (res.success) {
            setFullPOSData(res?.data);

              setitem(res.data.map(itm=>{
                  return {
                      ...itm,
                      id:itm?.platform,name:itm.name||itm.platform}
              }))
            if (SelectedId) {
              const SelectedData = res?.data?.find((itm) => {
                return itm?.id == SelectedId
              })
              setDisplayName( SelectedData?.name);
              handlePOS(SelectedData?.platform);
              setInstructions(SelectedData?.instructions)
            }

            if(SelectedPlatfrom){
              const SelectedData = res?.data?.find((itm) => {
                return itm?.platform == SelectedPlatfrom
              })
              setDisplayName(SelectedData?.name);
              handlePOS(SelectedData?.platform);
              setInstructions(SelectedData?.instructions)
            }

          }

      });
  };

  useEffect(()=>{
      getData2()
  },[])

  
  const InstructionHandler=(e)=>{
    
    const data = item.find((itn) => itn.id ==e)
  setInstructions(data?.instructions||"")

  }

  return <>
    <Layout>
      {tab == 'form' ? <>
        <Breadcrumb
          links={[
            {
              name: 'Booking System',
              link: '/pos'
            }
          ]}
          currentPage={`${form && form.id ? 'Edit' : 'Add'} Booking System `}
        />
        <h5 className="my-6 text-xl font-semibold text-typo">{`${form && form.id ? 'Edit' : 'Add'} Booking System `}</h5>
        <div className="bg-white shadow-box rounded-lg max-w-[1020px]">
          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-1 p-6 gap-y-6">
              <div>
                <label className="text-[#75757A] text-sm font-normal !mb-3">Title</label>
                <input
                                type="text"
                  className="mt-2 shadow-box bg-white w-full text-sm placeholder:text-gray-500 rounded-lg h-10 flex items-center gap-2 overflow-hidden px-2 !ring-[#71B55C] !outline-[#71B55C]"
                  value={DisplayName}
                  onChange={e => setDisplayName(e.target.value )}
                                required
                            />
               
              </div>
              <div>
                <label className="text-[#75757A] text-sm font-normal !mb-3">Select Booking System</label>
                {/* <input
                                type="text"
                                className="form-control"
                                value={form.name}
                                onChange={e => setform({ ...form, name: e.target.value })}
                                required
                            /> */}
                <SelectDropdown
                  isSingle={true}
                  id="statusDropdown"
                  displayValue="name"
                  placeholder="Select Booking System"
                  intialValue={form?.name}
                  result={e => {
                    handlePOS(e.value); 
                    InstructionHandler(e.value)
}}
                  options={item}
                  className="w-full mt-2"
                />
              </div>
             
              {form?.name == 'fareharbor' ?
                <div>
                  <label className="text-[#75757A] text-sm font-normal !mb-3">Secret Key</label>
                  <input
                    type="text"
                    className="mt-2 shadow-box bg-white w-full text-sm placeholder:text-gray-500 rounded-lg h-10 flex items-center gap-2 overflow-hidden px-2 !ring-[#71B55C] !outline-[#71B55C]"
                    value={form.secretKey}
                    onChange={e => setform({ ...form, secretKey: e.target.value })}
                    required
                  />
                </div>
                : null}

                {form.name=='procharter'?<>
                <div>
                  <label className="text-[#75757A] text-sm font-normal !mb-3">Provider</label>
                  <input
                    type="text"
                    className="mt-2 shadow-box bg-white w-full text-sm placeholder:text-gray-500 rounded-lg h-10 flex items-center gap-2 overflow-hidden px-2 !ring-[#71B55C] !outline-[#71B55C]"
                    value={form.provider}
                    onChange={e => setform({ ...form, provider: e.target.value })}
                    required
                  />
                </div>
                <div>
                  <label className="text-[#75757A] text-sm font-normal !mb-3">GUID</label>
                  <input
                    type="text"
                    className="mt-2 shadow-box bg-white w-full text-sm placeholder:text-gray-500 rounded-lg h-10 flex items-center gap-2 overflow-hidden px-2 !ring-[#71B55C] !outline-[#71B55C]"
                    value={form.guid}
                    onChange={e => setform({ ...form, guid: e.target.value })}
                    required
                  />
                </div>
                <div>
                  <label className="text-[#75757A] text-sm font-normal !mb-3">Password</label>
                  <input
                    type="text"
                    className="mt-2 shadow-box bg-white w-full text-sm placeholder:text-gray-500 rounded-lg h-10 flex items-center gap-2 overflow-hidden px-2 !ring-[#71B55C] !outline-[#71B55C]"
                    value={form.password}
                    onChange={e => setform({ ...form, password: e.target.value })}
                    required
                  />
                </div>
                </>:<></>}
              {form.name == 'hakatours' ||form?.name=='respax'? <>
                <div>
                  <label className="text-[#75757A] text-sm font-normal !mb-3">User Name</label>
                  <input
                    type="text"
                    className="mt-2 shadow-box bg-white w-full text-sm placeholder:text-gray-500 rounded-lg h-10 flex items-center gap-2 overflow-hidden px-2 !ring-[#71B55C] !outline-[#71B55C]"
                    value={form.username}
                    onChange={e => setform({ ...form, username: e.target.value })}
                    required
                  />
                </div>
                <div>
                  <label className="text-[#75757A] text-sm font-normal !mb-3">Password</label>
                  <input
                    type="text"
                    className="mt-2 shadow-box bg-white w-full text-sm placeholder:text-gray-500 rounded-lg h-10 flex items-center gap-2 overflow-hidden px-2 !ring-[#71B55C] !outline-[#71B55C]"
                    value={form.password}
                    onChange={e => setform({ ...form, password: e.target.value })}
                    required
                  />
                </div>
                
              </> : <></>}
                {form.name=='rezdy'||form?.name=='flybook'?<>
                <div>
                <label className="text-[#75757A] text-sm font-normal !mb-3">API Key</label>
                <input
                  type="text"
                  className="mt-2 shadow-box bg-white w-full text-sm placeholder:text-gray-500 rounded-lg h-10 flex items-center gap-2 overflow-hidden px-2 !ring-[#71B55C] !outline-[#71B55C]"
                  value={form.apiKey}
                  onChange={e => setform({ ...form, apiKey: e.target.value })}
                  required
                />
              </div>
                {/* <div>
                <label className="text-[#75757A] text-sm font-normal !mb-3">Url</label>
                <input
                  type="text"
                  className="mt-2 shadow-box bg-white w-full text-sm placeholder:text-gray-500 rounded-lg h-10 flex items-center gap-2 overflow-hidden px-2 !ring-[#71B55C] !outline-[#71B55C]"
                  value={form.url}
                  disabled={!form?.name}
                  onChange={e => setform({ ...form, url: e.target.value })}
                  required
                />
              </div> */}
                </>:<></>}
              
            </div>

            {Instructions ?<div class="p-4 bg-gray-100 rounded-lg shadow-md m-5">
              <h4>Instructions</h4>
      <div className="p-4 classli" dangerouslySetInnerHTML={{__html:Instructions}}></div>
            </div>:null}


            <div className="px-6 !py-4 border-t border-[#EAECF0] flex justify-end items-center !gap-3">
              <Link to="/pos" className="!px-4 text-typo hover:!text-typo hover:no-underline text-sm font-medium py-2.5 flex items-center justify-center gap-2 bg-[#fff] rounded-lg shadow-btn hover:bg-[#6db56224] border border-[#D0D5DD] transition-all focus:ring-2 ring-[#F1F2F3] disabled:bg-[#F3F2F5] disabled:cursor-not-allowed">Back</Link>
              <button type="submit" className="!px-4 text-sm font-medium text-white hover:!text-white hover:no-underline h-10 flex items-center justify-center gap-2 bg-[#71B55C] rounded-lg shadow-btn hover:opacity-80 transition-all focus:ring-2 ring-[#EDEBFC] disabled:bg-[#D0CAF6] disabled:cursor-not-allowed">Save</button>
            </div>
          </form>
        </div>
      </> : <>
        {activePlan?.id ? <>
          <h4>Select Products</h4>
          <p>{selectedProduct.length} Selected {selectedProduct.length > activePlan?.planId?.allowedProducts ? <span className="text-danger">({selectedProduct.length - activePlan?.planId?.allowedProducts} Extra Products) <i className="fa fa-info" title={`You Will pay exta ${activePlan?.planId?.extraProductPrice?.[activePlan?.subscription.currency]} ${activePlan?.subscription.currency} per product`} id="infoTooltip" onmouseover="tooltiphover('infoTooltip')" data-toggle="tooltip" data-placement="top"></i></span> : <></>}</p>
          <div className="table-responsive table-striped prodictable">
            <table className="table">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Product Name</th>
                  <th>Category / Sub Category</th>
                  <th>Sales</th>
                </tr>
              </thead>
              <tbody>
                {products.map(itm => {
                  return <tr>
                    <td> <input type="checkbox" checked={selectedProduct.includes(itm.id)} onChange={e => selectProduct(itm.id)} /></td>
                    <td>{itm.name}</td>
                    <td>{itm.productType}</td>
                    <td>{itm.sale}</td>
                  </tr>
                })}

              </tbody>
            </table>
          </div>

          <div className="mt-3 text-right">
            <button className="btn btn-primary">Save</button>
          </div>
        </> : <>
          Please Purchase a plan
        </>}
      </>}
    </Layout>
  </>
}

export default AddEditPOS