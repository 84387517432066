export const ITEM = [
  {
    badgeLabel: "Total Sales",
    value: "$3,4598",
    increment: "2.11%",
  },
  {
    badgeLabel: "Total orders",
    value: "34K",
    increment: "2.11%",
  },
  {
    badgeLabel: "Total Pax",
    value: "345.8K",
    increment: "2.11%",
  },
  {
    badgeLabel: "Additional Revenue",
    value: "$4,598",
    decrement: "2.11%",
  },
  {
    badgeLabel: "Average Order value",
    value: "$67.00",
    decrement: "2.11%",
  },
  {
    badgeLabel: "Commission Paid",
    value: "$2,980",
    increment: "2.11%",
  },
  {
    badgeLabel: "Lead Times",
    value: "56 Days",
    increment: "2.11%",
  },
];

export const ITEM_1 = [
  {
    badgeLabel: "Total Sales",
    key: 'totalSale',
  },
  {
    badgeLabel: "Discounted Offered",
    key: '',
  },
  {
    badgeLabel: "Commissions Paid",
    key: 'commissionPaid',
  },
  {
    badgeLabel: "Net Sales",
    key: 'totalSale',
  },
  {
    badgeLabel: "Forward Bookings",
    key: 'forward_bookings'
  }
];

export const ITEM_2 = [
  {
    badgeLabel: "Reseller Sales",
    key: 'totalSale',
  },
  {
    badgeLabel: "Commissions Paid",
    key: 'commissionPaid',
  },
  {
    badgeLabel: "Net Sales from Resellers",
    key: 'totalSale',
  },
  {
    badgeLabel: "Forward Bookings",
    key: 'forward_bookings'
  }
];

export const ITEM_3 = [
  {
    badgeLabel: "Invoice Raised",
    key: 'invoice_raised',
  },
  {
    badgeLabel: "Invoice Paid",
    key: 'invoice_raised',
  },
  {
    badgeLabel: "Balance Owing",
    key: 'invoice_due',
  },
  {
    badgeLabel: "Forward Bookings",
    key: 'forward_bookings',
  }
];

export const CHART_OPTIONS = [
  { option: "Bar Chart" },
  { option: "Pie Chart" },
  { option: "Line Chart" },
];

export const MONTH_OPTIONS = [
  { option: "This Month" },
  { option: "Last Month" },
];

export const colourStyles = {
  control: (styles, state) => ({
    ...styles,
    cursor: "pointer",
    borderWidth: 0,
    
    minHeight: 40,
    backgroundColor: "white",
    borderRadius: 8,
    textAlign: "left",
    minWidth: 180,
    boxShadow:
      "0px 0px 0px 1px rgba(18, 55, 105, 0.08), 0px 1px 2px 0px rgba(164, 172, 185, 0.24)",
    outline: state.isFocused ? "2px solid #71B55C" : "",
    
  }),
  menu: (styles) => ({
    ...styles,
    outline: "2px solid #71B55C",

  }),
  option: (styles, { isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? undefined
        : isSelected
          ? "#F7FAFF"
          : isFocused
            ? "#F7FAFF"
            : undefined,
      cursor: "pointer",
      color: isDisabled ? "#ccc" : isSelected ? "#000" : "#000",
      fontWeight: isSelected ? 500 : 400,
      cursor: isDisabled ? "not-allowed" : "default",
      ":active": {
        ...styles[":active"],
        backgroundColor: !isDisabled
          ? isSelected
            ? "#000"
            : "#F7FAFF"
          : undefined,
        color: !isDisabled ? isSelected && "#000" : undefined,
      },
    };
  },
  input: (styles) => ({
    ...styles,
    fontSize: 14,
    color: "#1D2433",
    fontWeight: 400,
  }),
  placeholder: (styles) => ({ ...styles, fontWeight: 400, color: "#98A2B3" }),
  singleValue: (styles, { data }) => ({ ...styles }),
  indicatorSeparator: (styles) => ({
    ...styles,
    display: "none"
  })
};


export const userTabs = [
  { title: 'User List', link: '/users' },
  { title: 'Roles', link: '/roles' },
];

export const BookingTabs = [
  { title: 'Booking System', link: '/pos' },
  { title: 'Reviews', link: '/api/review-connected' },
  // { title: 'Marketing Analytics', link: '/marketing-analytics' },
  { title: 'Accounting', link: '/api/accounting' },
  // { title: 'Tour Details', link: '/api/tour' },
  // { title: 'Employees', link: '/api/employees' },
  // { title: 'Social Media Scheduling Integration', link: '/omni-channel-setting/social/media' },
];


export const XeroTabs = [
  { title: 'Xero Invoices', link: '/accounting/invoices' },
  { title: 'Bills to Pay', link: '/accounting/bills' },
];