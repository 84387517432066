import { useRef, useEffect, useState } from 'react';
import {
    Box,
    Column,
    Heading,
    Button
} from 'gestalt';
import WebViewer from '@pdftron/webviewer';
import 'gestalt/dist/gestalt.css';
import loader from '../../methods/loader';
import ApiClient from '../../methods/api/apiClient';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import SelectDropdown from '../../components/common/SelectDropdown';
// import './PrepareDocument.css';
import environment from '../../environment';
import { MdCheck } from "react-icons/md";
import { GrDocumentPdf } from 'react-icons/gr';
import { PiSignature } from 'react-icons/pi';


const PrepareDocument = () => {
    const [Submitted,setSubmitted]=useState(false);
    const Navigate=useNavigate();
    const [Name,setName]=useState("");
    const [ExternalName, setExternalName]=useState("");
    const SearchParams=new URLSearchParams(window.location.search);
    const [resellerType,setresellerType]=useState(SearchParams.get('resellerType'));
    const [resellerTypes,setResellerTypes]=useState([]);
    const id=SearchParams.get('id'); 
    const copy = SearchParams.get('copy')||false;
    const custom = SearchParams.get('custom') || false;
    const templateId = SearchParams.get('templateId') ;
    const resellerId = SearchParams.get('resellerId');
    const [data,setdata]=useState({fileUrl:"",type:"file"})
    const [instance, setInstance] = useState(null);
    const [dropPoint, setDropPoint] = useState(null);
    const [UploadedFile,setUploadedFile]=useState(false);
    const [FileUploadError,setFileUploadError]=useState("")


    const viewer = useRef(null);
    const filePicker = useRef(null);

    // if using a class, equivalent of componentDidMount
    useEffect(() => {
        WebViewer(
            {
                path: 'webviewer',
                fullAPI:true,
                enableMeasurement:true,

                disabledElements: [
                    'ribbons',
                    'toggleNotesButton',
                    'searchButton',
                    'menuButton',
                    'viewControlsButton'
                ],
            },
            viewer.current,
        ).then(instance => {
            const { iframeWindow } = instance.UI;

            // select only the view group
            instance.UI.setToolbarGroup('toolbarGroup-View');
            console.log(instance, "THis is")
            setInstance(instance);
const {annotationManager}=instance.Core;
            instance.UI.setLayoutMode(instance.UI.LayoutMode.Single);

  

            


            const iframeDoc = iframeWindow.document.body;
            iframeDoc.addEventListener('dragover', dragOver);
            iframeDoc.addEventListener('drop', e => {
                drop(e, instance);
            });

            filePicker.current.onchange = e => {
                const file = e.target.files[0];
                if (file) {
                    setUploadedFile(true);
                    instance.UI.loadDocument(file);
                }else{
                    setUploadedFile(false);
                }
            };
        });
    }, []);

    const applyFields = async () => {
        setSubmitted(true);
        if (!Name || !ExternalName ) {
            return
        } 
            const { Annotations, documentViewer } = instance.Core;
            const annotationManager = documentViewer.getAnnotationManager();
            const fieldManager = annotationManager.getFieldManager();
            const annotationsList = annotationManager.getAnnotationsList();
            const annotsToDelete = [];
            const annotsToDraw = [];

            await Promise.all(
                annotationsList.map(async (annot, index) => {
                    let inputAnnot;
                    let field;

                    if (typeof annot.custom !== 'undefined') {
                        // create a form field based on the type of annotation
                        if (annot.custom.type === 'TEXT') {
                            field = new Annotations.Forms.Field(
                                annot.getContents() + Date.now() + index,
                                {
                                    type: 'Tx',
                                    value: annot.custom.value,
                                },
                            );
                            inputAnnot = new Annotations.TextWidgetAnnotation(field);
                        } else if (annot.custom.type === 'SIGNATURE') {
                            field = new Annotations.Forms.Field(
                                annot.getContents() + Date.now() + index,
                                {
                                    type: 'Sig',
                                },
                            );
                            inputAnnot = new Annotations.SignatureWidgetAnnotation(field, {
                                appearance: '_DEFAULT',
                                appearances: {
                                    _DEFAULT: {
                                        Normal: {
                                            data:
                                                'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAYdEVYdFNvZnR3YXJlAHBhaW50Lm5ldCA0LjEuMWMqnEsAAAANSURBVBhXY/j//z8DAAj8Av6IXwbgAAAAAElFTkSuQmCC',
                                            offset: {
                                                x: 100,
                                                y: 100,
                                            },
                                        },
                                    },
                                },
                            });
                        } else if (annot.custom.type === 'DATE') {
                            field = new Annotations.Forms.Field(
                                annot.getContents() + Date.now() + index,
                                {
                                    type: 'Tx',
                                    value: 'm-d-yyyy',
                                    // Actions need to be added for DatePickerWidgetAnnotation to recognize this field.
                                    actions: {
                                        F: [
                                            {
                                                name: 'JavaScript',
                                                // You can customize the date format here between the two double-quotation marks
                                                // or leave this blank to use the default format
                                                javascript: 'AFDate_FormatEx("mmm d, yyyy");',
                                            },
                                        ],
                                        K: [
                                            {
                                                name: 'JavaScript',
                                                // You can customize the date format here between the two double-quotation marks
                                                // or leave this blank to use the default format
                                                javascript: 'AFDate_FormatEx("mmm d, yyyy");',
                                            },
                                        ],
                                    },
                                },
                            );

                            inputAnnot = new Annotations.DatePickerWidgetAnnotation(field);
                        } else {
                            // exit early for other annotations
                            annotationManager.deleteAnnotation(annot, false, true); // prevent duplicates when importing xfdf
                            return;
                        }
                    } else {
                        // exit early for other annotations
                        return;
                    }

                    // set position
                    inputAnnot.PageNumber = annot.getPageNumber();
                    inputAnnot.X = annot.getX();
                    inputAnnot.Y = annot.getY();
                    inputAnnot.rotation = annot.Rotation;
                    if (annot.Rotation === 0 || annot.Rotation === 180) {
                        inputAnnot.Width = annot.getWidth();
                        inputAnnot.Height = annot.getHeight();
                    } else {
                        inputAnnot.Width = annot.getHeight();
                        inputAnnot.Height = annot.getWidth();
                    }

                    // delete original annotation
                    annotsToDelete.push(annot);

                    // customize styles of the form field
                    Annotations.WidgetAnnotation.getCustomStyles = function (widget) {
                        if (widget instanceof Annotations.SignatureWidgetAnnotation) {
                            return {
                                border: '1px solid #a5c7ff',
                            };
                        }
                    };
                    Annotations.WidgetAnnotation.getCustomStyles(inputAnnot);

                    // draw the annotation the viewer
                    annotationManager.addAnnotation(inputAnnot);
                    fieldManager.addField(field);
                    annotsToDraw.push(inputAnnot);
                }),
            );

            // delete old annotations
            annotationManager.deleteAnnotations(annotsToDelete, null, true);

            // refresh viewer
            await annotationManager.drawAnnotationsFromList(annotsToDraw);
            //  return
        await uploadForSigning();
    };


    const addField = (type, point = {}, name = '', value = '', flag = {}) => {
        if(!UploadedFile){
            setFileUploadError('Please upload a Pdf First!')
            retrurn
        }
        const { documentViewer, Annotations } = instance.Core;
        const annotationManager = documentViewer.getAnnotationManager();
        const doc = documentViewer.getDocument();
        const displayMode = documentViewer.getDisplayModeManager().getDisplayMode();
        const page = displayMode.getSelectedPages(point, point);
        if (!!point.x && page.first == null) {
            return; //don't add field to an invalid page location
        }
        const page_idx =
            page.first !== null ? page.first : documentViewer.getCurrentPage();
        const page_info = doc.getPageInfo(page_idx);
        const page_point = displayMode.windowToPage(point, page_idx);
        const zoom = documentViewer.getZoomLevel();

        var textAnnot = new Annotations.FreeTextAnnotation();
        textAnnot.PageNumber = page_idx;
        const rotation = documentViewer.getCompleteRotation(page_idx) * 90;
        textAnnot.Rotation = rotation;
        if (rotation === 270 || rotation === 90) {
            textAnnot.Width = 50.0 / zoom;
            textAnnot.Height = 250.0 / zoom;
        } else {
            textAnnot.Width = 250.0 / zoom;
            textAnnot.Height = 50.0 / zoom;
        }
        textAnnot.X = (page_point.x || page_info.width / 2) - textAnnot.Width / 2;
        textAnnot.Y = (page_point.y || page_info.height / 2) - textAnnot.Height / 2;

        textAnnot.setPadding(new Annotations.Rect(0, 0, 0, 0));
        textAnnot.custom = {
            type,
            value,
            flag,
            name: `${type}_`,
        };

        // set the type of annot
        textAnnot.setContents(textAnnot.custom.name);
        textAnnot.FontSize = '' + 20.0 / zoom + 'px';
        textAnnot.FillColor = new Annotations.Color(211, 211, 211, 0.5);
        textAnnot.TextColor = new Annotations.Color(0, 165, 228);
        textAnnot.StrokeThickness = 1;
        textAnnot.StrokeColor = new Annotations.Color(0, 165, 228);
        textAnnot.TextAlign = 'center';

        textAnnot.Author = annotationManager.getCurrentUser();

        annotationManager.deselectAllAnnotations();
        annotationManager.addAnnotation(textAnnot, true);
        annotationManager.redrawAnnotation(textAnnot);
        annotationManager.selectAnnotation(textAnnot);
    };
    const uploadForSigning = async () => {
        // upload the PDF with fields as AcroForm
        // const storageRef = storage.ref();
        // const referenceString = `docToSign/${uid}${Date.now()}.pdf`;
        // const docRef = storageRef.child(referenceString);
        // const { documentViewer, annotationManager } = instance.Core;
        // const doc = documentViewer.getDocument();
        // const xfdfString = await annotationManager.exportAnnotations({ widgets: true, fields: true });
        // const data = await doc.getFileData({ xfdfString });
        // const arr = new Uint8Array(data);
        // const blob = new Blob([arr], { type: 'application/pdf' });
        // docRef.put(blob).then(function (snapshot) {
        //   console.log('Uploaded the blob');
        // });

        // // create an entry in the database
        // const emails = assignees.map(assignee => {
        //   return assignee.email;
        // });
        // await addDocumentToSign(uid, email, referenceString, emails);
        // dispatch(resetSignee());
        // navigate('/');
        const { documentViewer, annotationManager } = instance.Core;
        const doc = documentViewer.getDocument();
        const xfdfString = await annotationManager.exportAnnotations({ widgets: true, fields: true });
        const data = await doc.getFileData({ xfdfString });
        const arr = new Uint8Array(data);
        const blob = new Blob([arr], { type: 'application/pdf' });
            loader(true)
            ApiClient.postFormData('api/upload/document', { file:blob }).then(res => {
                if (res.fileName) {
                    setdata({...data,fileUrl:res?.fileName})   
                    if(res?.fileName){
                        handleSubmit(res?.fileName);
                    }
                }
                loader(false)
            })
        const url = URL.createObjectURL(blob);

        // Trigger download
        // const link = document.createElement('a');
        // link.href = url;
        // link.download = 'modified.pdf';
        // document.body.appendChild(link);
        // link.click();
        // document.body.removeChild(link);
    };


    const dragOver = e => {
        e.preventDefault();
        return false;
    };

    const drop = (e, instance) => {
        const { docViewer } = instance;
        const scrollElement = docViewer.getScrollViewElement();
        const scrollLeft = scrollElement.scrollLeft || 0;
        const scrollTop = scrollElement.scrollTop || 0;
        setDropPoint({ x: e.pageX + scrollLeft, y: e.pageY + scrollTop });
        e.preventDefault();
        return false;
    };

    const dragStart = e => {
        e.target.style.opacity = 0.5;
        const copy = e.target.cloneNode(true);
        copy.id = 'form-build-drag-image-copy';
        copy.style.width = '250px';
        document.body.appendChild(copy);
        e.dataTransfer.setDragImage(copy, 125, 25);
        e.dataTransfer.setData('text', '');
    };

    const dragEnd = (e, type) => {
        addField(type, dropPoint);
        e.target.style.opacity = 1;
        document.body.removeChild(
            document.getElementById('form-build-drag-image-copy'),
        );
        e.preventDefault();
    };


    const handleSubmit = (file) => {
        let method = "post";
        let url = "api/contract/create";
        
        if (custom) url = "api/contract/create";
        let payload={type:"file",fileUrl:file}
        if (payload.id && !copy) {
            method = "put";
            url = "api/update/contract";
            if (custom) url = "api/update/contract";
        } else {
            delete payload.id;
        }
        if (custom) {
            if (!payload.id&&templateId) {
                payload['id'] = templateId;
            }
            payload['reseller'] =  resellerId;
            payload['resellerType']=resellerType;
            payload['internalName']=Name
            payload['externalName']=ExternalName
        }
        
        loader(true);
        ApiClient.allApi(url, payload, method).then((res) => {
            if (res.success) {
                Navigate.goBack()
                toast.success(res.message);
            }
            loader(false);
        });
    };

//     const getResellerTypes = () => {
//         ApiClient.get("api/categories/listing", {
//             catType: environment.resellerTypeId,
//         }).then((res) => {
//             if (res.success) {
//                 setResellerTypes(res.data);
//             }
//         });
//     };

//     useEffect(()=>{
// getResellerTypes()
//     },[])
    return (
        <div className={'prepareDocument'}>


            <div className='grid grid-cols-12 gap-4'>
                <div className='col-span-12 md:col-span-12 '>
                    <div className=''>
                        <h4 className='text-md text-black text-lg font-semibold'>Create Contract</h4>
                    </div>
                </div>

                <div className='col-span-12 md:col-span-8 '>
                    <div className='grid grid-cols-12 '>
                        <div className='col-span-12 '>
                            <div className='sidebar_lefts_pdf'>

                                <div className='links_side_pdfs shadow-box  p-6 bg-white rounded-lg '>

                                    <div className='grid grid-cols-12 gap-4'>

                                        <div className='col-span-12 md:col-span-12'>

                                            <div className='grid grid-cols-2 gap-4 mb-4'>
                                                <div >
                                                    <div className="col-span-12 md:col-span-12 lg:col-span-4">
                                                        <h6 className="text-typo text-sm font-medium">
                                                            Internal Name
                                                        </h6>
                                                    </div>
                                                    <div className="">
                                                        <input type='text' value={Name} className="mt-2 shadow-box bg-white w-full text-sm placeholder:text-gray-500 rounded-lg h-10 flex items-center gap-2 overflow-hidden px-2 !ring-[#71B55C] !outline-[#71B55C]" onChange={e => setName(e.target.value)} />
                                                    </div>
                                                    {Submitted && !Name ? <span className='text-red-600'>Internal Name is required</span> : null}
                                                </div>

                                                <div>
                                                    <div className="col-span-12 md:col-span-12 lg:col-span-4">
                                                        <h6 className="text-typo text-sm font-medium">
                                                            External Name
                                                        </h6>
                                                    </div>
                                                    <div className="">
                                                        <input type='text' value={ExternalName} className="mt-2 shadow-box bg-white w-full text-sm placeholder:text-gray-500 rounded-lg h-10 flex items-center gap-2 overflow-hidden px-2 !ring-[#71B55C] !outline-[#71B55C]" onChange={e => setExternalName(e.target.value)} />
                                                    </div>
                                                    {Submitted && !ExternalName ? <span className='text-red-600'>External Name is required</span> : null}
                                                </div>
                                            </div>

                                            <div>
                                                {/* <div className="col-span-12 md:col-span-12 lg:col-span-4">
                                                <h6 className="text-typo text-sm font-medium">
                                                    Reseller Type
                                                </h6>
                                            </div>
                                            <div className="col-span-12 md:col-span-12 lg:col-span-8">
                                                <SelectDropdown
                                                    displayValue="name"
                                                    placeholder=""
                                                    intialValue={resellerType}
                                                    result={(e) => {
                                                        setresellerType( e.value)
                                                    }}
                                                    theme="search"
                                                    id="sourceDropdown"
                                                    options={resellerTypes}
                                                />
                                            </div>
                                            {Submitted && !resellerType ? <span className='text-red-600'>Reseller Type is required</span> : null} */}
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>

                            <input type="file" ref={filePicker} style={{ display: 'none' }} />

                        </div>

                        <div className='col-span-12 shadow-box  p-6 bg-white rounded-lg mt-6'>
                            <div className="webviewer h-5" ref={viewer}></div>

                            <div>
                                <div className="justify-end flex items-center mt-4" >
                                    <button onClick={applyFields}
                                        accessibilityLabel="Create"
                                        className='!px-3 text-md font-normal hover:!text-white hover:no-underline text-white h-10 flex items-center justify-center gap-2 bg-[#71B55C] rounded-lg shadow-btn hover:opacity-80 transition-all focus:ring-2 ring-[#EDEBFC] disabled:bg-[#D0CAF6] disabled:cursor-not-allowed'>
                                        <MdCheck className='text-xl' /> Create
                                    </button>

                                </div>
                            </div>


                        </div>
                    </div>





                </div>
                <div className='col-span-12 md:col-span-4 sticky top-15'>
                    <div className='grid grid-cols-1 gap-4 mb-4 shadow-box p-6 bg-white rounded-lg stikcyclass '>
                        <div className='btns_one'>
                            <button className="btnupload border-2 border-dashed border-blue-500 rounded flex flex-col gap-y-2 items-center justify-center w-full h-32"
                                onClick={() => {
                                    if (filePicker) {
                                        filePicker.current.click();
                                    }
                                }}
                                accessibilityLabel="upload a document">
                                <GrDocumentPdf className='text-2xl' />
                                Upload a document

                            </button>
                            {!UploadedFile&&FileUploadError?<span className='text-red-600'>{FileUploadError}</span>:null}
                        </div>
                        <div className='signtur_btns'>

                            <div
                            //  draggable onDragStart={e => dragStart(e)} onDragEnd={e => dragEnd(e, 'TEXT')}
                            >

                                <button className="btnupload border-2 border-dashed border-blue-500 rounded flex flex-col gap-y-2 items-center justify-center w-full h-32"
                                    onClick={() => addField('TEXT')}
                                    accessibilityLabel="add text">

                                    <PiSignature className='text-2xl' />

                                    Add Text

                                </button>

                            </div>
                        </div>
                        <div className='signtur_btns'>

                            <div
                            //  draggable onDragStart={e => dragStart(e)} onDragEnd={e => dragEnd(e, 'SIGNATURE')}
                            >

                                <button className="btnupload border-2 border-dashed border-blue-500 rounded flex flex-col gap-y-2 items-center justify-center w-full h-32"
                                    onClick={() => addField('SIGNATURE')}
                                    accessibilityLabel="add signature">

                                    <PiSignature className='text-2xl' />

                                    Add signature

                                </button>

                            </div>
                        </div>

                   
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PrepareDocument;
