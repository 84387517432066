import React, { useState, useEffect } from "react";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import methodModel from "../../methods/methods";
import { Link, useNavigate, useParams } from "react-router-dom";
import Layout from "../../components/global/layout";
import { Tooltip } from "antd";
import FormControl from "../../components/common/FormControl";
import shared from "./shared";
import { useSelector } from "react-redux";
import DateRangePicker from "../../components/common/DateRangePicker";
import datepipeModel from "../../models/datepipemodel";

const AddEdit = () => {
    const { id } = useParams()
    const [form, setform] = useState({ id: '', name: '',completion:'',goal_id:'',task_id:'',assign_goal_id:'',start_date:'',completion_date:'',description:'', status: 'active' })


    const history = useNavigate()
    const [submitted, setSubmitted] = useState(false)
    const [goals, setGoals] = useState([])
    const [tasks, setTasks] = useState([])
    const user = useSelector((state) => state.user);
    const formValidation = [
        { key: 'name', required: true,message:'Status is Required' },
        // { key: 'status', required: true,message:'Status is Required' },
        { key: 'assign_goal_id', required: true,message:'Assigned To is Required' },
    ]

    const handleSubmit = (e) => {
        e.preventDefault()
        setSubmitted(true)
        let invalid = methodModel.getFormError(formValidation, form)
        if (invalid) return
        let method = 'post'
        let url = shared.addApi
        let value = {
            ...form,
            task_id:form.task_id||null,
            type:form.task_id?'sub':'main'
        }
        if (value.id) {
            method = 'put'
            url = shared.editApi
        } else {
            delete value.id
        }

        loader(true)
        ApiClient.allApi(url, value, method).then(res => {
            if (res.success) {
                // ToastsStore.success(res.message)
                history(`/${shared.url}`)
            }
            loader(false)
        })
    }

    useEffect(() => {
        if (id) {
            loader(true)
            ApiClient.getAll({url:shared.detailApi,params:{id},response:(res)=>{
                if (res.success) {
                    let value = res.data
                    let payload = form

                    Object.keys(payload).map(itm => {
                        payload[itm] = value[itm]
                    })

                    if(payload?.assign_goal_id?._id) payload.assign_goal_id=payload?.assign_goal_id?._id
                    if(payload?.task_id?._id) payload.task_id=payload?.task_id?._id
                    

                    payload.id = id
                    setform({
                        ...payload
                    })

                }
                loader(false)
            }})
        }

    }, [id])

    const getError = (key) => {
        return submitted ? methodModel.getError(key, form, formValidation)?.message : ''
    }

    const getGoals=()=>{
        ApiClient.get('api/goals',{addedBy:user._id}).then(res=>{
            if(res.success){
                setGoals(res.data)
            }
        })
    }

    const getTasks=()=>{
        ApiClient.get('api/tasks',{addedBy:user._id}).then(res=>{
            if(res.success){
                let data=res.data
                data=data.filter(itm=>itm.id!=id)
                setTasks(data)
            }
        })
    }

    useEffect(()=>{
        getGoals()
        getTasks()
    },[])

    return <>
        <Layout>
            <form onSubmit={handleSubmit}>
                <div className="pprofile1">


                    <div className='flex items-center mb-8'>
                        <Tooltip placement="top" title="Back">
                            <Link to={`/${shared.url}`} className="!px-4  py-2 flex items-center justify-center  rounded-lg shadow-btn hover:bg-[#6db56224] border transition-all  mr-3">
                                <span class="material-symbols-outlined">arrow_back</span>
                            </Link>
                        </Tooltip>
                        <div>
                            <h3 className="text-base font-medium text-[#0A0D14]">
                                {form && form.id ? 'Edit' : 'Add'}  {shared.addTitle}
                            </h3>
                            <p class="text-sm font-normal text-[#75757A]">Here you can see all about your {shared.addTitle}</p>
                        </div>
                    </div>



                    {/* <FormTabs list={tabs} value={tab} result={e=>setTab(e)}/> */}
                    <div className="grid grid-cols-12 gap-3">
                        <div className="md:col-span-6">
                            <FormControl
                                type="text"
                                label="Task Name"
                                value={form.name}
                                onChange={e => setform({ ...form, name: e })}
                                required
                            />
                        </div>

                        <div className="md:col-span-6">
                            <FormControl
                                type="select"
                                label="Assigned To"
                                displayValue="name"
                                placeholder="Select Project"
                                value={form.assign_goal_id}
                                onChange={e => setform({ ...form, assign_goal_id: e })}
                                options={goals}
                                theme="search"
                                required
                                error={getError('assign_goal_id')}
                            />
                        </div>

                        <div className="md:col-span-6">
                            <FormControl
                                type="select"
                                label="Task"
                                displayValue="name"
                                placeholder="Select Task"
                                value={form.task_id}
                                onChange={e => setform({ ...form, task_id: e })}
                                options={[
                                    {id:'',name:'Set as Main Task'},
                                    ...tasks
                                ]}
                                theme="search"
                            />
                        </div>

                        

                        <div className="md:col-span-6">
                            <label className="block xt-sm text-[#545454] text-[15px] mb-2">Start Date - Completion Date </label>
                            <DateRangePicker
                                value={{
                                    startDate: form.start_date,
                                    endDate: form.completion_date,
                                }}
                                isCompare={false}
                                placeholder="Start Date - Completion Date"
                                onChange={(e) => {
                                    setform({
                                        ...form,
                                        start_date: datepipeModel.datetostring(e.startDate),
                                        completion_date: datepipeModel.datetostring(e.endDate),
                                    })
                                }}
                                fullWidth={true}
                            />
                        </div>
                        <div className="md:col-span-6">
                        <FormControl
                                type="number"
                                label="% Completion"
                                value={form.completion}
                                max="100"
                                maxlength="3"
                                onChange={e => setform({ ...form, completion: e })}
                                required
                            />
                        </div>

                        <div className="col-span-12">
                            <FormControl
                                type="editor"
                                label="Description"
                                value={form.description}
                                onChange={e => setform({ ...form, description: e })}
                            />
                        </div>
                      
                    </div>


                    <div className="mt-4 text-right">

                        <button type="submit" className="text-white bg-gradient-to-br bg-[#71B55C] bg-[#71B55C] hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2">Save</button>
                    </div>
                </div>


            </form>
        </Layout>
    </>
}

export default AddEdit