import { useState } from "react";
import methodModel from "../../../methods/methods";
import SelectDropdown from "../SelectDropdown";
import "./style.scss";
import GooglePlaceAutoComplete from "../GooglePlaceAutoComplete";
import addressModel from "../../../models/address.model";
import ReactQuill from 'react-quill';

export default function FormControl({
  name,
  id = "",
  valueType = "string",
  onInputChange = (e) => {},
  inputValue = "",
  displayValue = "name",
  placeholder = "",
  type = "text",
  options = [],
  error,
  label,
  disabled = false,
  required = false,
  onChange = () => {},
  onPlaceChange = () => {},
  maxlength = "",
  minlength = "",
  min = "",
  className = "",
  value,
  theme = "",
}) {
  const [text, setText] = useState("");

  const add = () => {
    let arr = value || [];
    if (text) {
      arr.push(text);
    }
    onChange(arr);
    setText("");
  };

  const remove = (i) => {
    let arr = value || [];
    arr = arr.filter((itm, index) => index != i);
    onChange(arr);
  };

  const addItem = (v) => {
    let arr = value || [];
    let ext = arr?.find((itm) => itm == v);

    if (ext) {
      arr = arr.filter((itm) => itm != v);
    } else {
      arr.push(v);
    }

    onChange(arr);
  };

  return (
    <>
      <div className="relative mb-2 formWrapper">
        {label ? (
          <>
            <label className="block xt-sm text-[#545454] text-[15px] mb-2">
              {label}{" "}
              {required ? (
                <>
                  <span className="star">*</span>
                </>
              ) : (
                <></>
              )}
            </label>
          </>
        ) : (
          <></>
        )}

        {type == "select" ? (
          <SelectDropdown
            id={`statusDropdown_${id}`}
            displayValue={displayValue}
            className={className}
            valueType={valueType}
            onInputChange={onInputChange}
            inputValue={inputValue}
            placeholder={placeholder}
            intialValue={value || ""}
            name={name}
            theme={theme}
            result={(e) => {
              onChange(e.value);
            }}
            options={options}
            disabled={disabled}
            required={required}
          />
        ) :type=='address'?<>
        <GooglePlaceAutoComplete
                  value={value||''}
                  result={e=>{
                    if (e.place) {
                      let address = addressModel.getAddress(e.place)
                      onPlaceChange(address)
                    }else{
                      onChange(e.value||'')
                    }
                  }}
                  id={id}
                  placeholder="Type address"
                />
        </>: type == "number" ? (
          <input
            type="text"
            name={name}
            className="mt-2 border border-[#eee] bg-white w-full text-sm placeholder:text-gray-500 rounded-lg h-10 flex items-center gap-2 overflow-hidden px-2 !ring-[#71B55C] !outline-[#71B55C] placeholder:text-sm"
            required={required}
            placeholder={placeholder}
            value={value || ""}
            maxLength={maxlength}
            minLength={minlength}
            min={min}
            disabled={disabled}
            autoComplete="off"
            onChange={(e) => onChange(methodModel.isNumber(e))}
          />
        ) : type == "badge" ? (
          <>
            <div className="flex">
              <input
                type="text"
                className="relative flex items-center w-full h-10 gap-2 px-2 overflow-hidden bg-white rounded-lg shadow-box"
                placeholder={placeholder}
                value={text}
                autoComplete="off"
                onChange={(e) => setText(e.target.value)}
              />
              <button
                type="button"
                className="ml-2 btn btn-primary"
                onClick={add}
                disabled={disabled}
              >
                Add
              </button>
            </div>
            <div className="">
              {value?.map((itm, i) => {
                return (
                  <span className="m-1 badge badge-primary">
                    {itm}
                    <i
                      className="ml-1 fa fa-times"
                      onClick={() => remove(i)}
                    ></i>
                  </span>
                );
              })}
            </div>
          </>
        ) : type == "radio" ? (
          <>
            <div className="flex items-center mt-3 gap-x-4">
              {options.map((itm) => {
                return (
                  <div className="">
                    {" "}
                    <label
                      className={`border ${
                        value == itm.id
                          ? "text-black-700 bg-[#6db5623d] border-[#6db5623d] dark:border-blue-800 dark:text-black"
                          : "border-gray-300"
                      } px-6 py-2 rounded-lg flex cursor-pointer`}
                    >
                      <input
                        type="radio"
                        checked={value == itm.id ? true : false}
                        onChange={(e) => onChange(itm.id)}
                        className="mr-2 hide d-none"
                        name={name}
                        disabled={disabled}
                      />
                      {itm.name}
                    </label>
                  </div>
                );
              })}
            </div>
          </>
        ) : type == "editor" ? (
          <>
          
          <ReactQuill theme="snow" value={value} onChange={e=>{
            onChange(e);
          }} />
          </>
        ) : type == "checkbox" ? (
          <>
            {options.map((itm) => {
              return (
                <>
                  <label class="flex items-center mb-4">
                    <input
                      checked={value?.includes(itm.id) ? true : false}
                      onChange={(e) => addItem(itm.id)}
                      disabled={disabled}
                      type="checkbox"
                      value=""
                      class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 border dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <span class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                      {itm.name}
                    </span>
                  </label>
                </>
              );
            })}
          </>
        ) : (
          <input
            type={type}
            name={name}
            className="mt-2 mt-2 shadow-box bg-white w-full text-sm placeholder:text-gray-500 rounded-lg h-10 flex items-center gap-2 overflow-hidden px-2 !ring-[#71B55C] !outline-[#71B55C]"
            required={required}
            placeholder={placeholder}
            value={value || ""}
            maxLength={maxlength}
            minLength={minlength}
            min={min}
            disabled={disabled}
            onChange={(e) => {
              onChange(e.target.value);
            }}
            autoComplete="off"
          />
        )}

        {error ? (
          <>
            <div className="mt-1 text-danger small">{error}</div>
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
}
