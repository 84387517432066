const shared={
    check:'Task',
    title:'Tasks',
    addTitle:'Task',
    url:'task',
    addApi:'api/task',
    editApi:'api/task',
    detailApi:'api/task',
    listApi:'api/tasks',
    statusApi:'api/task',
    deleteApi:'api/task'
}

export default shared