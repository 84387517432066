const shared={
    check:'TourDetail',
    title:'Tour Details',
    addTitle:'Tour Detail',
    url:'tour-details',
    type:'tour-details',
    addApi:'api/tour',
    editApi:'api/tour',
    detailApi:'api/tour',
    listApi:'api/tours',
    statusApi:'api/tour',
    deleteApi:'api/tour'
}

export default shared