import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { FiX } from "react-icons/fi";

const Modal = ({ title = 'Header', closeiconShow=true, result=()=>{},isOpen=false,content=<>Body</>,submitText='',isHeader=true,isFooter=true})=>{

    const closeModal=()=>{
        result({event:'close'})
    }

    return <>
    <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-[9999999]" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div className="fixed inset-0 bg-black/40" />
          </Transition.Child>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95">
                <Dialog.Panel className="w-full max-w-2xl transition-all transform bg-white rounded-xl">
                  {isHeader?<>
                    <div className="flex items-center justify-between p-6 border-b">
                    <h4 className="text-2xl font-medium text-typo">
                      {title}
                    </h4>
                      {closeiconShow ?<button
                      type="button"
                      className="h-9 w-9 shrink-0 shadow-btn hover:shadow-none p-1 rounded-lg border border-gray-100 !text-[#71B55C]"
                      onClick={closeModal}>
                      <FiX className="w-full h-full" />
                    </button>:null}
                  </div>
                  </>:<></>}
                
                  <div className="p-6">
                  {content}
                  </div>
                  {isFooter?<>
                    <div className="flex items-center justify-end gap-3 p-6 border-t">
                    <button
                      type="button"
                      onClick={closeModal}
                      className="!px-4 text-[#3C3E49] text-sm font-normal py-2.5 flex items-center justify-center gap-2 bg-[#fff] rounded-lg shadow-btn hover:bg-[#6db56224] border border-[#D0D5DD] transition-all focus:ring-2 ring-[#F1F2F3] disabled:bg-[#F3F2F5] disabled:cursor-not-allowed">
                      Close
                    </button>
                    {submitText?<>
                    <button
                      type="button"
                      onClick={(e) => result({event:'submit'})}
                      className="!px-4 text-sm font-normal text-white h-11 flex items-center justify-center gap-2 bg-[#71B55C] rounded-lg shadow-btn hover:opacity-80 transition-all focus:ring-2 ring-[#EDEBFC] disabled:bg-[#D0CAF6] disabled:cursor-not-allowed">
                      {submitText}
                    </button>
                    </>:<></>}
                  </div>
                  </>:<></>}
                  
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
}

export default Modal