import React, { useEffect, useState } from 'react';
import Layout from '../../components/global/layout';
import './style.scss';
import { Link } from 'react-router-dom';
import { Tooltip } from "antd";
import { FiEdit3, FiPlus } from 'react-icons/fi';
import { BsTrash3 } from 'react-icons/bs';
import Table from "../../components/Table";
import { HiOutlineArrowDown, HiOutlineSearch } from "react-icons/hi";
import datepipeModel from '../../models/datepipemodel';
import shared from "./shared";
import SelectDropdown from '../../components/common/SelectDropdown';
import ApiClient from '../../methods/api/apiClient';
import pipeModel from '../../models/pipeModel';
const Html = ({
    user,
    sorting,
    edit,
    view,
    statusChange,
    pageChange,
    deleteItem,
    clear,
    filters,
    setFilter,
    filter,
    loaging,
    data,
    changestatus,
    isAllow,
    total = { total }
}) => {

    const status=[
        {id:'complete',html:<span className='inline-block px-3 py-1 text-xs border rounded'>Complete</span>},
        {id:'in-progress',html:<span className='inline-block px-3 py-1 text-xs border rounded'>In Progress</span>},
        {id:'not-started',html:<span className='inline-block px-3 py-1 text-xs border rounded'>Not Started</span>},
    ]

    const getStatus=(s='')=>{
        let ext=status.find(itm=>itm.id==s)
        return ext?ext.html:<span className='inline-block px-3 py-1 text-xs border rounded'>In Progress</span>
    }


    const columns = [
        {
            key: 'name', name: 'Task Name', sort: true,
            render: (row) => {
                return <span className='capitalize'>{row?.name}</span>
            }
        },
        {
            key: 'assign_goal_detail', name: 'Goal Name',   
            render: (row) => {
                return <span className='capitalize'>{row?.assign_goal_detail?.name||'--'}</span>
            }
        },
        {
            key: 'main_task_detail', name: 'Main Task',   
            render: (row) => {
                return <span className='capitalize'>{row?.main_task_detail?.name||'--'}</span>
            }
        },
        {
            key: 'start_date', name: 'Start Date',sort: true,
            render: (row) => {
                return <>
                    {datepipeModel.date(row?.start_date)}
                </>
            }
        },
        {
            key: 'completion_date', name: 'Completion Date',sort: true,
            render: (row) => {
                return <>
                    {datepipeModel.date(row?.completion_date)}
                </>
            }
        },
        {
            key: 'completion', name: 'Completion',sort: true,
            render: (row) => {
                return <>
                    {pipeModel.number(row.completion)}
                </>
            }
        },
        // {
        //     key: 'status', name: 'Status',
        //     render: (row) => {
        //         return <>
        //         <div className='flex gap-3'>
        //         <div className='flex items-center gap-2'>
        //                 <div class="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700 min-w-[90px]">
        //                     <div class="bg-[#71B55C] h-2.5 rounded-full w-[40%]"></div>
        //                 </div>
        //                 <span>40%</span>
        //             </div>
        //             {getStatus('in-progress')}
        //         </div>
                   

        //         </>
        //     }
        // },
        {
            key: 'action', name: 'Action',
            render: (itm) => {
                return <>
                    <div className="flex items-center justify-start gap-1.5">
                    {/* <Tooltip placement="top" title="View">
                                <a className="border cursor-pointer border-[#ff7641] hover:opacity-70 rounded-lg bg-[#ff764114] w-10 h-10 !text-[#71B55C] flex items-center justify-center text-xl" onClick={e => view(itm.id)}>
                                <span class="material-symbols-outlined">visibility</span>
                                </a>
                            </Tooltip> */}
                        {isAllow(`edit${shared.check}`) ?
                            <Tooltip placement="top" title="Edit">
                                <a className="border cursor-pointer border-[#ff7641] hover:opacity-70 rounded-lg bg-[#ff764114] w-10 h-10 !text-[#71B55C] flex items-center justify-center text-xl" onClick={e => edit(itm.id)}>
                                    <FiEdit3 />
                                </a>
                            </Tooltip>
                            : <></>}
                        {isAllow(`delete${shared.check}`) ? <Tooltip placement="top" title="Delete"> <span className='border cursor-pointer !border-[#E9253129] hover:opacity-70 rounded-lg bg-[#FDE9EA] w-10 h-10 text-[#E92531] flex items-center justify-center text-xl ' onClick={() => deleteItem(itm.id)}>
                            <BsTrash3 />
                        </span> </Tooltip> : <></>}
                    </div>
                </>
            }
        },
    ]

    const [goals,setGoals]=useState([])

    const getGoals=()=>{
        ApiClient.get('api/goals',{addedBy:user._id}).then(res=>{
            if(res.success){
                setGoals(res.data)
            }
        })
    }

    useEffect(()=>{
        getGoals()
    },[])


    return (
        <Layout>
            <div className="flex items-center justify-between">
                <div>
                   <h3 className="text-base font-medium text-[#0A0D14]"> {shared.title}</h3>
                    <p class="text-sm font-normal text-[#75757A]">Here you can see all about your {shared.title}</p>
                </div>

                <a id='downloadFile'></a>

                <div className="flex">


                    {/* <button className="!px-2.5 text-[#3C3E49] text-sm font-normal py-2.5 flex items-center justify-center gap-2 bg-[#fff] rounded-lg shadow-btn hover:bg-[#6db56224] border border-[#D0D5DD] transition-all focus:ring-2 ring-[#F1F2F3] disabled:bg-[#F3F2F5] disabled:cursor-not-allowed mr-3" onClick={() => exportfun()}>
                        <PiFileCsv className="text-xl text-typo" />  Export CSV
                    </button> */}

                    {isAllow(`add${shared.check}`) ?
                        <Link className="bg-[#71B55C] border border-solid border-[#27A376] shadow-[1px 2px 4px 0px #0d0d121f] py-[10px] px-[12px] leading-[20px] mr-[8px] flex items-center   hover:bg-[#71B55C] text-[14px]  rounded-[8px] text-white  font-medium hover:no-underline" to={`/${shared.url}/add`}>
                            <FiPlus className="text-xl text-white w-[18px] h-[18px] me-[4px]" />   Add {shared.addTitle}
                        </Link>
                        : <></>}
                </div>


            </div>



            <div className='shadow-box w-full bg-white rounded-[16px]  mt-6'>
                <div className='flex p-4'>
                    <div>
                    <div className="flex items-center gap-2">
            <form className="relative shadow-box bg-white min-w-[320px] rounded-lg h-10 flex items-center gap-2 overflow-hidden px-2" onSubmit={(e)=>{e.preventDefault();filter()}}>
              <HiOutlineSearch className="text-[#717275] text-xl shrink-0" />
              <input
                className="w-full h-full outline-none ring-0 p-0 placeholder:text-[#98A2B3] text-typo text-sm placeholder:font-light"
                placeholder="Search"
                value={filters.search}
                onChange={e => setFilter({ ...filters, search: e.target.value })}
              />
            </form>
            <button type="submit" className="bg-[#71B55C] border !border-[#71B55C] h-10 shadow-btn px-6 hover:opacity-80 text-sm text-white rounded-lg" onClick={e => filter()}>
              Search
            </button>
          </div>
                    </div>
                    <div className="flex gap-2 ml-auto">
                        <SelectDropdown
                            id="statusDropdown"
                            displayValue="name"
                            placeholder='All Goals'
                            intialValue={filters.assign_goal_id}
                            result={e => { filter({assign_goal_id:e.value}) }}
                            options={goals}
                        />
                        {filters.assign_goal_id ||filters.search? <>
                            <button
                                className="inline-block h-10 px-6 text-sm leading-10 text-white rounded-lg bg-[#71B55C] shadow-btn hover:opacity-80"
                                onClick={() => clear()}>
                                Reset
                            </button>
                        </> : <></>}


                    </div>
                </div>



                {!loaging ? <>
                    <Table
                        className='mb-3'
                        data={data}
                        columns={columns}
                        page={filters.page}
                        count={filters.count}
                        total={total}
                        result={(e) => {
                            if (e.event == 'page') pageChange(e.value)
                            if (e.event == 'sort') sorting(e.value)
                        }}
                    />

                </> : <></>}

                {
                    loaging ? <div className="flex items-center justify-center py-5 text-center">
                        <img src="/assets/img/loader.gif" className="pageLoader" />
                    </div> : <></>
                }

            </div>
        </Layout >
    );
};

export default Html;
