import datepipeModel from "../../../models/datepipemodel";
import SelectDropdown from "../SelectDropdown";

export default function MonthYearInput({month='',onChange=(e)=>{},year=''}){
    const curentDate=new Date()
    let months=datepipeModel.monthArray.map(itm=>{
        return {
            id:itm.id+1,
            name:itm.name
        }
    })


  const yearsList = (y) => {
    let year = y ? y : curentDate.getFullYear()
    if (year) year = Number(year)
    let years = []
    for (let i = 5; i > 0; i--) {
      let data = {
        name: year - i,
        id: year - i,
      }
      years.push(data)
    }
    for (let i = 0; i < 5; i++) {
      let data = {
        name: year + i,
        id: year + i,
      }
      years.push(data)
    }

    return years
  }

  const onMonthSelect=(e)=>{
    onChange({month:e.value,year:year||curentDate.getFullYear()})
  }

  const onYearSelect=(e)=>{
    onChange({year:e.value,month:e.value?(month||1):''})
  }


    return <>
    <div className="flex gap-3">
        <SelectDropdown
        intialValue={month}
        placeholder="Month"
        options={months}
        result={e=>{
          onMonthSelect(e)
        }}
        theme="search"
        />
         <SelectDropdown
        intialValue={year}
        placeholder="Year"
        options={yearsList(year)}
        result={e=>{
          onYearSelect(e)
        }}
        theme="search"
        />
    </div>
    </>
}