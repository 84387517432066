import { NavLink ,useLocation} from 'react-router-dom';

const NavTabs = ({ tabsData }) => {
  const location = useLocation();
  return (
    <ul className="flex gap-[20px] items-start border-b border-[#EAECF0] mb-6 w-full flex-wrap gap-y-4">
      {tabsData?.map((tabItem, index) => (
        <li key={index}>
          <NavLink
            to={tabItem.link}
            className={(isActive) => `pt-[1px] px-[4px] pb-[11px] block text-sm font-normal hover:!text-[#71B55C] hover:no-underline
             ${location.pathname==tabItem.link ? 'border-b-2 !border-[#71B55C] !text-[#71B55C] !font-medium' : '!text-[#667085]'}`}>
            {tabItem.title}
          </NavLink>
        </li>
      ))}
    </ul>
  );
};
export default NavTabs;