const status = [
    { id: 'VOIDED', name: 'Voided' },
    { id: 'PAID', name: 'Paid' },
    { id: 'AUTHORISED', name: 'Authorized' },
    { id: 'DELETED', name: 'Deleted' }
]



const xeroModel = { status }
export default xeroModel