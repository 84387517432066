import { useState } from "react"
import Layout from "../../components/global/layout"
import { useParams } from "react-router-dom"
export default function AddEditCoupon() {
    const {id}=useParams();
    const [formData, setformData] = useState({code: "",expiryDate: "",internalNotes: "",internalReference: "",issueDate: "",sourceOrder: "",status: "",value: 0,valueType: ""})

    const HandleFormSubmit=(e)=>{
        e.preventDefault();
console.log(formData,"This is the Submitted Data for the Coupons thats i think we want ---====-=-=-=-")
    }

    const FormChangeHandler=(e)=>{
        const {name,value}=e.target;
        setformData({...formData,[name]:value})
    }
  return (
    <div>
        <Layout>
              <form class=" mx-auto bg-white p-6 rounded-md  shadow-md" onSubmit={e=>HandleFormSubmit(e)}>
                  <h4 className=" font-medium text-xl mb-3">{!id?"Add":"Edit"} Coupon</h4>
                  <div className="grid grid-cols-2 gap-4">
              <div class="mb-4">
                  <label for="code" class="block text-gray-600 text-sm font-semibold mb-2">Code</label>
                          <input type="text" id="code" name="code" value={formData.code} onChange={FormChangeHandler} class="w-full p-2 border border-gray-300 rounded-md" required/>
              </div>

              <div class="mb-4">
                  <label for="expiryDate" class="block text-gray-600 text-sm font-semibold mb-2">Expiry Date</label>
                          <input type="date" id="expiryDate" onChange={FormChangeHandler} value={formData.expiryDate} name="expiryDate" class="w-full p-2 border border-gray-300 rounded-md" required/>
              </div>

              <div class="mb-4">
                  <label for="internalNotes" class="block text-gray-600 text-sm font-semibold mb-2">Internal Notes</label>
                          <input type="text" id="internalNotes" onChange={FormChangeHandler} value={formData.internalNotes} name="internalNotes" class="w-full p-2 border border-gray-300 rounded-md" required/>
              </div>

              <div class="mb-4">
                  <label for="internalReference" class="block text-gray-600 text-sm font-semibold mb-2">Internal Reference</label>
                          <input type="text" id="internalReference" onChange={FormChangeHandler} value={formData.internalReference} name="internalReference" class="w-full p-2 border border-gray-300 rounded-md" required/>
              </div>

              <div class="mb-4">
                  <label for="issueDate" class="block text-gray-600 text-sm font-semibold mb-2">Issue Date</label>
                          <input type="date" id="issueDate" name="issueDate" onChange={FormChangeHandler} value={formData.issueDate} class="w-full p-2 border border-gray-300 rounded-md" required/>
              </div>

              <div class="mb-4">
                  <label for="sourceOrder" class="block text-gray-600 text-sm font-semibold mb-2">Source Order</label>
                          <input type="text" id="sourceOrder" onChange={FormChangeHandler} name="sourceOrder" value={formData.sourceOrder} class="w-full p-2 border border-gray-300 rounded-md" required/>
              </div>

              <div class="mb-4">
                  <label for="status" class="block text-gray-600 text-sm font-semibold mb-2">Status</label>
                          <select id="status" name="status" onChange={FormChangeHandler} value={formData.status} class="w-full p-2 border border-gray-300 rounded-md" required>
                      <option value="ISSUED">ISSUED</option>
                  </select>
              </div>

              <div class="mb-4">
                  <label for="value" class="block text-gray-600 text-sm font-semibold mb-2">Value</label>
                          <input type="number" id="value" onChange={FormChangeHandler} name="value" class="w-full p-2 border border-gray-300 rounded-md" value={formData.value} required />
              </div>

              <div class="mb-4">
                  <label for="valueType" class="block text-gray-600 text-sm font-semibold mb-2">Value Type</label>
                          <select id="valueType" value={formData.valueType} name="valueType" onChange={FormChangeHandler} required class="w-full p-2 border border-gray-300 rounded-md">
                      <option value="VALUE_LIMITPRODUCT">VALUE_LIMITPRODUCT</option>
                  </select>
              </div>
</div>
              <div class="mt-6 pb-5 mb-3" >
                  <button style={{float:"right"}} type="submit" class="px-4 py-2 bg-[#6db562] text-white rounded-md hover:bg-blue-600">Submit</button>
              </div>

          </form>
          </Layout>
    </div>
  )
}
