import { useEffect, useState } from "react";
import Layout from "../../components/global/layout";
import { useNavigate } from "react-router-dom";
import { PiFileCsv } from "react-icons/pi";
import { HiOutlineArrowDown, HiOutlineSearch } from "react-icons/hi";
import loader from "../../methods/loader";
import environment from "../../environment";
import axios from "axios";
import ApiClient from "../../methods/api/apiClient";
import { useSelector } from "react-redux";
import pipeModel from "../../models/pipeModel";
import methodModel from "../../methods/methods";

const OPTIONS = [{ option: "Country of Origin" }, { option: "Option 1" }];

const RefundData = () => {
  const user=useSelector(state=>state.user);
  const [selected, setSelected] = useState(OPTIONS[0]);
  const history = useNavigate();
  const viewCustomerData = (id) => {
    history("/customerdetails/" + id);
  };


  const [Data, setData] = useState([]);
  const [datafilters, setdatafilters] = useState({ countryName: "", sortBy:"customerName asc", sorder: "asc",key:"customerName", "status": "CONFIRMED", email: [],page:1,count:50, search: "",supplier:user.supplier_id?user.supplier_id.id:user._id })
  const GetData = (p = {}) => {
    const newfilters = { ...datafilters, ...p, email: p.email ? p.email.toString() : datafilters.email.toString() }
    loader(true);

    const response=(res)=>{
      if (res.success) {
        setData(res.data)
      }
      loader(false);
    }
    if (user?.subRole && user?.subRole?.id == environment.SubRolePartner) {
      delete newfilters.supplier
      newfilters['partner'] = user?.id || user?._id
    }
    ApiClient.getAll({url:'api/refund/data',params:newfilters,response})
  }
  useEffect(() => {
    GetData()
  }, [])

  const HandleSubmit = (e) => {
    e.preventDefault();
    GetData();
  }




  const exportfun = async (e) => {
    e.preventDefault()
    const token = await localStorage.getItem("token");
    let newfilters = { ...datafilters,export_to_xls:'Y'}
    if(newfilters.email)newfilters.email=newfilters.email.toString()
    const req = await axios({
      method: "get",
      url: `${environment.api}api/customer/bookings-stats`,
      responseType: "blob",
      params:newfilters,
      body: { token: token }
    });
    var blob = new Blob([req.data], {
      type: req.headers["content-type"],
    });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `customerdata.xlsx`;
    link.click();
  }

  const clear=()=>{
    let f={
      search:''
    }
    filter(f)
  }

  const filter=(p={})=>{
    setdatafilters({ ...datafilters, ...p })
    GetData(p);
  }

  const sorting = (key) => {
    let sorder = "asc";
    if (datafilters.key == key) {
      if (datafilters.sorder == "asc") {
        sorder = "desc";
      } else {
        sorder = "asc";
      }
    }

    let sortBy = `${key} ${sorder}`;
    filter({ sortBy, key, sorder });
  };

  const viewOrder=(id)=>{
    history(`/orders/${id}`)
  }

  return (
    <Layout>
      <div className="flex items-center justify-between mb-6">
        <div className="flex flex-col gap-1">
          <h4 className="text-2xl font-semibold text-[#111827]">
            Refund Data
          </h4>
          <p className="text-sm font-normal text-[#75757A]">
            Here you can see all about your Refund Data
          </p>
        </div>
        <div className="flex gap-3">
          <button
            type="button"
            onClick={e => exportfun(e)}
            className="!px-2.5 text-[#3C3E49] text-sm font-normal py-2.5 flex items-center justify-center gap-2 bg-[#fff] rounded-lg shadow-btn hover:bg-[#6db56224] border border-[#D0D5DD] transition-all focus:ring-2 ring-[#F1F2F3] disabled:bg-[#F3F2F5] disabled:cursor-not-allowed">
            <PiFileCsv className="text-typo text-xl" />
            Export CSV
          </button>
          {/* <button
            type="button"
            className="!px-3 text-sm font-normal text-white h-11 flex items-center justify-center gap-2 bg-[#71B55C] rounded-lg shadow-btn hover:opacity-80 transition-all focus:ring-2 ring-[#EDEBFC] disabled:bg-[#D0CAF6] disabled:cursor-not-allowed">
            <FiPlus className="text-xl text-white" />
            Add New customer
          </button> */}
        </div>
      </div>
      <div className="bg-white shadow-box rounded-lg w-full">
        <div className="flex items-start gap-3 p-6">
          <div className="flex items-center gap-2">
            <form className="relative shadow-box bg-white min-w-[320px] rounded-lg h-10 flex items-center gap-2 overflow-hidden px-2" onSubmit={HandleSubmit}>
              <HiOutlineSearch className="text-[#717275] text-xl shrink-0" />
              <input
                className="w-full h-full outline-none ring-0 p-0 placeholder:text-[#98A2B3] text-typo text-sm placeholder:font-light"
                placeholder="Search"
                value={datafilters.search}
                onChange={e => setdatafilters({ ...datafilters, search: e.target.value })}
              />
            </form>
            <button type="submit" className="bg-[#71B55C] border !border-[#71B55C] h-10 shadow-btn px-6 hover:opacity-80 text-sm text-white rounded-lg" onClick={e => HandleSubmit(e)}>
              Search
            </button>
            {datafilters.search?<>
            <button type="button" onClick={clear}  className="bg-[#71B55C] border !border-[#71B55C] h-10 shadow-btn px-6 hover:opacity-80 text-sm text-white rounded-lg">
              Clear
            </button>
            </>:<></>}
          </div>
        </div>
        <div className="scrollbar w-full overflow-auto">
          <table className="w-full">
            <thead className="border-y border-[#EAECF0]">
              <tr className=" border-y border-[#EAECF0]">
              <th
               
                  scope="col"
                  className="text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-center bg-[#6db56214] !py-3">
                  Order Number
                </th>
                <th
                onClick={()=>sorting('customerName')}
                  scope="col"
                  className="cursor-pointer text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] !px-5 text-left bg-[#6db56214] !py-3">
                  Customer
                  <HiOutlineArrowDown className="shrink-0 inline text-sm ml-2.5" />
                </th>
                <th
                
                  scope="col"
                  className="cursor-pointer text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] !px-5 text-left bg-[#6db56214] !py-3">
                  Product Name
                </th>
                <th
                onClick={()=>sorting('refundAmount')}
                  scope="col"
                  className="text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-center bg-[#6db56214] !py-3">
                  Refund Amount
                  <HiOutlineArrowDown className="shrink-0 inline text-sm ml-2.5" />
                </th>
                {/* <th
                  scope="col"
                  className="text-[#82838B] !border-r-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-center bg-[#6db56214] !py-3">
                  Action
                </th> */}
              </tr>
            </thead>
            <tbody>
              {Data.map((item, index) => (<tr>
                <td className=" text-typo !text-[#71B55C] cursor-pointer !px-5 text-sm font-normal !py-3 !border text-center border-[#EAECF0]" onClick={e=>viewOrder(item.id)}>
                  {item.orderNumber}
                </td>
                <td className=" text-typo !px-5 text-sm font-normal !border-l-0 !py-3 !border text-center border-[#EAECF0]">
                  <div className="flex items-center gap-3.5">
                    <img
                      src={methodModel.userImg(item?.customer?.image)}
                      height="32px"
                      width="32px"
                      className="object-cover shrink-0 w-8 h-8 shadow-xs rounded-full"
                    />
                    <div>
                      <span className="block text-left">{item?.customer?.name}</span>
                      <span className="text-[#6B7280] font-normal text-sm block">
                        {item?.customer?.email}
                      </span>
                    </div>
                  </div>
                </td>
                <td className=" text-typo !px-5 text-sm font-normal !py-3 !border border-[#EAECF0]">
                  {item.productDetail?.name}
                </td>
                <td className=" text-typo !px-5 text-sm font-normal !py-3 !border text-center border-[#EAECF0]">
                  {pipeModel.currency(item.refundAmount,'',user.companyCurrencyFormat)}
                </td>
              
                {/* <td className=" text-typo !px-5 text-sm font-normal !border-r-0 !py-3 !border text-center border-[#EAECF0]">
                  <button
                    onClick={(e) => viewCustomerData(item?.customer?.email)}
                    className="bg-[#ffe9ea] mx-auto h-[30px] text-sm cursor-pointer hover:bg-[#71B55C] hover:!border-[#71B55C] transition-all duration-300 hover:text-white flex items-center !px-2 !gap-1 border border-[#EBEBEB] !rounded-md text-[#000]">
                    <GoEye /> View
                  </button>
                </td> */}
              </tr>))}
            </tbody>
          </table>
          {Data?.length==0?<div className="text-center p-3">No Data</div>:null}
        </div>
      </div>
    </Layout>
  );
};

export default RefundData;
