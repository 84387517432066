import React, { useEffect, useState } from 'react';
import Layout from '../../../components/global/layout';
import Pagination from "react-pagination-js";
import pipeModel from '../../../models/pipeModel';
import SelectDropdown from '../../../components/common/SelectDropdown';
import xeroModel from '../../../models/xero.model';
import datepipeModel from '../../../models/datepipemodel';
import Select from "react-select";
import { HiOutlineArrowDown, HiOutlineSearch } from 'react-icons/hi';
import { GoEye } from 'react-icons/go';
import { FiFilter } from 'react-icons/fi';
import DateRangePicker from '../../../components/common/DateRangePicker';
import { XeroTabs, colourStyles } from '../../../utils/constants';
import moment from 'moment';
import NavTabs from '../../../components/global/NavTabs';
import { MdClear } from 'react-icons/md';
import PaginationTextView from '../../../components/common/PaginationTextView';

const Html = ({
  reseller,
  handleReseller,
  resellerValue,
  getData,
  sortClass,
  sorting,
  clear,
  isClear,
  user,
  edit,
  view,
  ChangeStatus,
  statusChange,
  pageChange,
  filters,
  CategoryValueChanger,
  filter,
  setFilter,
  loaging,
  data,
  xeroType,
  ChangeType,
  total = { total },
  SustainableCategories
}) => {
  
  return (
    <Layout>
      {/* <NavTabs tabsData={XeroTabs} /> */}
      <div className="flex items-center justify-between mb-6">
        <div className="flex flex-col gap-1">
          <h4 className="text-2xl font-semibold text-[#111827]">
            Bills to Pay
          </h4>
          <p className="text-sm font-normal text-[#75757A]">
            Here you can see all about your Bills
          </p>
        </div>
      </div>

      <div className="bg-white shadow-box rounded-lg w-full">
        <div className="flex items-start justify-between gap-3 p-6 max-[1535.98px]:flex-wrap">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              filter();
            }}
            className="flex items-center !gap-2">
            <div className="relative shadow-box bg-white min-w-[100px] lg:min-w-[120px] xl:min-w-[160px] 2xl:min-w-[340px] rounded-lg h-10 flex items-center gap-2 overflow-hidden px-2">
              <HiOutlineSearch className="text-[#717275] text-xl shrink-0" />
              <input
                className="w-full h-full outline-none ring-0 p-0 placeholder:text-[#98A2B3] text-typo text-sm placeholder:font-light"
                value={filters.search}
                onChange={(e) =>
                  setFilter({ ...filters, search: e.target.value })
                }
                placeholder="Search"
              />
            </div>
            <button className="!bg-[#71B55C] h-10 shadow-btn px-6 hover:opacity-80 text-sm text-white rounded-lg">
              Search
            </button>
          </form>
          
          <div className="flex !gap-2 items-center flex-wrap">
                <div className='lg:w-50 xl:w-50 2xl:w-80'>
                      <DateRangePicker
                            className="border"
                            placeholder="Select Date"
                            value={{
                                startDate: filters.start_date,
                                endDate: filters.end_date,
                            }}
                            onChange={(e) =>{ setFilter({...filters,start_date:e.startDate,end_date:e.endDate});
                          if(e.startDate&&e.endDate){
                              getData({ start_date: e.startDate, end_date: e.endDate })
                          }
                          }}
                      />
                </div>
                    <SelectDropdown
                      id="status"
                      displayValue="name"
                      placeholder='All Status'
                      intialValue={filters.status}
                      result={(e) => {
                        ChangeStatus(e.value);
                      }}
                      options={xeroModel.status}
                    />
                    <SelectDropdown
                    id="Select Account"
                    displayValue="name"
                    placeholder='All Accounts'
                    intialValue={filters.AccountID}
                    result={(e) => {
                      setFilter({...filters,AccountID:e.value});
                      getData({AccountID:e.value})
                    }}
                    options={user?.xero_accounts}
                  />
                  {/* <SelectDropdown
                    id="status"
                    displayValue="name"
                    placeholder="Invoice Type"
                    intialValue={filters.type}
                    result={(e) => {
                      ChangeType(e.value);
                    }}
                    options={xeroType}
                  /> */}
                  {isClear() ? (
                    <>
                      <button
                        className="!px-3 text-sm font-normal hover:!text-white hover:no-underline text-white h-10 flex items-center justify-center gap-2 !bg-[#71B55C] rounded-lg shadow-btn hover:opacity-80 transition-all focus:ring-2 ring-[#EDEBFC] disabled:bg-[#D0CAF6] disabled:cursor-not-allowed"
                        onClick={() => clear()}>
                        <MdClear className="text-xl text-white" />
                        Clear
                      </button>
                    </>
                  ) : (
                    <></>
                  )}
          </div>
        </div>
             <div className="table_rowings mx-2">
              <p className="text-gray-600 pb-2 text-sm"> {PaginationTextView(filters.page,filters.count,total)}</p>
          </div>
        <div className="scrollbar w-full overflow-x-auto overflow-y-visible">
          <table className="w-full ">
            <thead className="border-y border-[#EAECF0]">
              <tr className="border-y border-[#EAECF0]">
                <th
                  scope="col"
                  className="text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-left bg-[#F7FAFF] !py-3">
                  Issue Date
                </th>
                <th
                  scope="col"
                  className="text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-left bg-[#F7FAFF] !py-3 cursor-pointer"
                  onClick={(e) => sorting("invoiceNumber")}>
                  Invoice Number
                  <HiOutlineArrowDown className="shrink-0 inline text-sm" />
                </th>
                <th
                  scope="col"
                  className="text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-left bg-[#F7FAFF] !py-3">
                  Invoice Type
                </th>
                <th
                  scope="col"
                  className="text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-left bg-[#F7FAFF] !py-3">
                  Reference
                </th>
                <th
                  scope="col"
                  className="text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-left bg-[#F7FAFF] !py-3">
                  Name of Company
                </th>
                {/* <th scope="col" className='text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-left bg-[#F7FAFF] !py-3 cursor-pointer' onClick={e => sorting('contactName')}>
                  Contact Name
                  <HiOutlineArrowDown className="shrink-0 inline text-sm" />
                </th> */}
                {/* <th scope="col" className='text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-left bg-[#F7FAFF] !py-3 cursor-pointer' onClick={e => sorting('amountCredited')}>
                  Amount Credited
                  <HiOutlineArrowDown className="shrink-0 inline text-sm" />
                </th> */}
                {/* <th scope="col" className='text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-left bg-[#F7FAFF] !py-3 cursor-pointer' onClick={e => sorting('amountDue')}>
                  Amount Due
                  <HiOutlineArrowDown className="shrink-0 inline text-sm" />
                </th> */}
                {/* <th
                  scope="col"
                  className="text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-left bg-[#F7FAFF] !py-3">
                  Category
                </th> */}
                <th
                  scope="col"
                  className="text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-left bg-[#F7FAFF] !py-3">
                  Sustainability Category
                </th>
                <th
                  scope="col"
                  className="text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-left bg-[#F7FAFF] !py-3">
                  Description
                </th>
                <th
                  scope="col"
                  className="text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-left bg-[#F7FAFF] !py-3">
                  Due Date
                </th>
                <th
                  scope="col"
                  className="text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-left bg-[#F7FAFF] !py-3">
                  Sub Total
                </th>
                <th
                  scope="col"
                  className="text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-left bg-[#F7FAFF] !py-3">
                  Taxes
                </th>
                <th
                  scope="col"
                  className="text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-left bg-[#F7FAFF] !py-3">
                  Total
                </th>
                <th
                  scope="col"
                  className="text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-left bg-[#F7FAFF] !py-3 cursor-pointer"
                  onClick={(e) => sorting("amountPaid")}>
                  Amount Paid
                  <HiOutlineArrowDown className="shrink-0 inline text-sm" />
                </th>
                <th
                  scope="col"
                  className="text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-left bg-[#F7FAFF] !py-3">
                  Status
                </th>
                {/* <th
                  scope="col"
                  className="text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-left bg-[#F7FAFF] !py-3">
                  Reseller Name
                </th> */}
                <th
                  scope="col"
                  className="text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-left bg-[#F7FAFF] !py-3">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {!loaging &&
                data &&
                data.map((itm, i) => {
                  return (
                    <tr key={i}>
                      <td className="!text-typo  !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]">
                        {datepipeModel.date(
                          itm?.dateString,
                          user?.companyDateFormat
                        )}
                      </td>
                      <td
                        className="!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]"
                        onClick={(e) => view(itm.id)}>
                        {itm.invoiceNumber}
                      </td>
                      <td className="!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]">
                        {itm?.type || "--"}
                      </td>
                      <td className="!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]">
                        {itm?.reference || "--"}
                      </td>
                      <td className="!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]">
                        {itm?.contact?.Name}
                      </td>
                      {/* <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]' onClick={e => view(itm.id)}>{itm.contact.Name}</td> */}
                      {/* <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'>{pipeModel.currency(itm.amountCredited, '$', user.companyCurrencyFormat)}</td> */}
                      {/* <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'>{pipeModel.currency(itm.amountDue, '$', user.companyCurrencyFormat)}</td> */}
                      {/* <td className="!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]">
                        {itm?.lineItems?.[0]?.AccountDetail?.Name}
                      </td> */}

                        <td className="!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0] overflow-visible "  >

                       <div className=''>
                          <div className=''>
                          <SelectDropdown
                        options={SustainableCategories}
                        placeholder="Select Categories"
                        displayValue="name"
                        intialValue={itm?.category_id}
                        result={e=>CategoryValueChanger(e?.value,i)}
                        />
                       </div>
                          </div>

                      </td>
                      <td className="!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]">
                        <span className="list_product">
                          {itm?.lineItems?.[0]?.Description || "--"}
                        </span>
                      </td>
                      <td className="!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]">
                        {datepipeModel.date(
                          itm?.dueDate,
                          user?.companyDateFormat
                        )}
                      </td>
                      <td className="!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]">
                        {pipeModel.currency(
                          itm?.subTotal,
                          "",
                          user.companyCurrencyFormat
                        ) || "--"}
                      </td>
                      <td className="!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]">
                        {pipeModel.currency(
                          itm?.totalTax,
                          "",
                          user.companyCurrencyFormat
                        ) || "--"}
                      </td>
                      <td className="!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]">
                        {pipeModel.currency(
                          itm?.total,
                          "",
                          user.companyCurrencyFormat
                        ) || "--"}
                      </td>
                      <td className="!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]">
                        {pipeModel.currency(
                          itm.amountPaid,
                          "$",
                          user.companyCurrencyFormat
                        )}
                      </td>
                      <td className="!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]">
                        <div onClick={() => statusChange(itm)}>
                          {itm.status === "AUTHORISED" ? (
                            <span className="bg-[#ECF4EF] w-fit text-xs !px-3 h-[30px] flex items-center justify-center border !border-[#94D5AE] text-[#11A74D] !rounded">
                              {itm.status}
                            </span>
                          ) : (
                            <>
                              {itm.status === "DELETED" ? (
                                <sapn className="bg-[#FDE9EA] w-fit text-xs !px-3 h-[30px] flex items-center justify-center border !border-[#E9253129] text-[#E92531] !rounded">
                                  {itm.status}
                                </sapn>
                              ) : (
                                <>
                                  <span className="bg-[#71B55C]/20 w-fit text-xs !px-3 h-[30px] flex items-center justify-center border !border-[#71B55C]/40 !text-[#71B55C] !rounded">
                                    {itm.status}
                                  </span>
                                </>
                              )}
                            </>
                          )}
                        </div>
                      </td>
                      {/* <td className="!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]">
                        <Select
                          styles={colourStyles}
                          options={reseller}
                          placeholder="All Resellers"
                          value={resellerValue(itm?.reseller_id)}
                          isClearable={true}
                          name="Reseller"
                          onChange={(e) => handleReseller(e?.value, itm)}
                        />
                      </td> */}
                      <td className="!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]">
                        <button
                          onClick={() => view(itm.id)}
                          className="bg-[#FAFAFA] h-[30px] text-sm cursor-pointer hover:!bg-[#71B55C] hover:!border-[#71B55C] transition-all duration-300 hover:text-white flex items-center !px-2 !gap-1 border !border-[#EBEBEB] !rounded-md text-[#000]">
                          <GoEye /> View
                        </button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>

        {!loaging && total == 0&&!user?.xero_invoices_syncing ? (
           <div className="no-data-found text-center p-5"><img src="/assets/img/no-data-f.png" className="w-[100px] mx-auto	opacity-50	"/><p className="uppercase	text-sm tracking-widest ">No Data Found</p></div>
        ) : (
          <></>
        )}

        {total == 0&&user?.xero_invoices_syncing?<div className="py-3 text-center">Xero Bills data is syncing</div>:null}
        {!loaging && total > filters.count ? (
          <div className="">
            <div className="paginationWrapper p-6 flex items-center justify-between">
            <div className="table_rowings mx-2">
            <p className="text-gray-600  text-md"> Showing 10 Records of 100 Records</p>
          </div>
              <Pagination
                currentPage={filters.page}
                totalSize={total}
                sizePerPage={filters.count}
                changeCurrentPage={pageChange}
              />
            </div>
            </div>
        ) : (
          <></>
        )}
      </div>

      {loaging ? (
        <div className="loaderDiv text-center py-4">
          <img
            src="/assets/img/loader.gif"
            width="auto"
            height="auto"
            className="pageLoader"
          />
        </div>
      ) : (
        <></>
      )}
    </Layout>
  );
};

export default Html;
