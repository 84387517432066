import { useEffect, useState } from "react";
import {
    useNavigate,
    useParams
} from "react-router-dom";
import Layout from "../../components/global/layout";
import ApiClient from "../../methods/api/apiClient";
import datepipeModel from "../../models/datepipemodel";
import Breadcrumb from "../../components/common/Breadcrumb";
import { useSelector } from "react-redux";
import { IoChevronBackOutline } from "react-icons/io5";
import loader from "../../methods/loader";

const CouponDetail = () => {
    const user = useSelector((state) => state.user);
    const { id } = useParams();
    const history = useNavigate();
    const [data, setdata] = useState();

    const back = () => {
        history(-1);
    };

    useEffect(() => {
        getCouponsDetails();
    }, []);

    const getCouponsDetails = () => {
        loader(true);
        ApiClient.get(`api/voucher/detail?id=${id}`).then((res) => {
            if (res.success) {
                setdata(res.data);
            }
            loader(false);
        });
    };

    const ValueTypeReturner = (value) => {
        let newvalue = value.toLowerCase();
        if (newvalue.includes('percent')) {
            return '%'
        } else {
            return '$'
        }
    }
    return (
        <Layout>
            <Breadcrumb
                links={[
                    {
                        name: "Coupons Listing",

                        link: "/coupons-data",
                    },
                ]}
                currentPage={"Coupon Details"}
            />
            <div className="max-w-[1024px]">
                <div className="flex items-center justify-between my-6">
                    <div className="flex flex-col gap-1">
                        <h4 className="text-2xl font-semibold text-[#111827]">
                            Coupon Detail
                        </h4>
                        <p className="text-base font-normal text-[#75757A]">
                            Here you can see all about your product statistics
                        </p>
                    </div>
                    <div className="flex gap-3">
                        <button
                            onClick={(e) => back()}
                            type="button"
                            className="!px-3 text-base font-normal text-white h-11 flex items-center justify-center gap-2 bg-[#71B55C] rounded-lg shadow-btn hover:opacity-80 transition-all focus:ring-2 ring-[#EDEBFC] disabled:bg-[#D0CAF6] disabled:cursor-not-allowed">
                            <IoChevronBackOutline className="text-xl text-white" />
                            Back
                        </button>
                    </div>
                </div>
                <div className="bg-white shadow-box rounded-lg w-full p-6">
                    <div className="grid grid-cols-4 !gap-5">
 
                        <div>
                            <p className="text-[#75757A] text-sm font-normal mb-1.5">
                                Code
                            </p>
                            <h4 className="text-base shadow-box bg-gray-100 rounded-lg h-11 flex items-center gap-2 overflow-hidden !px-3">
                                {data?.code}
                            </h4>
                        </div>
                        <div className="col-span-3">
                            <p className="text-[#75757A] text-sm font-normal mb-1.5">Internal Notes</p>
                            <h4 className="text-base shadow-box bg-gray-100 rounded-lg h-11 flex items-center gap-2 overflow-hidden !px-3">
                                {data?.internalNotes || "--"}
                            </h4>
                        </div>

                        <div className="col-span-2">
                            <p className="text-[#75757A] text-sm font-normal mb-1.5">
                                Value 
                            </p>
                            <h4 className="text-base shadow-box bg-gray-100 rounded-lg h-11 flex items-center gap-2 overflow-hidden !px-3">
                                {ValueTypeReturner(data?.valueType || '')}{data?.value}
                            </h4>
                        </div>
                        <div className="col-span-2">
                            <p className="text-[#75757A] text-sm font-normal mb-1.5">
                                Value Type
                            </p>
                            <h4 className="text-base shadow-box bg-gray-100 rounded-lg h-11 flex items-center gap-2 overflow-hidden !px-3">
                                {data?.valueType}
                            </h4>
                        </div>
                        
                        <div className="col-span-2">
                            <p className="text-[#75757A] text-sm font-normal mb-1.5">
                                Issue Date
                            </p>
                            <h4 className="text-base shadow-box bg-gray-100 rounded-lg h-11 flex items-center gap-2 overflow-hidden !px-3">
                                {datepipeModel.date(data?.issueDate, user?.companyDateFormat)}
                            </h4>
                        </div>
                        <div className="col-span-2">
                            <p className="text-[#75757A] text-sm font-normal mb-1.5">
                                Expiry Date
                            </p>
                            <h4 className="text-base shadow-box bg-gray-100 rounded-lg h-11 flex items-center gap-2 overflow-hidden !px-3">
                                {datepipeModel.date(data?.expiryDate, user?.companyDateFormat)}
                            </h4>
                        </div>
                        <div className="col-span-2">
                            <p className="text-[#75757A] text-sm font-normal mb-1.5">
                                Status
                            </p>
                            <h4 className="text-base shadow-box bg-gray-100 rounded-lg h-11 flex items-center gap-2 overflow-hidden !px-3">
                                {data?.status || "---"}
                            </h4>
                        </div>

                        <div className="col-span-2">
                            <p className="text-[#75757A] text-sm font-normal mb-1.5">
                                Internal Reference
                            </p>
                            <h4 className="text-base shadow-box bg-gray-100 rounded-lg h-11 flex items-center gap-2 overflow-hidden !px-3">
                                {data?.internalReference||"---"}
                            </h4>
                        </div>
                   
                    </div>
                </div>
            </div>
        </Layout>
    );
};
export default CouponDetail;
