import React from "react";
// import { GoDotFill } from "react-icons/go";

const Badge = ({ label }) => {
  return (
    <div className="h-[26px] px-2.5 flex gap-1.5 justify-center items-center !rounded-md bg-[#6db56236]  w-fit border border-[rgba(57,124,246,0.10)]">
      <div className="2xl:w-1.5 2xl:h-1.5 w-[5px] h-[5px] shrink-0 bg-[#71B55C]" />
      <p className="text-sm font-normal  text-[#555]">{label}</p>
    </div>
  );
};

export default Badge;
