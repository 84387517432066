import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ApiClient from '../../../methods/api/apiClient';
import loader from '../../../methods/loader';
import Html from './html';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import environment from '../../../environment';
import moment from 'moment';
import { Dialog, Transition } from '@headlessui/react';
import { TbPlugConnected } from 'react-icons/tb';

const XeroBills = () => {
    const user = useSelector(state => state.user)
    const [ShowSyncModal, setShowSyncModal]=useState(false)
    const [SustainableCategories, setSustainableCategories] = useState([])
    const searchState = useSelector((state) => state.search);
    const [filters, setFilter] = useState({ page: 1, count: 50, search: '', catType: '', type: '' })
    const [data, setData] = useState([])
    const [total, setTotal] = useState(0)
    const [loaging, setLoader] = useState(true)
    const history = useNavigate()
    const [reseller, setreseller] = useState()

    const xeroType = [
        {id: 'ACCPAY', name: 'Account Payable'},
        {id: 'ACCREC', name: 'Account Receivable'},
    ]


    const GetSustainableCategories = () => {
        loader(true);
        const response = (res) => {
            if (res.success) {
                const data = res.data;
                setSustainableCategories(data.map((item) => {
                    return { name: item?.name, id: item?.id }
                }))
            }
            loader(false);
        }
        const filter = { catType: environment.sustainableId, parentCategory: '', status: 'active' }
        ApiClient.getAll({ url: 'api/categories/listing', response: response, params: filter })

    }
    useEffect(() => {
        getResellerData()
        GetSustainableCategories()
    }, [])

    const getResellerData = () => {
        ApiClient.get(`api/reseller/listing?status=active`).then(res => {
            if (res.success) {
                setreseller(res.data.map(item => {
                    return ({ value: item?.id, label: item?.name })
                }))
            }
        })
    }

    const filter = (p = {}) => {
        setFilter({ ...filters, page: 1, ...p })
        getData({ page: 1, ...p })
    }

    const sortClass = (key) => {
        let cls = 'fa-sort'
        if (filters.key == key && filters.sorder == 'asc') cls = 'fa-sort-up'
        else if (filters.key == key && filters.sorder == 'desc') cls = 'fa-sort-down'
        return 'fa ' + cls
    }

    const sorting = (key) => {
        let sorder = 'asc'
        if (filters.key == key) {
            if (filters.sorder == 'asc') {
                sorder = 'desc'
            } else {
                sorder = 'asc'
            }
        }
        let sortBy = `${key} ${sorder}`;
        filter({ sortBy, key, sorder })
    }

    useEffect(() => {
        if (user && user.loggedIn) {
            setFilter({ ...filters, search: searchState.data })
            getData({ search: searchState.data, page: 1 })
        }
    }, [searchState])

    const getData = (p = {},apiCall=false,showload=false) => {
        if(!showload){
        setLoader(true)
        }
        let filter = { ...filters, ...p, addedBy: user.supplier_id ? user.supplier_id.id : user._id, type:'ACCPAY'}
        if(filter.start_date){
            filter.start_date=moment(new Date(filter.start_date)).format('YYYY-MM-DD')
        }
        if(filter.end_date){
            filter.end_date = moment(new Date(filter.end_date)).format('YYYY-MM-DD')
        }
        ApiClient.get('api/xero/invoices', filter,'','',{apiCall:apiCall}).then(res => {
            if (res.success) {
                setData(res.data.map(itm => {
                    itm.id = itm._id
                    return itm
                }))
                if (res.total == 0 && user?.xero_invoices_syncing) {
                    swal({
                        title: "Xero Bills are syncing now ",
                        icon: "warning",
                        allowOutsideClick: false, // 
                    })
                }
                setTotal(res.total)
            }
            setLoader(false)
        })
    }

    const clearKey = {
        search: '',
        status: '',
        type: '',
        start_date:'',
        end_date:'',
        AccountId:''
    }
    const clear = () => {
        let f = clearKey
        Object.keys(f).map(itm => {
            f[itm] = ''
        })
        setFilter({ ...filters, page: 1, ...f })
        getData({ search: '', page: 1, ...f })
    }

    const isClear = () => {
        let value = false
        Object.keys(clearKey).map(itm => {
            if (filters[itm]) value = true
        })
        return value
    }

    const pageChange = (e) => {
        setFilter({ ...filters, page: e })
        getData({ page: e })
    }

    const ChangeStatus = (e) => {
        setFilter({ ...filters, status: e, page: 1 })
        getData({ status: e, page: 1 })
    }

    const statusChange = (itm) => {
        let modal = 'category'
        let status = 'active'
        if (itm.status == 'active') status = 'deactive'
        if (window.confirm(`Do you want to ${status == 'active' ? 'Activate' : 'Deactivate'} this`)) {
            loader(true)
            ApiClient.put(`api/pos/status/change`, { id: itm.id, status }).then(res => {
                if (res.success) {
                    getData()
                }
                loader(false)
            })
        }
    }
    const view = (id) => {
        history("/accounting/bill/" + id)
    }

    const edit = (id) => {
        history(`/pos/edit/${id}`)
    }

    const handleReseller = (e, itm) => {
        let payload = {
            reseller_id: e || null,
            contact_name: itm?.contactName
        }
        ApiClient.put(`api/xero/reseller-category`, payload).then(res => {
            if (res.success) {
        
                getData()
            }
        })
    }

    const resellerValue = (e) => {
        if (e) {
            let value = reseller && reseller.find(item => item?.value == e)
            return value || ''
        } else {
            return ''
        }
    }

    const ChangeType=(e)=>{
        setFilter({ ...filters, type: e, page: 1 })
        getData({ type: e, page: 1 })
    }
    
    const CategoryValueChanger = (value, index) => {
        const Updateddata = data;
        Updateddata[index]['category_id'] = value;
        setData([...Updateddata])
        ApiClient.put(`api/xero/reseller-category`, { category_id: value, contact_name: Updateddata[index].contact?.Name }).then(res => {
            getData({}, true,true)
        })

    }
    return <><Html
        isClear={isClear}
        CategoryValueChanger={CategoryValueChanger}
        user={user}
        filter={filter}
        view={view}
        setFilter={setFilter}
        sorting={sorting}
        sortClass={sortClass}
        edit={edit}
        clear={clear}
        ChangeStatus={ChangeStatus}
        pageChange={pageChange}
        filters={filters}
        loaging={loaging}
        data={data}
        total={total}
        statusChange={statusChange}
        reseller={reseller}
        SustainableCategories={SustainableCategories}
        handleReseller={handleReseller}
        resellerValue={resellerValue}
        xeroType={xeroType}
        ChangeType={ChangeType}
        getData={getData}
    />
        <Transition.Root show={ShowSyncModal} as={Fragment} >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" onClick={() => setShowSyncModal(true)}>

                <Dialog as="div" className="relative z-10" onClose={() => setShowSyncModal(false)}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className=" relative transform  rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                                    <div className="py-5 border-b text-center">
                                        <img src="/assets/img/logo.webp" className="inline-block w-[200px]" />
                                    </div>
                                    <div className="bg-white px-4 pb-4  sm:p-6 sm:pb-4 ">
                                        <div className="sm:flex sm:items-start sm:justify-center ">
                                            <div className="mt-3 text-center flex flex-col gap-y-3  sm:mt-0 sm:text-left sm:w-full">
                                                <div className='text-center mb-3 flex items-center bg-gray-100 mx-auto shadow-lg rounded-full h-16 w-16 p-2  justify-center'>
                                                    <TbPlugConnected className='text-[40px]' />
                                                </div>
                                                <Dialog.Title as="h3" className="text-lg font-semibold leading-6  text-gray-900 text-center">

                                                    <div className=''>
                                                        <label className='mb-3 block'> Bills to Pay are syncing now </label>
                                                    </div>
                                                </Dialog.Title>
                                                <div className="mt-2">
                                                    <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 sm:justify-center">
                                                        <button
                                                            type="submit"
                                                            className=" !px-4 text-sm font-normal text-white hover:!text-white hover:!no-underline h-10 flex items-center justify-center gap-2 !bg-[#71B55C] rounded-lg shadow-btn hover:opacity-80 transition-all focus:ring-2 ring-[#EDEBFC] disabled:bg-[#D0CAF6] disabled:cursor-not-allowed"
                                                            onClick={e => setShowSyncModal(false)}
                                                        >
                                                            Ok
                                                        </button>

                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </div>
        </Transition.Root>
    </>;
};

export default XeroBills;
