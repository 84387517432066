import React from 'react'
import './style.scss'
export default function SubmissionTanks() {
  return (
    <div>
          <div className="mainThanku">
              <div className="container text-center">
                  <h2 className="thankuText mb-3 text-capitalize">You've finished!</h2>
                  <p>Your Submission has beed recieved.</p>
                  <p>We will be in touch and contact you soon. If additional Details needed</p>
                  <div className="text-center">
                  </div>
              </div>
          </div>
    </div>
  )
}
