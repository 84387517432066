//src/plugins/aiAssistant.js
import sharedModel from '../models/shared.model';
import { generateResponse } from './opnai';

export async function getAssistantResponse(userMessage,topic='Provide professional conversational Reply for my data ') {
    try {
        const assistantResponse = await generateResponse(userMessage,topic);
        return sharedModel.getInsightHtml(assistantResponse);
    } catch (error) {
        console.error('Error in AI Assistant:', error);
        return 'Sorry, I am unable to process your request at the moment.';
    }
}
