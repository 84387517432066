import Layout from "../../components/global/layout";
import PrepareDocument from "./Pdfsign";


const Preparation = () => {
    return (
        <div> 
            <Layout>
            <PrepareDocument />
            </Layout>
        </div>
    );
};

export default Preparation;