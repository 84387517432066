import React, { useEffect, useState } from "react";
import Html from "./Html";
// import "./style.scss";
import addressModel from "../../../models/address.model";

import { Loader } from '@googlemaps/js-api-loader';
import environment from "../../../environment";
const loader = new Loader({
  apiKey: environment.map_api_key,
  version: "weekly",
  libraries: ["places"]
});

const GooglePlaceAutoComplete = ({ placeholder, result, id='', value }) => {
  const [searchText, setSeatchText] = useState('')
  const [err, setError] = useState('')
  const search = async (text) => {
    setSeatchText(text)
    result({
      event: 'value',
      value: text
    })
  }

  const placeChange = (place) => {
    setSeatchText(place.formatted_address)
    result({
      event: 'placeChange',
      value: place.formatted_address,
      place,
      address: addressModel.getAddress(place)
    })
  }

  useEffect(() => {
    loader
  .load()
  .then((g) => {
    let google=g
    console.log("googless",google)
    // new google.maps.Map(document.getElementById("map"), mapOptions);
    const input = document.getElementById("pac_input_" + id+random);
    console.log("input",input)
    if(!input){
      // setError(random)
      return
    } 
    const options = {
      // componentRestrictions: { country: "us" },
      fields: ["address_components", "geometry", "formatted_address", "utc_offset_minutes"],
      strictBounds: false,
      // types: [],
    };
    const autocomplete = new google.maps.places.Autocomplete(input, options);
    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      placeChange(place)
    });
  })
  .catch(e => {
    // do something
    console.log("googless err",e)
  });
  
  },[err])

  const random=Math.random()

  useEffect(() => {
    setSeatchText(value)
  }, [value])

  return <>
    <Html
      id={id+random}
      result={result}
      placeholder={placeholder}
      searchText={searchText}
      placeChange={placeChange}
      search={search}
    />
  </>
}
export default GooglePlaceAutoComplete