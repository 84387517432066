const shared={
    check:'Goal',
    title:'Goals',
    addTitle:'Goal',
    url:'goal',
    addApi:'api/goal',
    editApi:'api/goal',
    detailApi:'api/goal',
    listApi:'api/goals',
    statusApi:'api/goal',
    deleteApi:'api/goal'
}

export default shared